<template>
  <i
    :class="iconClass"
  >
    <span
      v-for="(x, index) in 14"
      :key="index"
      :class="'path'+x +' '+ spanClass"
    />
  </i>
</template>

<script>

export default {
  name: "Icon",
  props: {
    iconName: {
      type: String,
      default: () => (''),
    },
    iconClass: {
      type: String,
      default: () => (''),
    },
    spanClass: {
      type: String,
      default: () => (''),
    },
  },
  mounted(){
    console.log("IKONE");
  },
};
</script>
