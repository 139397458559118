import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "comma mr-1 text-color-text"
}
const _hoisted_2 = {
  key: 0,
  class: "comma mr-1 text-color-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.href && _ctx.link)
      ? (_openBlock(), _createBlock("a", {
          key: 0,
          class: "hidden-entity-link",
          href: _ctx.href
        }, null, 8, ["href"]))
      : _createCommentVNode("", true),
    (_ctx.href && _ctx.link)
      ? (_openBlock(), _createBlock("a", {
          key: 1,
          class: "entity-link",
          href: _ctx.href
        }, [
          _createVNode("span", {
            class: ["entity", _ctx.classList]
          }, [
            _createTextVNode(_toDisplayString(_ctx.result?.[_ctx.propertyByEntity]), 1),
            (_ctx.result?.[_ctx.propertyByEntity]?.length > 0)
              ? (_openBlock(), _createBlock("span", _hoisted_1, ","))
              : _createCommentVNode("", true)
          ], 2)
        ], 8, ["href"]))
      : (_openBlock(), _createBlock("span", {
          key: 2,
          class: ["entity no-link-entity", _ctx.classList]
        }, [
          (_ctx.result?.[_ctx.propertyByEntity]?.length > 0)
            ? (_openBlock(), _createBlock("span", _hoisted_2, ","))
            : _createCommentVNode("", true),
          (_ctx.externalLink)
            ? (_openBlock(), _createBlock("a", {
                key: 1,
                href: _ctx.result?.ExternalLink,
                class: "entity-a hover:text-color-primary hover:no-underline"
              }, _toDisplayString(_ctx.result?.[_ctx.propertyByEntity]), 9, ["href"]))
            : (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.result?.[_ctx.propertyByEntity]), 1)
              ], 64))
        ], 2))
  ], 64))
}