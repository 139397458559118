
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {SeoInterface} from "@/interfaces";
import {appStoreNavigate} from "@/router/router.service";

@Options({
  emits: ['onClick']
})
export default class VirtualLink extends Vue {

  @Prop({required: false, default: undefined})
  href?: string;

  @Prop({required: false, default: undefined})
  seo?: SeoInterface;

  @Prop({required: false, default: ''})
  classList?: string;

  @Prop({required: false, default: false})
  closeMenuOnClick?: boolean;

  @Prop({required: false, default: false})
  externalLink?: boolean;

  @Prop({required: false, default: undefined})
  target?: string;

  onCloseMenu() {
    this.$store.state.closeMenu++
  }
  get internalUrl(): boolean {
    return !this.url?.startsWith('http') && !this.url?.startsWith('https')
  }
  get url(): string {
    return this.seo?.SefUrl ?? this.href ?? ''
  }
  get aTarget():string {
    return this.target === 'BLANK' ? '_blank' : '_self'
  }
  navigate() {
    if (this.url) {
      appStoreNavigate(this.url)
    }
    if (this.closeMenuOnClick) {
      this.onCloseMenu();
      this.$emit('onClick')
    }
  }
  /* checkIfWww(){
    if(this.url?.startsWith('www')){
      return "https://";
    }
    return '';
  } */
}
