<template>
  <div
    class="title-small content relative z-10 pb-12 lg:pb-16 test-class"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded && firstModuleDataItem !== null"
      class="container max-w-screen-md lg:max-w-screen-2xl relative"
      :class="{'noHeroHeader' :moduleInfo.Module.ModuleProperties.ModuleHasNoHeroHeader === 'True'}"
    >
      <div
        class="page-title-container-v2"
        :class="{
          'mobile-absolute':
            moduleInfo.Module.ModuleProperties
              .ModuleHasTitleAboveOrUnderHeader === 'aboveHeader',
        }"
      >
        <div
          class="page-title"
        >
          <div class="relative z-10">
            <span
              v-if="moduleInfo.Module.ModuleProperties.ModuleHasRating === 'True'"
              class="stars flex"
            >
              <i
                v-for="index in getRating()"
                :key="index"
                class="fas fa-star text-yellow text-24 w-6"
              />
            </span>
            <h1
              v-if="
                hasPropertyValue(
                  'Title',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
              class="title-2a pb-2"
            >
              {{
                getPropertyValue($t,"Title",moduleInfo.Module.ModuleProperties,firstModuleDataItem)
              }}
            </h1>
            <!-- start - end dates -->
            <div v-if="moduleInfo.Module.ModuleProperties.ModuleHasDates === 'True'">
              <div
                v-if="hasPropertyValue('StartDate', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
                class="title-dates pb-3"
              >
                <AppDate :date="getPropertyValue($t, 'StartDate', moduleProperties, moduleData)" />
                <!--              {{ getPropertyValue($t, "StartDate", moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}.-->
                <span
                  v-if="hasPropertyValue('EndDate', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
                >
                  {{ $t("loc_to") }}
                  <AppDate :date="getPropertyValue($t, 'EndDate', moduleProperties, moduleData)" />
                  <!--                {{ getPropertyValue($t, "EndDate", moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}.-->
                </span>
              </div>
            </div>
            <!-- link -->
            <div
              v-if="
                moduleInfo.Module.ModuleProperties.ModuleHasHyperlink === 'True' && hasPropertyValue('Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem)
              "
              class="title-link space-x-4 pb-2"
            >
              <i class="fas fa-link text-24 w-6" />
              <VirtualLink
                :href="getPropertyValue($t, 'Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
                class-list="title-link space-x-4 pb-2"
              >
                <span class="link">
                  {{ getPropertyValue($t, 'Link', moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
                </span>
              </VirtualLink>
            </div>

            <!-- lokacija -->
            <div v-if="moduleInfo.Module.ModuleProperties.ModuleHasLocation === 'True'">
              <div class="title-link pb-2">
                <i class="fas fa-map-pin text-24 mr-2 md:mr-4 w-6" />
                <span class="flex text-color-text">
                  <Property
                    :value="firstModuleDataItem?.Place"
                    :property-name="'Place'"
                    :external-link="true"
                    :link="true"
                    :link-property="'ExternalLink'"
                    class="text-color-text font-sans-bold"
                    :class-list="['text-color-text']"
                  >
                    {{ getPropertyValue($t, Place, firstModuleDataItem) }}
                  </Property>
                  <!-- <span class="mr-1 text-color-text">, </span> -->
                </span>
                <span
                  v-if="hasPropertyValue('Address', moduleProperties, firstModuleDataItem)"
                  class="title-poi"
                ><span
                  v-if="firstModuleDataItem.Place?.length > 0"
                  class="comma mr-1 text-color-text"
                >,</span>{{ getPropertyValue($t, 'Address', moduleProperties, firstModuleDataItem) }}
                </span>
              </div>
            </div>
            <div
              v-if="
                hasPropertyValue(
                  'Subtitle',
                  moduleInfo.Module.ModuleProperties,
                  firstModuleDataItem
                )
              "
              class="p-sm text-color-darkgrey pb-3 text-justify"
              v-html="getPropertyValue($t,'Subtitle',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            />
            <template v-if="moduleInfo.Module.ModuleProperties.ModuleHideBreadcrumbs !== 'True'">
              <Breadcrumbs
                :module-info="moduleInfo"
              />
            </template>
          </div>
          <svg v-if="moduleInfo.Module.ModuleProperties.ModuleHideBreadcrumbs !== 'True'" class="title-bg-shape" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024.1 82.8" style="enable-background:new 0 0 1024.1 82.8;" xml:space="preserve">
            <polygon id="XMLID_1_" class="st0" points="1.9,62.9 1.9,1 690.6,1 690.6,1 1023.9,1 1023.9,63.4 865.5,82.6 "/>
          </svg>
        </div>
        <!--TODO: zamjeniti share + wishlist sa componentom (FooterActionButtons.vue - preimenovati i includeati di treba)-->
        <div
          v-if="moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' || moduleInfo.Module.ModuleProperties.ModuleHideWishlistButton !== 'True'"
          class="action-buttons lg:space-x-6"
        >
          <template v-if="moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True'">
            <ShareBtn
              :module-info="moduleInfo"
              :module-class="moduleClass"
              :module-location="'loc-title'"
            />
          </template>
          <!-- <Favorite
            :entity="firstModuleDataItem"
            :inline="true"
          /> -->
        </div>
      </div>
      <JsonLdManager
        :entity-id="moduleInfo.Module.Binding.SourceEntityTypeID"
        :entity="firstModuleDataItem"
      />
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/libs/features/lib/Breadcrumbs/Breadcrumbs";
import {selfDataRequestMixin} from "@/mixins";
import Property from "@/components/includes/entity/Property";
import ShareBtn from "@/components/includes/ShareBtn";
import JsonLdManager from "@/components/utils/json-ld-manager";
import Favorite from "@/components/includes/Favorite";
import AppDate from "@/components/includes/Date";

export default {
  name: "TitleSmall",
  components: {
    //Favorite,
    Breadcrumbs,
    Property,
    ShareBtn,
    JsonLdManager,
    AppDate
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    getRating(){
      if(this.firstModuleDataItem?.Rating > 0){
        return parseInt(this.firstModuleDataItem.Rating);
      }
      else if(this.firstModuleDataItem?.FacilityStars > 0){
        return parseInt(this.firstModuleDataItem.FacilityStars);
      }
      else return 0;
    }
  }
};
</script>
