<template>
  <div
    v-if="showCovidTrack === true" 
    class="nav-covid-track max-w-screen-3xl w-full h-10 fixed left-0 right-0 mx-auto z-99 top-0 bg-color-secondary text-color-nav-text flex items-center justify-center text-center"
  >
    <VirtualLink
      :href="menuItem.CustomUrl?.length > 0 ? menuItem.CustomUrl : menuItem.Page"
      class-list="text-color-nav-text font-medium font-sans-narrow"
    >
      {{ menuItem.Name }}
      <!-- <svg class="svg-parallelogram" viewBox="0 0 2000 500" preserveAspectRatio="none"  xmlns="http://www.w3.org/2000/svg">
        <rect x="190" y="0" width="90%" height="100%" transform="skewX(-20)" />
      </svg> -->
    </VirtualLink>
      
    <span 
      class="close-track absolute top-0 right-0 z-99 px-2 text-60 -mt-8 pt-1 cursor-pointer font-medium"
      @click="hideCovidTrack"
    >×</span>
  </div>
</template>

<script>
import { menuMixin } from "@/mixins";
//import {selfDataRequestMixin} from "@/mixins";

export default {
  mixins: [menuMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    }
  },
  emits: ["showCovid"],
  data() {
      return {
        showCovidTrack: false 
      }
  },
  mounted() {
   if(sessionStorage.getItem('showCovidTrack') === true || sessionStorage.getItem('showCovidTrack') === null) {
    this.showCovidTrack = true;
   }
  },
  methods: {
    hideCovidTrack(){
      this.$emit("showCovid", false);
      this.showCovidTrack = false;
      sessionStorage.setItem('showCovidTrack', false);
      /* this.setCookie('showCovidTrack', false) */
    }
  },
};
</script>
