import {splitByString, splitByStringAndTranslate} from "@/components/filters/filter-utility";

const FILTERS = {
    capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },
    uppercase: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.toUpperCase()
    },
    lowercase: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.toLowerCase()
    },
    cutText: (value, length, suffix) => {
        if (value.length > length) {
            return value.substring(0, length) + suffix;
        } else {
            return value;
        }
    },

    /*
     * Title            :   Filter koji na osnovu propertija vraća value
     * Implementation   :   $filters.getPropertyValue(  $t, 'Title', moduleProperties, data)
     */
    getPropertyValue: (t, value, moduleProperties, moduleData) => {
      //console.time('getPropertyValue');
        let returnValue
        //ako postoji traženi property 'value' u objektima 'moduleProperties' i 'moduleData'
        const getAlternativeProperty = FILTERS.extractSourcePropertyName(moduleProperties[value]);

        if(moduleData && moduleData.length == 1){
            moduleData = moduleData[0];
        }

        // Admin mode
        if(moduleProperties.adminMode) return getAlternativeProperty || value;

        const moduleDataHasEntries = FILTERS.objectHasEntries(moduleData);
        const modulePropertiesHasEntries = FILTERS.objectHasEntries(moduleProperties);
        returnValue = FILTERS.extractSourcePropertyValFromText(moduleProperties[value], moduleData, moduleDataHasEntries);
        if (((modulePropertiesHasEntries && Object.prototype.hasOwnProperty.call(moduleProperties, value)) &&
            (moduleDataHasEntries && Object.prototype.hasOwnProperty.call(moduleData, value)) &&
            moduleProperties[value] == "") ||
            (moduleDataHasEntries && Object.prototype.hasOwnProperty.call(moduleData, value) &&
            !Object.prototype.hasOwnProperty.call(moduleProperties, value))) {
          returnValue = FILTERS.extractSourcePropertyValFromText(moduleData[value], moduleData, moduleDataHasEntries);
        }

        //console.timeEnd('getPropertyValue');
        //console.count('getPropertyValue');
        returnValue = FILTERS.extractJezicniIzrazFromText(returnValue, t);
        //console.count('getPropertyValue')
        return returnValue
    },
    extractJezicniIzrazFromText: (value, t) => {
        const propertyType = value != undefined ? typeof (value) : typeof ('');
        let temp = value
        if(value && propertyType === 'string' && t){
            temp.split('%').forEach( str => {
              const translated = t(str);
              if (str !== translated) {
                temp = temp.replace(`%${str}%`, translated);
              }
            });
          /*        const regex = /(%.*?%)/g;
                    if(regex.test(temp)){
                      const found = temp.match(regex);
                      found.map(x => {
                        const xTrimmed = x.replace(/%/g, '');
                        const jezicniIz = t? (x ? t(xTrimmed) : x) : x;
                        temp = temp.replace(x, jezicniIz);
                      });
                    }*/
        }
        return temp;
    },
    extractSourcePropertyValFromText: (value, moduleData, moduleDataHasEntries) => {
      const propertyType = value != undefined ? typeof (value) : typeof ('');
      let temp = value;
      if(temp && propertyType === 'string' && moduleDataHasEntries){
        const regex = /\$\(([^)]+)\)/gm;
        if(regex.test(temp)){
          const arr = temp.match(regex);
          arr.map(x => {
            const xTrimmed = x.replace('$(', '').replace(')', '');
              if(Object.prototype.hasOwnProperty.call(moduleData, xTrimmed)){
                const sourceVal = moduleData[xTrimmed];
                temp = temp.replace(x, sourceVal);
              }
          });
        }
      }
      return temp;
    },
    extractSourcePropertyName: (value) => {
      const propertyType = value != undefined ? typeof (value) : typeof ('');
      let temp = value;
      if(temp && propertyType === 'string'){
        const regex = /\$\(([^)]+)\)/gm;
        if(regex.test(value)){
          const arr = value.match(regex);
          temp = arr[0].replace('$(', '').replace(')', '');
        }
      }
      return temp;
    },
    /*
     * Title            :   Filter koji na osnovu vrijednosti vraća dal za neki property postoji value
     * Implementation   :   $filters.hasPropertyValue('Title', moduleProperties, data)
     */
    hasPropertyValue: (value, moduleProperties, moduleData) => {
        let retVal = false;

        // Nemamo moduleData iz nekog razloga vrati value kojeg trazimo
        //if (Object.entries(moduleData).length == 0) return value;

        const propertyValue = FILTERS.getPropertyValue( null, value, moduleProperties, moduleData);
        const propertyType = propertyValue != undefined ? typeof (propertyValue) : typeof ('');
        //console.log('propertyValue : propertyType : value', propertyValue, propertyType, value);

        switch (propertyType) {
            case "string":
                retVal = (propertyValue != undefined && propertyValue != "");
                break;
            case "number":
                retVal = propertyValue > 0;
                break;
            case "boolean":
                return (propertyValue == 1 || propertyValue == true);
            case "object":
                return (propertyValue != null);
            default:
                retVal = false;
        }
        //console.count('hasPropertyValue')
        return retVal;
    },
    createObjectConstructor: (obj) => {
        return Object.prototype.constructor(obj);
    },
    objectHasEntries: (obj) => {
        const objConstructor = FILTERS.createObjectConstructor(obj);
        return Object.entries(objConstructor).length > 0;
    },
    stringFormat: (b, ...args) => {
        const a = args;
        a.unshift(b);
        return b.replace(/(\{\{\d\}\}|\{\d\})/g, function (b) {
            if (b.substring(0, 2) == "{{") return b;
            const c = parseInt(b.match(/\d/)[0]);
            return a[c + 1]
        })
    },
    splitProperties: (str, param  = '#') => {
      return splitByString(str, param)
    },
    splitPropertiesAndTranslate: (str, param  = '#', removeChar, func) => {
      return splitByStringAndTranslate(str, param, removeChar, func)
    },
    getDateFromString(dateString){
      let date = new Date();
      if(dateString) {
        const dateStringArray = dateString.split('.');
        if(dateStringArray.length > 0){
          date = new Date(dateStringArray[2], dateStringArray[1] - 1, dateStringArray[0]);
        }
      }
      return date;
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getDomainFromUrl(url) {
      if(url){
        let domain = (new URL(url));
        return domain.hostname;
      } else {
        return url;
      }
    }
}

export default FILTERS;
