<template>
  <div
    class="content"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded"
      class="paragraph-img-left-right"
    >
      <!-- Version when Image Left (default) -->
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasImageLeftOrRight === 'imageLeft' || moduleInfo.Module.ModuleProperties.ModuleHasImageLeftOrRight === ''"
        class="content-container mx-auto relative flex lg:justify-start my-20 gap-12"
        :class="moduleInfo.Module.ModuleProperties.ModuleHasImageSize"
      >
        <div
          v-if="display === 'desktop'"
          class="hidden lg:block w-full lg:w-1/2 h-auto relative max-h-800 lg:pr-10px"
        >
          <fancybox-item
            v-if="firstModuleDataItem?.MediaGallery.length > 1"
            :first-module-data-item="firstModuleDataItem"
            :gallery-type="galleryType"
          />
          <vue-picture
            v-if="firstModuleDataItem?.MediaGallery.length"
            :image="firstModuleDataItem.MediaGallery[0]"
            css-class="lr-img object-cover w-full h-full max-h-800"
          />
        </div>

        <div class="container lg:px-0 lg:mx-0 w-full lg:w-1/2 lg:max-w-530">
          <h2
            v-if="hasPropertyValue( 'Title', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            class="headline-3a mb-4"
          >
            {{ getPropertyValue($t, "Title", moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
          </h2>

          <div
            v-if="display === 'mobile'"
            class="lg:hidden my-3 w-full max-h-400 relative"
          >
            <fancybox-item
              v-if="firstModuleDataItem?.MediaGallery.length > 1"
              :first-module-data-item="firstModuleDataItem"
              :gallery-type="galleryType"
            />
            <vue-picture
              v-if="firstModuleDataItem?.MediaGallery.length"
              :image="firstModuleDataItem.MediaGallery[0]"
              css-class="lg:hidden w-full object-cover max-h-400"
            />
          </div>

          <div
            class="my-4 py-1 pl-6 lg:pl-8"
            :class="{'border-color-primary border-0 border-l-2 pl-6 lg:pl-8': moduleInfo.Module.ModuleProperties.ModuleHasBorder === 'True'}"
          >
            <p
              v-if="hasPropertyValue('HighlightedText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="p-base content-text text-color-primary"
              v-html="getPropertyValue($t, 'HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            />
          </div>

          <div class="py-4">
            <div
              v-if="hasPropertyValue('ShortDescription', moduleInfo.Module.ModuleProperties, firstModuleDataItem) || hasPropertyValue('Text', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="p-sm content-text leading-8"
              :class="{'lg:pl-20 lg:pr-12': moduleInfo.Module.ModuleProperties.ModuleHasIndentText === 'True'}"
              v-html="getPropertyValue($t, 'ShortDescription', moduleInfo.Module.ModuleProperties, firstModuleDataItem)|| getPropertyValue( $t, 'Text', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            />
          </div>
          <div>
            <VirtualLink
              v-if="hasPropertyValue('UrlText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              :href="getHref(moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class-list="btn-small pl-0"
            >
              {{ getPropertyValue( $t,  'UrlText', moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
            </VirtualLink>
          </div>
        </div>
      </div>

      <!--  Version when Image Right -->
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasImageLeftOrRight === 'imageRight'"
        class="content-container mx-auto relative flex lg:justify-end my-20 gap-12"
        :class="moduleInfo.Module.ModuleProperties.ModuleHasImageSize"
      >
        <div
          v-if="display === 'desktop'"
          class="hidden lg:block w-full lg:w-1/2 h-auto relative max-h-800 lg:pl-10px order-2"
        >
          <fancybox-item
            v-if="firstModuleDataItem?.MediaGallery.length > 1"
            :first-module-data-item="firstModuleDataItem"
            :gallery-type="galleryType"
          />
          <vue-picture
            v-if="firstModuleDataItem?.MediaGallery.length"
            :image="firstModuleDataItem.MediaGallery[0]"
            css-class="lr-img object-cover w-full h-full max-h-800"
          />
        </div>

        <div
          class="container lg:pr-0 lg:mx-0 w-full lg:w-1/2 lg:max-w-530 order-1"
        >
          <h2
            v-if="hasPropertyValue('Title',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            class="headline-3a mb-4"
          >
            {{ getPropertyValue( $t, 'Title',moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
          </h2>

          <div
            v-if="display === 'mobile'"
            class="lg:hidden my-3 w-full max-h-400 relative"
          >
            <fancybox-item
              v-if="firstModuleDataItem?.MediaGallery.length > 1"
              :first-module-data-item="firstModuleDataItem"
              :gallery-type="galleryType"
            />

            <vue-picture
              v-if="firstModuleDataItem?.MediaGallery.length"
              :image="firstModuleDataItem.MediaGallery[0]"
              css-class="lg:hidden w-full object-cover max-h-400"
            />
          </div>

          <div
            class="my-4 py-1 pl-6 lg:pl-8"
            :class="{'border-color-primary border-0 border-l-2 pl-6 lg:pl-8': moduleInfo.Module.ModuleProperties.ModuleHasBorder === 'True'}"
          >
            <p
              v-if="hasPropertyValue('HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
              class="p-base content-text text-color-primary"
              v-html="getPropertyValue($t, 'HighlightedText', moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
            />
          </div>

          <div class="py-4">
            <div
              v-if="hasPropertyValue('ShortDescription', moduleInfo.Module.ModuleProperties, firstModuleDataItem)|| hasPropertyValue('Text', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class="p-sm content-text leading-8"
              :class="{'lg:pl-20 lg:pr-12': moduleInfo.Module.ModuleProperties.ModuleHasIndentText === 'True'}"
              v-html="getPropertyValue($t, 'ShortDescription', moduleInfo.Module.ModuleProperties, firstModuleDataItem)|| getPropertyValue( $t, 'Text', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
            />
          </div>
          <div>
            <VirtualLink
              v-if="hasPropertyValue('UrlText', moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              :href="getHref(moduleInfo.Module.ModuleProperties, firstModuleDataItem)"
              class-list="btn-small pl-0"
            >
              {{ getPropertyValue($t, 'UrlText', moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
            </VirtualLink>
          </div>
        </div>
      </div>

      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSeparator === 'True'"
        class="module-border container mx-auto max-w-screen-2xl mb-4"
      />
    </div>
  </div>
</template>
<script>
import VuePicture from "../../includes/VuePicture";
import FancyboxItem from "@/components/includes/fancybox/FancyboxItem";

import {selfDataRequestMixin} from "@/mixins";

export default {
  name: "ParagraphImageLeftRight",
  components: {
    VuePicture,
    FancyboxItem,
  },
  mixins: [
    selfDataRequestMixin
  ],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      galleryType: 'paragraphImageLeftRight',
      display: "desktop",
      imgs: [],
      visible: false
    }
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
    windowWidth() {
      if (window.innerWidth >= 768) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
      }
    },
  }

};
</script>

<style lang="scss">
@import "@fancyapps/ui/dist/fancybox.css";

/* .content-text::v-deep(ul) {
  @apply list-none m-0 p-0;
}
.content-text::v-deep(ul li) {
  @apply font-sans text-color-darkgrey text-18 leading-loose pl-8 relative mb-3;
}
.content-text ul li:before {
  content: "•";
  @apply absolute inline-block left-0;
} */

.paragraph-img-left-right {
  .content-container {
    @apply max-w-screen-3xl;
    .lr-img {
      @apply min-h-500;
    }
  }
  .content-container.big {
    @apply max-w-screen-3xl;
    .lr-img {
      @apply min-h-500;
    }
  }
  .content-container.small {
    @apply max-w-screen-2xl;
    .lr-img {
      @apply min-h-300;
    }
  }
  .has-tooltip{margin-right: 20px;}

  .btn-small{
    @apply text-22;
  }
}
</style>
