
export const swiperOptions = {
    slidesPerView: 1,
    loopedSlides: 1,
    loop: true,
    autoHeight: true,
    initialSlide: 0,
    centeredSlides: false,
    spaceBetween: 20,
    watchSlidesVisibility: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button.swiper-button-next",
        prevEl: ".swiper-button.swiper-button-prev",
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            loopedSlides: 1,
            loop: true
        },
        440: {
            slidesPerView: 2,
            loopedSlides: 2,
        },
        768: {
            slidesPerView: 3,
            loopedSlides: 3,
            autoHeight: false,
        },
        992: {
            slidesPerView: 4,
            loopedSlides: 4,
            autoHeight: false,
        },
        1440: {
            slidesPerView: 4,
            loopedSlides: 4,
            autoHeight: false,
        },
    }
};

export const swiperPortraitOptions = {
    slidesPerView: 1.2,
    loopedSlides: 1,
    loop: true,
    autoHeight: true,
    initialSlide: 0,
    centeredSlides: false,
    spaceBetween: 20,
    watchSlidesVisibility: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button.swiper-button-next",
        prevEl: ".swiper-button.swiper-button-prev",
    },
    breakpoints: {
        0: {
            slidesPerView: 1.3,
            loopedSlides: 2,
            loop: true
        },
        505: {
            slidesPerView: 2.3,
            loopedSlides: 3,
            loop: true
        },
        768: {
            slidesPerView:3.3,
            loopedSlides: 4,
            loop: true

        },
        992: {
            slidesPerView: 3.3,
            loopedSlides: 4,
            loop: true,
            autoHeight: false,

        },
        1420: {
            slidesPerView: 4,
            loopedSlides: 4,
            loop: true,
            autoHeight: false,
        }
    }
};
export const swiperHeroOptions = {
    slidesPerView: 1,
    loopedSlides: 1,
    loop: true,
    initialSlide: 0,
    pagination: {
        el: '.swiper-pagination.pagination-bullet, .swiper-pagination.pagination-fraction',
        clickable: true,
        renderBullet: function (index, className) {
          const numbOfSlides = this.slides.length;
          return '<span class="' + className + '"><span class="fractions">' + ([index+1]+' / '+ numbOfSlides)+'</span></span>';
      },
    },
    navigation: {
        nextEl: ".swiper-button.swiper-button-next",
        prevEl: ".swiper-button.swiper-button-prev",
    },
};

const swiperGalleryThumbsOptions = {
    spaceBetween: 2,
    slidesPerView: '4',
    loop: true,
    initialSlide: 0,
    loopedSlides: 6, //looped slides should be the same
    slideToClickedSlide: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    centeredSlides: false,
    navigation: {
        nextEl: ".swiper-button.swiper-button-next",
        prevEl: ".swiper-button.swiper-button-prev",
    },
    breakpoints: {
        992: {
            slidesPerView: '6',
            spaceBetween: 20
        },
    }



};

const swiperGalleryOptions = {
    spaceBetween: 10,
    loop: true,
    loopedSlides: 6, //looped slides should be the same
    navigation: {
        nextEl: ".swiper-button.swiper-button-next",
        prevEl: ".swiper-button.swiper-button-prev",
    },
};

export const swiperListOptions = {
    slidesPerView: 1,
    loop: false,
    initialSlide: 0,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
};

export const swiperGalleryTextOptions = {
    loop: false,
    slidesPerView: '1',
    navigation: {
        nextEl: ".swiper-button.swiper-button-next.swiper-gallery-text-next",
        prevEl: ".swiper-button.swiper-button-prev.swiper-gallery-text-prev",
    },
    pagination: {
        el: '.swiper-pagination.pagination-bullet, .swiper-pagination.pagination-fraction',
        clickable: true,
        renderBullet: function (index, className) {
            const numbOfSlides = this.slides.length;
            return '<span class="' + className + '"><span class="fractions">' + ([index+1]+' / '+ numbOfSlides)+'</span></span>';

        },
    },
};

const swiperReviewsOptions = {
    loop: false,
    slidesPerView: '1',
    navigation: {
        nextEl: ".swiper-button.swiper-button-next.swiper-reviews-next",
        prevEl: ".swiper-button.swiper-button-prev.swiper-reviews-prev",
    },
    pagination: {
        el: '.swiper-pagination.pagination-bullet, .swiper-pagination.pagination-fraction',
        clickable: true,
        renderBullet: function (index, className) {
            const numbOfSlides = this.slides.length;
            return '<span class="' + className + '"><span class="fractions">' + ([index+1]+' / '+ numbOfSlides)+'</span></span>';

        },
    },
};

export { swiperGalleryOptions, swiperGalleryThumbsOptions, swiperReviewsOptions };
