
import { createApp, defineAsyncComponent } from 'vue'
import VueAxios from 'vue-axios'
import { appI18n } from './i18n'
import FILTERS from '@/components/filters/filter'
import PARAMETERS from '@/components/filters/parameters'
import CONSTANTS from '@/components/shared/constants'
import { Node, Tree } from "tree-vue-component";
import { createGtm } from '@gtm-support/vue-gtm';
import '@/css/main.scss';
import { Appstore } from '@/store/main.store.ts'
import axiosInstance from "@/services/http-common";
import { mainRouter } from "@/router/main.router";
import { App as Application } from "@vue/runtime-core";
import MasterMainMaster from "@/components/layouts/MasterMainMaster.vue";
import VirtualLink from "@/components/includes/router/VirtualLink.vue";
/*import Dropdown from "@/components/AppModule/CardsList/Dropdown.vue";
import Landscape from "@/components/AppModule/CardsList/Landscape.vue";
import Icons from "@/components/AppModule/CardsList/Icons.vue";
import Portrait from "@/components/AppModule/CardsList/Portrait.vue";
import Square from "@/components/AppModule/CardsList/Square.vue";*/
//import runtime from "serviceworker-webpack-plugin/lib/runtime";
import Distance from "@/components/AppModule/Widget/Distance.vue";
import FormaPersona from "@/components/AppModule/Widget/FormaPersona.vue";
import FormaPersonaChild from "@/components/AppModule/Widget/FormaPersonaChild.vue";
import FormaPersonaHeader from "@/components/AppModule/Widget/FormaPersonaHeader.vue";
import IconsCTA from "@/components/AppModule/Widget/IconsCTA.vue";
import IconsCTAV2 from "@/components/AppModule/Widget/IconsCTAV2.vue";
import Instagram from "@/components/AppModule/Widget/Instagram.vue";
import Timeline from "@/components/AppModule/Widget/Timeline.vue";
import Weather from "@/components/AppModule/Widget/Weather.vue";
import SocialIcons from "@/components/AppModule/Widget/SocialIcons.vue";
import HeaderBig from "@/components/AppModule/HeroHeader/HeaderBig.vue";
import HeaderSmall from "@/components/AppModule/HeroHeader/HeaderSmall.vue";
import Mosaic from "@/components/AppModule/Mosaics/Mosaic.vue";
import MapRegion from "@/components/AppModule/Maps/MapRegion.vue";
import Portrait from "@/components/AppModule/CardsList/Portrait.vue";
import Square from "@/components/AppModule/CardsList/Square.vue";
import Landscape from "@/components/AppModule/CardsList/Landscape.vue";
import Dropdown from "@/components/AppModule/CardsList/Dropdown.vue";

import TitleSmall from "@/components/AppModule/ContentTitle/TitleSmall.vue";
import TitleBig from "@/components/AppModule/ContentTitle/TitleBig.vue";

import Paragraph from "@/components/AppModule/ContentBlock/Paragraph.vue";
import ParagraphImageLeftRight from "@/components/AppModule/ContentBlock/ParagraphImageLeftRight.vue";
import Banner from "@/components/AppModule/ContentBlock/Banner.vue";

import IntroText from "@/components/AppModule/ContentIntro/IntroText.vue";
import IntroTextImg from "@/components/AppModule/ContentIntro/IntroTextImg.vue";


import VTooltip from "v-tooltip";
import App from "@/App.vue";
import { defineComponent } from "@vue/composition-api";
import { Log } from "@/interfaces";
import { v4 as uuidv4 } from 'uuid';
import { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Load all files in content folder to add later as global dynamic components
 */
const files = require
  .context("./components/content/", true, /\.vue$/i, "lazy")
  .keys();

/**
 * Load all eager component
 */
const eagerComponent = require
  .context("./components/AppModule/", true, /\.vue$/i, "eager")

if ('serviceWorker' in navigator && false) {
  // runtime.register();
}
/**
 * setup the app
 */
//@ts-ignore
axiosInstance.interceptors.request.use((config: AxiosRequestConfig & { meta: any }) => {
  config.withCredentials = config.withCredentials || false;
  const uuid = uuidv4()
  config.baseURL = Appstore.state.CdnApiBaseUrl;
  config.params = config.params ?? {};
  config.data = config.data ?? {}
  config.data.Settings = config.data.Settings ?? Appstore.state.CdnApiKey;
  config.data.RequestUUID = uuid;
  config.data.PageUUID = Appstore.state.PageData.PageUuid ?? 'not found';
  config.data.LanguageID = config.data.LanguageID ?? Appstore.state.LanguageId;

  config.meta = config.meta || {}
  config.meta.RequestStartedAt = new Date();
  config.meta.RequestUUID = uuid
  /*  if (config.data.Persona || Appstore.state.personaId) {
      config.data.Persona = config.data.Persona ?? Appstore.state.personaId
    }
    if (config.data.PerfectVacation || Appstore.state.perfectVacationId) {
      config.data.PerfectVacation = config.data.PerfectVacation ?? Appstore.state.perfectVacationId
    }*/
  return config;
});
axiosInstance.interceptors.response.use((response: AxiosResponse & { config: any }) => {
  const logs: Log[] = [
    {
      Type: 'request',
      Timestamp: response.config.meta.RequestStartedAt as Date,
      Request: response.config.data?.toString(),
      ExecutionTimeMs: 0,
      PageUUID: Appstore.state.PageData.PageUuid ?? 'not found',
      RequestUUID: response.config.meta.RequestUUID
    },
    {
      Type: 'response',
      Timestamp: new Date(),
      Request: '',
      ExecutionTimeMs: new Date().getTime() - response.config.meta.RequestStartedAt.getTime(),
      PageUUID: Appstore.state.PageData.PageUuid ?? 'not found',
      RequestUUID: response.config.meta.RequestUUID
    },
  ]
  Appstore.dispatch('insertLogs', logs).catch((error) => console.error(error))
  return response;
})
let app: Application<Element>;

app = createApp(App)
  .use(VueAxios, axiosInstance)
  .use(Appstore)
  .use(mainRouter)
  .use(appI18n);
//@ts-ignore
if (window.Params?.GTM_ID){
  app.use(
    createGtm({
      //@ts-ignore
      id: window.Params.GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      /*queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x'
      },*/
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: mainRouter, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: [], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
}


// app.config.isCustomElement = tag => tag.includes('htz-icons')

/**
 * declare every file in content folder as global
 */
files.forEach((file) => {
    const componentName = (file.split("/")?.pop() as string).split(".")[0];
    const componentPath = file.replace("./", "").replace(".vue", "");
    app.component(componentName, defineAsyncComponent({
        loader: () =>
          import(
            `@/components/content/${componentPath}` /*webpackChunkName: "[request]" */
            ),
        //loadingComponent: Spinner
    }))
})
/**
 * declare every file in content folder as global
 */
/*    eagerComponent.keys().forEach((file) => {
    const componentName = (file.split("/")?.pop() as string).split(".")[0];
    const componentPath = './components/AppModule/' + file.replace("./", "").replace(".vue", "");
    const comp = eagerComponent(file);
    app.component(componentName, () => comp)
})*/
/**
 * Used in DEV and TEST mode for showing additional parameter
 */
app.component('ModuleLink', defineAsyncComponent({
    loader: () =>
      import(
        `@/components/utils/edit-link.vue`),

}))
/**
 * Used in DEV and TEST mode for showing additional parameter
 */
app.component('AdminToolbar', defineAsyncComponent({
    loader: () =>
      import(
         `@/components/includes/admin/AdminToolbar.vue`),
}))
/**
 * Used in DEV mode for showing additional parameter
 */
app.component('VirtualRouterDemo', defineAsyncComponent({
    loader: () =>
      import(
        `@/components/includes/admin/VirtualRouterDemo.vue`),
}))

app.component('MasterMainMaster', MasterMainMaster)
app.component('VirtualLink', VirtualLink)

app.component('Distance', Distance)
app.component('FormaPersona', FormaPersona)
app.component('FormaPersonaChild', FormaPersonaChild)
app.component('FormaPersonaHeader', FormaPersonaHeader)
app.component('IconsCTA', IconsCTA)
app.component('IconsCTAV2', IconsCTAV2)
app.component('Instagram', Instagram)
app.component('Timeline', Timeline)
app.component('Weather', Weather)
app.component('SocialIcons', SocialIcons)

app.component('Mosaic', Mosaic)
app.component('MapRegion', MapRegion)

//HEADERS
app.component('HeaderBig', HeaderBig)
app.component('HeaderSmall', HeaderSmall)

//CARDS LIST
app.component('Portrait', Portrait)
app.component('Square', Square)
app.component('Landscape', Landscape)
app.component('Dropdown', Dropdown)

//CONTENT TITLE
app.component('TitleSmall', TitleSmall)
app.component('TitleBig', TitleBig)

//CONTENT BLOCK
app.component('Paragraph', Paragraph)
app.component('ParagraphImageLeftRight', ParagraphImageLeftRight)
app.component('Banner', Banner)

//CONTENT INTRO
app.component('IntroText', IntroText)
app.component('IntroTextImg', IntroTextImg)


/*    app.component('Dropdown', Dropdown)
app.component('Icons', Icons)
app.component('Landscape', Landscape)
app.component('Portrait', Portrait)
app.component('Square', Square)*/
app.use(VTooltip);

if (process.env.NODE_ENV === 'development') {
    app.component('node', Node)
    app.component('tree', Tree)
}

app.config.globalProperties.$filters = FILTERS;
app.provide('$constants', CONSTANTS)
// app.config.globalProperties.$parameters = PARAMETERS;
app.config.globalProperties.paginationActive = true;
app.config.globalProperties.$parameters = PARAMETERS;
app.config.globalProperties.$constants = CONSTANTS;
//pp.use(filters)
app.mount('#app');

/*  setTimeout(() => {
  document.getElementsByClassName("body-overlay")[0].classList.add('hide-overlay');
}, 3000); */

export default app;
