
import {FilterDataDropDownInput} from "@/libs/features/.storybook/interfaces";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {Options} from "vue-class-component";
import FilterDropDown from "@/libs/features/lib/FilterDropDown/FilterDropDown.vue";
import {Action} from "vuex-class";
import {SelfDataMixin} from "@/mixins/self-data.mixin";
import axiosInstance from "@/services/http-common";
import {ComponentState, ModuleFilter, ModuleInfo} from "@/interfaces";
import {AxiosResponse} from "axios";
import {Prop, Watch} from "vue-property-decorator";
import {Appstore} from "@/store/main.store";
import FILTERS from "@/components/filters/filter";

interface FormaPersonaModuleProperties {
  ModulePersonaTitle: string;
  ModuleProductTitle: string;
  ButtonNavigateUrl: string;
  ButtonName: string;
}
@Options({
  components: {
    FilterDropDown
  },
  name: 'FormaPersonaChild',

})
export default class FormaPersonaChild extends SelfDataMixin {

  @Prop({default: []})
  dropdownClassList: string[] = [];

  @Prop({default: []})
  classList: string[] = [];

  @Action('setPersone')
  setPersone!: (val: {[key: string] : string}) => void

  @Action('setPerfectVacationId')
  setPerfectVacationId!: (val: string) => void

  dropDownSelectFilteredData: SelectItemEmit[] = [];
  filterOnCLick = true;
  filterData: FilterDataDropDownInput[] = [];
  isActive = false;

  @Watch('dataToLoad', {immediate: true, deep: true})
  onModuleInfoChange() {
    this.loadLists(this.dataToLoad)
  }

  get formaPersonaModuleProperties(): FormaPersonaModuleProperties {
    return this.moduleProperties as FormaPersonaModuleProperties
  }
  get formaPersonaFilterProperties(): ModuleFilter[] {
    return this.moduleFilters as ModuleFilter[]
  }
  get dataToLoad(): {entityTypeId: number, property: string, placeholder: string, fields: string[], propertyToShow: string}[] {
    return this.formaPersonaFilterProperties
      .filter(o => o.LookupSource === 'Persona' || o.LookupSource === 'Product')
      .map(o => ({
        entityTypeId: o.LookupSourceTypeID,
        property: o.LookupSource,
        placeholder: o.FilterFieldLngExp,
        fields: [o.LookupSourceValue],
        propertyToShow: o.LookupSourceValue
    }))

  }
  /*mounted() {
    this.loadLists()
  }*/
  onDropDownSelect(val: SelectItemEmit[]) {
    this.dropDownSelectFilteredData = val;
    if (!this.filterOnCLick){
      this.setDataAndNavigate();
    }
  }
  mapListResponse(response:  AxiosResponse<{Data: {[p: string]: string}[]}>, item:  {entityTypeId: number, property: string, placeholder: string, fields: string[], propertyToShow: string}, i: number) {
    const titleForPersona: 'ModulePersonaTitle' | 'ModuleProductTitle' = `Module${item.property}Title` as 'ModulePersonaTitle' | 'ModuleProductTitle'
    const queryParamProp = this.$route.query[item.property] ?? this.$route.query[item.property.toLowerCase()];

    this.filterData[(i)] = {
      data: response.data.Data.map(o => ({
        EntityID: o.EntityID,
        Caption: o[item.propertyToShow]
      })),
      emptyValue: item.placeholder,
      property: item.property,
      type: 'dropdown',
      selectedValue:
        queryParamProp ?
          {property: item.property, value: (queryParamProp as any) } :
          undefined,
      showTitle: true,
      title: FILTERS.getPropertyValue(  this.$t,
                titleForPersona,
                this.formaPersonaModuleProperties,
                this.moduleData
              )
    };
  }
  loadLists(dataToLoad: {entityTypeId: number, property: string, placeholder: string, fields: string[], propertyToShow: string }[]) {
    // console.log('loadLists');
    dataToLoad.forEach((item, i) => {
      axiosInstance
        .post<{Data: {[key: string]: string}[]}>(
          `/api/v1/${item.property}`, {
            Fields: item.fields
          })
        .then(response => {
          this.mapListResponse(response, item, i)
          const queryParamProp = this.$route.query[item.property] ?? this.$route.query[item.property.toLowerCase()]
          if (queryParamProp) {
            this.dropDownSelectFilteredData.push({property: item.property, value: (queryParamProp as string) });
            //this.filterData.push({property: item.property, value: (this.$route.query)})
          }
        }).catch((e) => {
        console.error(e)
      });
    })

  }
  getQueryFromSelectedItems(selectedItems: SelectItemEmit[]): {[key: string] : string} {
    return selectedItems.reduce((o, n) => ({...o,[n.property ?? 'missing']: n.value ? n.value.toString() : ''}), {})
  }
  setDataAndNavigate() {
    const query = this.getQueryFromSelectedItems(this.dropDownSelectFilteredData);


    /*this.$router.replace({
      path: this.$route.path,
      query,
      replace: true
    })*/
    this.setPersone(query)

    if (this.moduleProperties.ButtonNavigateUrl) {
      /*window.location.href = this.moduleProperties.ButtonNavigateUrl + (this.moduleProperties.ButtonNavigateUrl.includes('?') ? '&' : '?') +
        (query['Persona'] ? 'Persona=' + query['Persona'] : '') +
        (query['Persona'] && query['Product'] ? '&' : '') +
        (query['Product'] ? 'Product=' + query['Product'] : '')*/
      const newUrl = this.moduleProperties.ButtonNavigateUrl + (this.moduleProperties.ButtonNavigateUrl.includes('?') ? '&' : '?') +
        (query['Persona'] ? 'Persona=' + query['Persona'] : '') +
        (query['Persona'] && query['Product'] ? '&' : '') +
        (query['Product'] ? 'Product=' + query['Product'] : '')
      /*const queryParams: {[key: string]: string} = {};
      if (query['Persona']) {
        queryParams.Persona = query['Persona']
      }
      if (query['Product']) {
        queryParams.Product = query['Product']
      }*/
      console.log(newUrl);
      this.$router.push(newUrl).then(a => {
        Appstore.state.navigateUrl = newUrl;
      })
    } else {
      this.$store.state.reload++;

    }
  }


}
