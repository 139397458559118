import {ActionContext} from "vuex";
import {AppState, RelatedInterface} from "@/interfaces";
import axiosInstance from "@/services/http-common";

export const dropdownMutations = {
  setEmptyDataStore(state: AppState, entityName: string) {
    state.entitiesStore[entityName] = {
      loaded: false,
      loading: true,
      timeLoaded: new Date(),
      propertyNames: ['all'],
      entities: {},
      ids: []
    }
  },
  setDataDataInStore(state: AppState, {data, entityName}: { data: RelatedInterface[], entityName: string }) {
    state.entitiesStore[entityName] = {
      loaded: true,
      loading: false,
      timeLoaded: new Date(),
      propertyNames: ['all'],
      entities: {...data.reduce((o, n) => ({...o,[n.EntityID]: n}), {})},
      ids: [...data.map(o => o.EntityID)]
    }
  }
}
export const dropDownActions = {
  setEmptyRegionStore(store: ActionContext<AppState, AppState>, entityName: string) {
    if (store.state.entitiesStore[entityName]) {
      store.commit('setEmptyRegionStore')
    }
  },
  initDropDownStore(store: ActionContext<AppState, AppState>,  data: {entityName: string, fields?: string[], relatedFields?: {[key: string]: string}}) {
    store.dispatch('getData', data).catch((e) => console.error(e));
  },
  makeDataRequest(store:  ActionContext<AppState, AppState>, {entityName, fields, relatedFields}: {entityName: string, fields?: string[], relatedFields?: {[key: string]: string}}) {
    axiosInstance.post(
      `/api/v1/${entityName}`,
      {
        Fields: fields || ['Title'],
        RelatedFields: relatedFields || undefined,
        Paging: {
          Limit: -1
        },
      }
    )
      .then(res => store.commit('setDataDataInStore', {data: res.data.Data, entityName}))
      .catch((e) => console.error(e));
  },
  getData(store: ActionContext<AppState, AppState>,  data: {entityName: string, fields?: string[], relatedFields?: {[key: string]: string}}) {
    store.commit('setEmptyDataStore', data.entityName )
    store.dispatch('makeDataRequest', data).catch((e) => console.error(e));
  }
}
