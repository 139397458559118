<template>
  <div
    v-if="cookieMsgShow"
    id="cookie-accept-container"
    :style="hideOrShowMsg ? 'display: none;' : 'display: block;'"
  >
    <p>{{ $t("loc_cookie_msg") }}</p>
    <a
      class="btn-small pl-0"
      href="javascript:void(0);"
      @click="cookieAccept"
    >
      {{ $t("loc_accept") }}
    </a>
  </div>
</template>

<script>

import FILTERS from "../filters/filter";

export default {
  name: "CookiesMsg",
  data(){
    return {
      showMsg: FILTERS.getCookie('cookie_accepted')
    }
  },
  computed: {
    hideOrShowMsg(){
      if(this.readCookieAccepted){
        return true;
      }
      else
      {
        return false;
      }
    },
    readCookieAccepted(){
        return this.showMsg;
    },

    cookieMsgShow()
    {
      return this.$t("loc_cookie_msg") != "[loc_cookie_msg]" && this.$t("loc_cookie_msg") != "loc_cookie_msg" && this.$t("loc_cookie_msg").trim() != "";
    }
  },
  methods: {
    cookieAccept(){
      FILTERS.setCookie('cookie_accepted', true, 365);
      this.showMsg = FILTERS.getCookie('cookie_accepted');
    }
  }
};
</script>