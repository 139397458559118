

import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown.vue";
import {Options, Vue} from "vue-class-component";
import {Emit, Prop, Watch} from "vue-property-decorator";
import {FilterDataDropDownInput} from "@/libs/features/.storybook/interfaces";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";

@Options({
  components: {AppDropDown},
  emits: ['on-selected-values']
})
export default class FilterDropDown extends Vue {

  @Prop({default: []})
  filterData: FilterDataDropDownInput[] = [];

  @Prop({default: false, required: false})
  selectAll: boolean = false;

  @Prop({default: []})
  classList: string[] = [];

  @Prop({default: []})
  dropdownClassList: string[] = [];

  @Emit()
  onSelectedValues(val:SelectItemEmit[]) {}

  selectedValues: SelectItemEmit[] = [];

  @Watch('filterData', {immediate: true, deep: true})
  onFilterDataChange(val:FilterDataDropDownInput[]) {
    this.selectedValues = [];
    val.forEach(o => {
      //console.log(o);
      if (o.selectedValue && o.selectedValue.property) {
        this.selectedValues.push({property: o.selectedValue.property, value: (o.selectedValue.value)})
      }
    })
  }
  onChangeSelected(val:SelectItemEmit) {
    if (this.selectedValues.some(o => o.property === val.property)) {
      this.selectedValues = this.selectedValues.map(o =>
        ({...o, value: (o.property === val.property ? val.value : o.value)}))
    } else {
      this.selectedValues.push(val)
    }
    this.selectedValues = this.selectedValues.filter(o => o.value)
    this.onSelectedValues(this.selectedValues)
  }
}
