<template>
  <div>
    <h1 class="text-center text-3xl font-sans-bold m-3 my-12">
      HTZ Icons
    </h1>
    <div class="icons-kit container max-w-screen-lg mx-auto">
      <div class="grid gap-x-12 gap-y-3 grid-cols-1 md:grid-cols-2">
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-weather-cloudy-ico"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-weather-cloudy-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-weather-sunny-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-weather-sunny-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-youtube"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-youtube</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-facebook" />
          <span class="mls">icon-facebook</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-instagram" />
          <span class="mls">icon-instagram</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-linked-in" />
          <span class="mls">icon-linked-in</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-pinterest" />
          <span class="mls">icon-pinterest</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-globe-ico" />
          <span class="mls">icon-globe-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-twitter" />
          <span class="mls">icon-twitter</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-stars-ico" />
          <span class="mls">icon-stars-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-heart-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-heart-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-pin-ico" />
          <span class="mls">icon-pin-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-camera-ico" />
          <span class="mls">icon-camera-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-share-ico" />
          <span class="mls">icon-share-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cart-ico" />
          <span class="mls">icon-cart-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-accesibility-ico" />
          <span class="mls">icon-accesibility-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-ltt-ico"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-ltt-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-search-ico" />
          <span class="mls">icon-search-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-user-tools-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-user-tools-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-best-parties-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-best-parties-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-active-holiday-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-active-holiday-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cultural-attractions-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-cultural-attractions-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-hotel-brochure-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-hotel-brochure-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-lunch-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-lunch-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-natural-attractions-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-natural-attractions-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-nautic-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-nautic-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-other-offers-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-other-offers-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-perfect-instagram-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-perfect-instagram-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-rural-tourism-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-rural-tourism-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-spots-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-spots-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-wellness-ico" />
          <span class="mls">icon-wellness-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-hotel-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-hotel-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-plan-trip-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-plan-trip-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-travel-info-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-travel-info-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>


        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-bike-ico"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-bike-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-bus-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-bus-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-car-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-car-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-plane-ico"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-plane-ico</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-whatsapp"><span class="path1" /></i>
          <span class="mls">icon-whatsapp</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-telegram"><span class="path1" /></i>
          <span class="mls">icon-telegram</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-mail2"><span class="path1" /></i>
          <span class="mls">icon-mail2</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>
      </div>
      <hr class="my-6">
      <!-- nove ikone od 2.11.2021. -->
      <div class="grid gap-x-12 gap-y-3 grid-cols-1 md:grid-cols-2">
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-emergency"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-emergency</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-pdv-i-tax-free"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-pdv-i-tax-free</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-customs-info"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-customs-info</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-passport-visa"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-passport-visa</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-credit-cards"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-credit-cards</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-tips"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-tips</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-taxi-service"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-taxi-service</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cro-car"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-cro-car</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cro-bus"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /></i>
          <span class="mls">icon-cro-bus</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-train"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-train</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-campsite-icon"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-campsite-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cro-train"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-cro-train</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-bnb-icon"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-bnb-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-lost-and-found"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /></i>
          <span class="mls">icon-lost-and-found</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-hostel-icon"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-hostel-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-hotel-icon-m"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /></i>
          <span class="mls">icon-hotel-icon-m</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-hunting-lodges-icon"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /></i>
          <span class="mls">icon-hunting-lodges-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-unique-accomodation-icon"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-unique-accomodation-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-mountain-hut-icon"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-mountain-hut-icon</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-currency"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-currency</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>

        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-cro-map"><span class="path1" /></i>
          <span class="mls">icon-cro-map</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
          </button>
        </div>
      </div>
      <!-- nove ikone 29.12.2021. -->
      <hr class="my-6">
      <div class="grid gap-x-12 gap-y-3 grid-cols-1 md:grid-cols-2">
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-boats"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /></i>
          <span class="mls">icon-covid-boats</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-car"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-car</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-croatian-citizens"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-covid-croatian-citizens</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-disinfect-hands"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-disinfect-hands</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-distance"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /></i>
          <span class="mls">icon-covid-distance</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-enter-croatia-1"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-enter-croatia-1</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-enter-croatia-2"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-enter-croatia-2</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-events"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /></i>
          <span class="mls">icon-covid-events</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-facilities"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /></i>
          <span class="mls">icon-covid-facilities</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-ferry"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /></i>
          <span class="mls">icon-covid-ferry</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-flights"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-covid-flights</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-foreign-citizens"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-foreign-citizens</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-get-test"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /></i>
          <span class="mls">icon-covid-get-test</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-hotels"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /><span class="path18" /><span class="path19" /><span class="path20" /><span class="path21" /><span class="path22" /><span class="path23" /><span class="path24" /><span class="path25" /><span class="path26" /><span class="path27" /><span class="path28" /><span class="path29" /><span class="path30" /><span class="path31" /></i>
          <span class="mls">icon-covid-hotels</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-infected-case"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /></i>
          <span class="mls">icon-covid-infected-case</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-info"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-covid-info</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-insurance"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-covid-insurance</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-mask-indoor"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /></i>
          <span class="mls">icon-covid-mask-indoor</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-mask-outdoor"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /></i>
          <span class="mls">icon-covid-mask-outdoor</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-measures"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-covid-measures</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-negative-test"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-negative-test</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-outdoor"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /></i>
          <span class="mls">icon-covid-outdoor</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-public-transport"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-covid-public-transport</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-transit"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /><span class="path18" /><span class="path19" /><span class="path20" /><span class="path21" /><span class="path22" /></i>
          <span class="mls">icon-covid-transit</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-travel"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /></i>
          <span class="mls">icon-covid-travel</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-covid-wash-hands"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /></i>
          <span class="mls">icon-covid-wash-hands</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-climate" />
          <span class="mls">icon-dn-climate</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-communicate"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-dn-communicate</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-dig-nomad"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /><span class="path18" /><span class="path19" /><span class="path20" /><span class="path21" /><span class="path22" /><span class="path23" /><span class="path24" /><span class="path25" /></i>
          <span class="mls">icon-dn-dig-nomad</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_accommodation"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /></i>
          <span class="mls">icon-dn-faq_accommodation</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_best-places"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-dn-faq_best-places</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_calendar"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /></i>
          <span class="mls">icon-dn-faq_calendar</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_Croatia"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-dn-faq_Croatia</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_EU"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /></i>
          <span class="mls">icon-dn-faq_EU</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_healthcare"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /></i>
          <span class="mls">icon-dn-faq_healthcare</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_info"><span class="path1" /><span class="path2" /><span class="path3" /></i>
          <span class="mls">icon-dn-faq_info</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_internet-speed"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /><span class="path18" /><span class="path19" /><span class="path20" /></i>
          <span class="mls">icon-dn-faq_internet-speed</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_schools"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /></i>
          <span class="mls">icon-dn-faq_schools</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_taxes"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /></i>
          <span class="mls">icon-dn-faq_taxes</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_visa"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /></i>
          <span class="mls">icon-dn-faq_visa</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_visa-submit"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /></i>
          <span class="mls">icon-dn-faq_visa-submit</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-faq_work-type"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /></i>
          <span class="mls">icon-dn-faq_work-type</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-healthcare"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-dn-healthcare</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-hospitality"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-dn-hospitality</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-internet"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /></i>
          <span class="mls">icon-dn-internet</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-location"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-dn-location</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-nature"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /></i>
          <span class="mls">icon-dn-nature</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-permit-apply"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-dn-permit-apply</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-dn-visa"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /></i>
          <span class="mls">icon-dn-visa</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_camera"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /></i>
          <span class="mls">icon-web_camera</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_croatia"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /></i>
          <span class="mls">icon-web_croatia</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_electricity_ic"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-web_electricity_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_events_ic"><span class="path1" /><span class="path2" /></i>
          <span class="mls">icon-web_events_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_holidays_ic"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /></i>
          <span class="mls">icon-web_holidays_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_news_ic"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /></i>
          <span class="mls">icon-web_news_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_pets_ic"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /><span class="path12" /><span class="path13" /><span class="path14" /><span class="path15" /><span class="path16" /><span class="path17" /><span class="path18" /></i>
          <span class="mls">icon-web_pets_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
        <div class="flex items-center justify-between space-x-6">
          <i class="icon icon-web_virtual-walk_ic"><span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" /><span class="path9" /><span class="path10" /><span class="path11" /></i>
          <span class="mls">icon-web_virtual-walk_ic</span>
          <button
            class="btn btn-blue btn-copy"
            @click="copyText($event)"
          >
            Copy Icon
            <button />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import useClipboard from 'vue-clipboard3'

export default defineComponent({
  name: "HtzIcons",
  setup() {
    const { toClipboard } = useClipboard()
    const copyText = async (event) => {
      var textToCopy = event.target.previousElementSibling.textContent;
      try {
        await toClipboard(textToCopy)
        console.log('Copied to clipboard')
      } catch (e) {
        console.error(e)
      }
    }
    return { copyText }
  },
})
</script>
<style>
.icons-kit .icon{font-size: 48px; min-width: 70px;}
.btn{
  width: 60px;
  padding: 12px;
  height: auto;
  min-height: auto;
  font-size: 12px;
  min-width: 120px;
}
</style>
