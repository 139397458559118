import {Prop, Watch} from "vue-property-decorator";
import {I18nMixin} from "@/mixins/i18nMixin";
import {AppState, ComponentState, ModuleData, ModuleInfo, ModuleInterface, PageDataInterface} from "@/interfaces";
import throttle from "lodash/throttle";
import {Action, Mutation} from "vuex-class";
import {LocationQueryValue} from "vue-router";
import { a11yKeys } from "@/services/a11y.helpers";
import {getModuleDataKey,  setStoreModule} from "@/store/store.utils";
import FILTERS from '@/components/filters/filter';
import CONSTANTS from '@/components/shared/constants';
import {AxiosResponse} from "axios";
import axiosInstance from "@/services/http-common";
import * as QueryString from "querystring";

export class AppMixin extends I18nMixin {
  @Action('setPersone')
  setPersone!: (val: {[key: string] : string}) => void

  @Action('setupHyperlinks')
  setupHyperlinks!: () => void

  @Action('setupEntityId')
  setupEntityId!: () => void

  @Mutation('setA11yValue')
  setA11yValue!: ({key, value}: {key: string, value: boolean}) => void

  @Action('setupParams')
  setupParams!: () => void

  @Action('setMenuData')
  setMenuData!: () => void

  @Action('setBreadcrumbs')
  setBreadcrumbs!: () => void

  @Action('makeRequest')
  makeRequest!: (val: {moduleInfo: ModuleInfo, }) => void

  @Action('makeNewRequest')
  makeNewRequest!: (val: {moduleInfo: ModuleInfo, query: Record<string, string | LocationQueryValue[] | null> }) => void
  @Action('setModuleInfo')
  setModuleInfo!: (val: {moduleInfo: ModuleInfo, query: Record<string, string | LocationQueryValue[] | null> }) => void

  @Prop() lang = ''
  @Prop() first = ''
  @Prop() second = ''
  @Prop() third = ''
  @Prop() forth = ''
  @Prop() fifth = ''
  @Prop() sixth = ''
  @Prop() seventh = ''
  @Prop() eight = ''

  showAdminToolbar = false;
  handleDebouncedScroll: any = null;
  lazyLoadModuleIsActive = false;
  slice = 4;
  loadedComponentQuantity = 4;
  loadedInitialComponents = true
  moduleDataLoading(state: ComponentState): boolean {
    return !!state.moduleInfo && this.$store.state[state.moduleInfo.Module.EntityID.toString()]?.isLoading
    //return this.$store.state.moduleDataList[getModuleDataKey(moduleInfo)]?.loaded
  }
  moduleDataError(state: ComponentState) {
    return this.$store.state[state.moduleInfo!.Module.EntityID.toString()]?.isError
  }
/*  get everyModule(): boolean {
    return this.appState.PageData.ModuleData.slice(0, this.loadedComponentQuantity)
      .every(o => {
        return this.moduleDataLoading(o) && !this.moduleDataError(o)
      })
  }*/
  @Watch('allForModuleComplete', {immediate: true})
  onEveryModule(val: boolean) {
    if (val) {
      this.loadedInitialComponents = true;
    }
  }
  get allForModuleComplete(): boolean {
    //console.log(this.firstModule)
    //console.log(this.secondModule)
    //console.log(this.thirdModule)
    //console.log(this.forthModule)
    for(let i = 0; i < this.loadedComponentQuantity; i++) {
      if (!this.moduleReady(i)) {
        return false
      }
    }
    return true //this.firstModule && this.secondModule && this.thirdModule && this.forthModule
  }
  notPersona(moduleInfo: ModuleInfo): boolean {
    return moduleInfo.Module?.ModuleVariation?.Title.replace(/\s/g, '') !== 'FormaPersona'
  }
  isBindingNotApplicable(storeModule: ComponentState):boolean {
    return !!storeModule.moduleInfo?.Module?.ModuleVariation?.BindingNotApplicable && this.notPersona(storeModule.moduleInfo!)
  }
  isModuleValidBinding(storeModule: ComponentState): boolean {
    return !storeModule.moduleInfo?.Module.Binding.SourceName
  }
  isModuleDataNopEmpty(storeModule: ComponentState): boolean {
    return (storeModule.moduleData?.length ?? 0) > 0
  }
  moduleIsValid(storeModule: ComponentState): boolean {
    if (this.isBindingNotApplicable(storeModule)) {return true}
    if (this.isModuleValidBinding(storeModule)) {return false}

    if (!this.moduleDataLoading(storeModule) && !this.moduleDataError(storeModule) && this.isModuleDataNopEmpty(storeModule)) {
      return true
    } else {
      return !!storeModule.filters.length;
    }
  }

  moduleReady(i: number): boolean {
    return this.appState.PageData.ModuleData[i] && !!this.$store.state[this.appState.PageData.ModuleData[i]?.Module.EntityID.toString()]?.moduleInfo ?
      this.moduleIsValid(this.$store.state[this.appState.PageData.ModuleData[i].Module.EntityID.toString()]) :
      false
  }
/*  get firstModule(): boolean {
    return this.appState.PageData.ModuleData[0] ? this.moduleDataLoading(this.appState.PageData.ModuleData[0]) && !this.moduleDataError(this.appState.PageData.ModuleData[0]) : true
  }
  get secondModule(): boolean {
    return this.appState.PageData.ModuleData[1] ? this.moduleDataLoading(this.appState.PageData.ModuleData[1]) && !this.moduleDataError(this.appState.PageData.ModuleData[1]) : true
  }
  get thirdModule(): boolean {
    return true// this.appState.PageData.ModuleData[2] ? this.moduleDataLoading(this.appState.PageData.ModuleData[2]) && !this.moduleDataError(this.appState.PageData.ModuleData[2]) : true
  }
  get forthModule(): boolean {
    return this.appState.PageData.ModuleData[3] ? this.moduleDataLoading(this.appState.PageData.ModuleData[3]) && !this.moduleDataError(this.appState.PageData.ModuleData[3]) : true
  }*/
 /* get firstModuleLoaded(): boolean {
    //console.log(this.everyModule);
    if (
      this.everyModule
    ) {
      this.firstLoadedInitialComponents = true;
      return true;
    } else {
      return false;
    }
  }
  get loadedInitialComponents(): boolean {
    return !this.firstLoadedInitialComponents ?
      this.firstModuleLoaded:
      true
  }*/
  get showErrorMessageOnErrorRequest(): boolean {
    return this.$store.state.Params.find(o => o.Key === 'showErrorMessageOnErrorRequest')?.Value === 'true' ?? false;
  }
  get moduleToShow(): ModuleInfo[] {
    //@ts-ignore
    return this.pageData.ModuleData

  }

  get appState(): AppState {
    return this.$store.state
  }
  get pageData(): PageDataInterface {
    return this.$store.state.PageData
  }
  get modulePerScreen() {
    return Math.ceil(window.innerHeight / 500) > this.slice ? Math.ceil(window.innerHeight / 500) : this.slice;
  }
  mounted() {
    this.slice = this.lazyLoadModuleIsActive ? 4 : this.pageData.ModuleData.length;
    let loginCookie = atob(FILTERS.getCookie(CONSTANTS.COOKIES.LoginCookie));
    this.showAdminToolbar = !this.pageData.IsProduction && loginCookie != '' && FILTERS.getDomainFromUrl(loginCookie) == FILTERS.getDomainFromUrl(window.location.origin);
  }
  checkPersonaQuery(){
    const params: {[key: string] : string} = {};
    if (this.$route.query.Persona ?? this.$route.query.persona) {
      //@ts-ignore
      params['Persona'] = (this.$route.query.Persona ?? this.$route.query.persona).toString();
    }
    if (this.$route.query.Product ?? this.$route.query.product) {
      //@ts-ignore
      params['Product'] = (this.$route.query.Product ?? this.$route.query.product).toString();
    }
    this.setPersone(params)
  }

  getAllModule(module: ModuleInterface[]): ModuleInterface[] {
    const list: ModuleInterface[] = [];
    module.forEach(m => {
        list.push(m);
      if (m.ChildModules) {
        list.push(...this.getAllModule(m.ChildModules));
      }
    });
    return list
  }
  get listAllModules(): ModuleInterface[] {
    return this.getAllModule(this.pageData.ModuleData.map(o => o.Module))
  }
  storeModuleData() {
    this.listAllModules.forEach(m => {
      // TODO change on new filter
      /*if (m.Module.ModulLayoutVue === 'Review') {
        m.Module.Api.RequestBody.WhereStatement = `$(ParentID) IN[${this.$store.state.PageData.PageId}]`
      }*/
      setStoreModule(m.EntityID.toString())
    })
  }
  deleteOldModuleData() {
    this.pageData.ModuleData.forEach(m => {
      this.$store.unregisterModule(m.Module.EntityID.toString())
    })
  }
  getA11y() {
    Object.keys(a11yKeys).forEach(key => {
      if (localStorage.getItem(key)) {
        this.setA11yValue({key, value: JSON.parse(localStorage.getItem(key) as string)})
      }
    })
  }
  getFavorites() {
    if (localStorage.getItem('favorites')) {
      this.$store.state.favorites = JSON.parse(localStorage.getItem('favorites') as string)
    }
  }
  created() {
    if (this.lazyLoadModuleIsActive) {
      this.handleDebouncedScroll = throttle(this.handleScroll, 200);
      window.addEventListener('scroll', this.handleDebouncedScroll);
    }
    else {
      this.slice = this.pageData.ModuleData.length
    }
  }
  setNewTitle(res: AxiosResponse<PageDataInterface>) {
    document.title = res.data.Seo?.PageTitle ?? ''
  }
  getModuleData() {
    /*this.pageData.ModuleData.forEach(mi => {
      this.setModuleInfo({moduleInfo: mi, query: this.$route.query})

      if (mi.Module.Api.RequestBody.EntityTypeID && mi.Module.ModulLayoutVue !== 'FormaPersona') {
        this.makeRequest(
          {
            moduleInfo: mi,
          })
      } else {
        this.$store.commit('loadingFinished', {
          id: mi.Module.EntityID.toString()
        })

      }
    })*/
    this.listAllModules.forEach(mi => {
      //@ts-ignore
      //this.setModuleInfo({moduleInfo: mi}) //({moduleInfo: { Module: {...mi.Module, ModuleProperties: prepareModuleProperties(mi.Module.ModuleProperties)} }, query: this.$route.query})
      /*if (mi.Module.newModuleData) {*/
        this.makeNewRequest(
          {
            moduleInfo: {Module: mi},
            query: this.$route.query
          })
     /* } else {
        if (!!mi.Module.Binding.SourceName && mi.Module.ModulLayoutVue !== 'FormaPersona') {
          this.makeRequest(
            {
              moduleInfo: mi,
            })
        } else {
          this.$store.commit('loadingFinished', {
            id: mi.Module.EntityID.toString()
          })

        }
      }*/
    })

  }
  destroyed () {
    if (this.lazyLoadModuleIsActive) {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
  }
  handleScroll () {
    this.slice =
      this.$store.state.forceLoadAllModule ? (
        this.pageData.ModuleData.length
      ) : (
        (this.modulePerScreen + Math.floor((window.scrollY + 100) / 500) > this.slice) &&
        (this.modulePerScreen + Math.floor((window.scrollY + 100) / 500) <= this.pageData.ModuleData.length) ?
          this.modulePerScreen + Math.floor((window.scrollY + 100) / 500) :
          this.slice
      )

    /*
    console.log(this.slice);
    console.log(this.modulePerScreen);
    console.log(window.scrollY);
    console.log(Math.floor((window.scrollY + 250) / 500));*/
  }
}
