
<template v-if="modalShown">

  <!-- TODO: hendlati clear msgs -->
  <div
    v-if="errorMessage"
    class="login-msg login-msg--error"
  >
    <p>{{ $t(errorMessage) }} </p>
    <button @click="clearLoginErrorMessage">
      ×
    </button>
  </div>
  <div
    v-if="infoMessage"
    class="login-msg login-msg--info"
  >
    <p>{{ $t(infoMessage) }} </p>
    <button @click="clearLoginInfoMessage">
      ×
    </button>
  </div>

  <AppInput
    v-model="email"
    :input-type="'email'"
    :placeholder="$t('loc_login_email')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full pb-1']"
    :show-icon="false"
    :value="''"
  />
  <br>

  <button
    class="btn-blue-border w-full mt-4 mb-2"
    data-cy="loginBtn"
    @click="sendEmailForgotPassword(email)"
  >
    {{ $t('loc_login_send_email_forgot_password_button') }}
  </button>
</template>

<script>

import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import {mapActions, mapGetters} from "vuex";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"


export default {
  name: "LoginForm",
  components: {
    AppInput
  },
  data() {
    return {
      email: '',
      LOGIN_TYPE: this.$constants.LOGIN_TYPE
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'LoggedInUserModule/errorMessage',
      infoMessage: 'LoggedInUserModule/infoMessage'
    }),
    modalShown() {
      return this.$store.state.LoggedInUserModule.loginState === LoginStateEnum.FORGOT_PASSWORD
    }
  },
  methods: {
    ...mapActions({
      closeAuthModal: 'LoggedInUserModule/closeAuthModal',
      sendEmailForgotPassword: 'LoggedInUserModule/sendEmailForgotPassword',
    }),
    closeModal() {
      this.closeAuthModal();
    },
  },
};
</script>

<style lang="scss" scoped>



</style>
