

import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {ComponentState, ModuleDataForModule, ModuleInfo, ModulePicturesInterface} from "../../interfaces";
import {Action} from "vuex-class";
import {moduleExcludedLoadMore} from "@/services/load-more.settings";
import {getModuleDataKey} from "@/store/store.utils";
import {moduleDataForModule} from "@/mixins/shared-mixin-functions";
import {newPrepareImage} from "@/components/utils/prepareImages";

@Options({
  name: 'LoadMore'
})
export default class LoadMore extends Vue {
  @Prop({required: true})
  moduleInfo!: ModuleInfo

  @Action('makeNewRequest')
  makeRequest!: ({moduleInfo}: {moduleInfo: ModuleInfo, merge?: boolean}) => void

  showInfo = false
  moduleExcludedLoadMore: string[] = moduleExcludedLoadMore
  get renderLoadMore(): boolean {
    return this.loaded && this.moduleNotExcluded && this.hasLoadMore && !this.loadMoreComplete && !this.moduleHasSlider
  }
  get moduleNotExcluded(): boolean {
    return  !this.moduleExcludedLoadMore.includes(this.moduleInfo.Module?.ModuleVariation?.Title.replace(/\s/g, ''))
  }
  get loaded() {
    //return true
    return this.storeModule.moduleInfo ?
      (this.storeModule.moduleInfo.Module.Binding.SourceEntityID ?
        !this.storeModule.isLoading && !this.storeModule.isError :
        true) :
      false
  }
  get moduleHasSlider(): boolean {
    return this.moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && (this.storeModule.moduleData || []).length > 4
  }
  get storeModule(): ComponentState {
    return this.$store.state[this.moduleInfo.Module.EntityID.toString()] ?? {
      galleryType: 'default',
      errorMessage: '',
      moduleInfo: null,
      moduleData: [],
      filters: [],
      childData: [],
      triggerMounted: 0,
      isLoading: false,
      isError: false,
      pagination: {
        CurrentPage: 1,
        LastPage: 1,
        PerPage: 1,
        From: 1,
        To: 1,
        Total: 1,
      },
      paginationActive: false,
    }
  }
  get loadMoreLimitModule(): string {
    return this.storeModule.moduleInfo?.Module.Paging.Offset?.toString() || this.loadMoreLimitData
  }
  get loadMoreLimitData(): string {
    return this.storeModule.moduleInfo?.Module.Paging.DataLimit?.toString() || this.loadMoreLimitParams
  }
  get loadMoreLimitParams(): string {
    return this.$store.state.Params.find(o => o.Key === 'LoadMoreLimit')?.Value || '30'
  }
/*(
  this.storeModule.moduleInfo && this.storeModule.moduleInfo.Module.Paging.Total?
+this.storeModule.moduleInfo.Module.Paging.Total > (this.storeModule.moduleData || []).length :
    false
) :*/

  get paginationLimit(): boolean {
    return this.storeModule.paginationActive ?
      this.moduleDataForModule.length < this.storeModule.pagination.Total :
      true
  }
  get dataLimitLimit(): boolean {

    /* console.log(this.storeModule.moduleData!.length);
    console.log(this.storeModule.moduleInfo?.Module.Paging.Total); */
    return this.storeModule.moduleInfo?.Module.Paging.DataLimit ?
      (
        this.moduleDataForModule.length < +this.storeModule.moduleInfo?.Module.Paging.DataLimit
        /*  &&
        (
          this.storeModule.paginationActive ?
            true :
            this.storeModule.moduleData!.length > +this.storeModule.moduleInfo?.Module.Paging.Total
        )*/
      ) :
      true
  }
  get filterLimit(): boolean {
/*    console.log(this.storeModule.moduleData!.length);
    console.log(this.moduleDataForModule.length);*/
    return this.isFilterActive ?
      this.storeModule.moduleData!.length <= this.moduleDataForModule.length && this.moduleDataForModule.length < this.storeModule.pagination.Total :
      true
  }
  get noConditionLimit(): boolean {
    return !this.isFilterActive &&
    !this.storeModule.moduleInfo?.Module.Paging.DataLimit &&
    !this.storeModule.paginationActive ?
      this.moduleDataForModule.length <= +this.loadMoreLimitParams:
      true
  }
  get hasLoadMore(): boolean {
    /*console.log(this.noConditionLimit);
    console.log(this.paginationLimit);
    console.log(this.dataLimitLimit);
    console.log(this.filterLimit);*/
    return true // this.noConditionLimit && this.paginationLimit && this.dataLimitLimit && this.filterLimit
  }
  get loadMoreCompleteDataLimit(): boolean {
    return true
    /*return this.storeModule.moduleInfo?.Module.Paging.DataLimit ?
      this.moduleDataForModule.length >= +this.storeModule.moduleInfo?.Module.Paging.DataLimit :
      this.moduleDataForModule.length >= this.storeModule.moduleData!.length;*/
  }
  get loadMoreCompletePaginationLimit(): boolean {
    return this.storeModule.paginationActive ?
      this.moduleDataForModule.length >= this.storeModule.pagination.Total :
     this.loadMoreCompleteDataLimit
  }

  get isFilterActive(): boolean {
    return this.storeModule.filters.length > 0
  }
  get loadMoreComplete(): boolean {
    return  this.loadMoreCompletePaginationLimit ;
  }
  get galleryType(): keyof  ModulePicturesInterface {
    return this.storeModule.galleryType ;
  }
  get hasVideoPoster(): boolean {
    return this.storeModule.hasVideoPoster ;
  }
  get moduleDataForModule():ModuleDataForModule[] {
    return this.storeModule.moduleData || []
    //return moduleDataForModule(this.storeModule,this.moduleHasSlider,+this.loadMoreLimitParams,this.galleryType,this.hasVideoPoster )
  }
  get loadMoreQuantity(): number {
    return (this.$store.state.Params.some(o => o.Key === 'LoadMoreQuantity') ?
      +this.$store.state.Params.find(o => o.Key === 'LoadMoreQuantity')!.Value :
      10)
  }

  prepareModuleInfoLoadMore(moduleInfo: ModuleInfo): ModuleInfo {
    //const moduleInfoToUse: ModuleInfo = JSON.parse(JSON.stringify(moduleInfo))
    if (this.storeModule.paginationActive) {
      this.storeModule.pagination.CurrentPage++;
      moduleInfo.Module.Paging.Offset =
        (
          +(moduleInfo.Module.Paging.Offset || 0) +
          +(moduleInfo.Module.Paging.ItemPerPage || 20)
        ).toString();
      this.storeModule.pagination.Offset = this.storeModule.pagination.Limit * (this.storeModule.pagination.CurrentPage - 1)
    }
    return moduleInfo
  }
  loadMoreRequest(qtyToAdd: number): void {

    this.makeRequest({
      moduleInfo: this.prepareModuleInfoLoadMore(this.moduleInfo),
      merge: true
    })
  }
  loadMore() {
    const qtyToAdd: number = this.storeModule.paginationActive ?
      this.storeModule.pagination.Limit :
      this.loadMoreQuantity
    if (this.storeModule.paginationActive) {
      this.loadMoreRequest(qtyToAdd)
    }
    this.storeModule.loadMore += qtyToAdd

  }
}
