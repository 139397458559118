<template>
      <i
        class="fas fa-search text-color-nav-text hover:text-color-secondary text-32"
      @click="openModal"
    />
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      :open="isOpen"
      @close="closeModal"
    >
      <div class="dialog-container fixed inset-0 z-50 overflow-y-auto"
          :class="{'dialog-has-covid-track': (hasCovidTrack === true || hasCovidTrack === null)}">
        <div class="dialog-container-inner">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay 
              class="dialog-overlay fixed inset-0"   
              :class="{'dialog-has-covid-track': (hasCovidTrack === true || hasCovidTrack === null)}"

            />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
            role="button"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="dialog-content-container inline-block w-full h-full p-6 overflow-hidden overflow-y-auto transition-all transform bg-color-secondary bg-opacity-90 shadow-xl pt-0"
            >
              <div class="max-w-screen-2xl mx-auto w-full">
                <button
                  type="button"
                  class="text-color-nav-text text-68 cursor-pointer float-right block leading-none mr-4 h-10"
                  @click="closeModal"
                >
                  ×
                </button>
              </div>
              <div class="clear-both" />
              <DialogTitle
                as="p"
                class="text-2xl font-lg leading-6 text-color-grey w-full max-w-screen-lg mx-auto"
                style="color: #c8c8c8;"
              >
                I'm searcing for
              </DialogTitle>
              <AppInput
                :placeholder="$t('loc_search_placeholder')"
                :class-list="['search-modal-input border-b-2 border-htz-white text-htz-white text-48 w-full px-1 relative z-1 bg-transparent']"
                :show-icon="true"
                :value="'todo'"
                class="search-modal-container w-full max-w-screen-lg mx-auto mt-5"
              />

              <!-- TAB BAR - TODO: vidjeti na što će se spajati i može li se nekako incluedati tab bar ovdje-->
              <div class="tab-nav-slider-container mt-6">
                <div class="tab-nav-slider-container-inner w-full lg:flex lg:justify-center lg:items-center">
                  <swiper
                    ref="tabBarSwiper"
                    free-mode="true"
                    free-mode-sticky="true"
                    free-mode-momentum-ratio="0.25"
                    free-mode-velocity-ratio="0.25"
                    free-mode-minimum-velocity="0.1"
                    mousewheel-control="true"
                    mousewheel-sensitivity="0.5"
                    slide-to-clicked-slide="true"
                    slides-per-view="auto"
                    touch-ratio="0.2"
                    class="tab-nav-slider"
                    @swiper="onSwiper"
                  >
                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 1 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 2 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 3 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 4 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 5 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 6 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 7 </a></li>
                    </swiper-slide>

                    <swiper-slide class="w-auto">
                      <li><a>  Tab element 8 </a></li>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>

              <!-- FILTER MODUL - TODO -->

              <!-- SEARCH RESULTS -->
              <div class="max-w-screen-lg mx-auto mt-10">
                <p
                  class="text-2xl font-lg leading-6 text-color-grey w-full max-w-screen-lg mx-auto"
                  style="color: #c8c8c8;"
                >
                  Search results for:
                </p>              
                <p class="text-color-primary text-48 mt-3"> Zlatna bula </p>

                <div class="search-results-container mx-auto">
                  <div class="search-result border-b border-white border-opacity-25 py-6">
                    <span class="card-overtitle text-color-nav-text"> history </span>
                    <h3 class="subtitle-1 text-color-nav-text">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are located off the southwest coast of the island of Hvar, Croatia, opposite the entrance to the Hvar (city) harbour. 
                    </p>
                  </div>

                  <div class="search-result border-b border-white border-opacity-25 py-6">
                    <span class="card-overtitle text-color-nav-text"> history </span>
                    <h3 class="subtitle-1 text-color-nav-text">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are located off the southwest coast of the island of Hvar, Croatia, opposite the entrance to the Hvar (city) harbour. 
                    </p>
                  </div>

                  <div class="search-result border-b border-white border-opacity-25 py-6">
                    <span class="card-overtitle text-color-nav-text"> history </span>
                    <h3 class="subtitle-1 text-color-nav-text">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are located off the southwest coast of the island of Hvar, Croatia, opposite the entrance to the Hvar (city) harbour. 
                    </p>
                  </div>

                  <button class="btn-transparent mx-auto flex mt-6"> More results </button>

                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
//za tab bar:
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperListOptions } from "@/services/swiperOptions";


export default {
  components: { 
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,  
    AppInput,
    //za tab bar:
    Swiper,
    SwiperSlide,
   },
   props: {
    hasCovidTrack: {
      type: Boolean,
      default: false,
    },
   },
  setup() {
    const isOpen = ref(false)

    return {
      isOpen,
      closeModal() {
        let searchModal = document.querySelector(".dialog-container");
        if(searchModal){searchModal.classList.remove("dialog-container-fixed");} 
        isOpen.value = false
      },
      openModal() {
        isOpen.value = true
        let navHeight = document.querySelector(".nav").offsetHeight;
        if(navHeight < 66){
          setTimeout(() => {
            let searchModal = document.querySelector(".dialog-container");
            if(searchModal){searchModal.classList.add("dialog-container-fixed");} 
          }, 50);
        }
      },
      
    }
  },
  data() {
    return{
      //za tab bar: 
      swiper: {},
      swiperListOptions: {...swiperListOptions},
      showCovidTrack: sessionStorage.getItem('showCovidTrack')
    }
  },
  methods: {
     onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style lang="scss" scoped>

//dialog
.dialog-container,
.dialog-overlay{ top: 64px; @screen lg{top: 120px;}}

.dialog-overlay.dialog-has-covid-track,
.dialog-container.dialog-has-covid-track{
  top: 104px;
  @screen lg{
    top: 160px;
  }
}

.dialog-container-inner,
.dialog-content-container{
  height: 100%; min-height: calc(100vh - 64px);
  @screen lg{min-height: calc(100vh - 120px);}
}


.dialog-container-fixed{
  @screen lg{top: 64px;}
  .dialog-container-inner,
  .dialog-content-container{
    @screen lg{min-height: calc(100vh - 64px);}
  }
}

//search
.icon-search-container:hover{.icon-search-ico:before{@apply text-color-primary;}}
.icon-search-ico:hover{&:before{@apply text-color-primary;}}
button:focus{ @apply outline-none;}

//tab bar
.tab-nav-slider-container {
  @apply min-h-20 border-t border-b border-white border-opacity-25;
  @screen md{
    @apply min-h-24;
  }
  .tab-nav-slider{
    @apply mx-auto min-h-20  max-w-screen-xl min-h-20  flex items-center justify-center flex-wrap py-3 transition-all duration-300 ease-in-out;
    @screen sm{
      @apply min-h-24;
    }
    .swiper-slide{width: auto !important; opacity: 1;}
      li a{
        @apply w-full h-full block text-color-nav-text uppercase text-14 font-sans-bold px-4 transition-all duration-100 ease-in-out;
        letter-spacing: 1px;
        @screen sm{
          @apply text-15;
        }
        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
        }
        &:hover, &:active{
          @apply text-color-primary;
        }

      }
      li a.active{
        @apply text-color-primary;
        &:after {
          content: "";
          @apply bg-color-primary;
        }
      }
  }
  .tab-nav-slider-container-inner:not(.tab-nav-slider-fixed) .swiper-slide{
    li a{
      @apply text-color-nav-text;
    }
    li a.active{
      @apply text-color-primary;
      &:after {
        content: "";
        @apply bg-color-primary;
      }
    }
    /* &:first-child li a{
        @apply text-color-primary;
    } */
  }

  .tab-nav-slider-fixed{
    @apply fixed z-30 left-0 right-0;
    top: 64px;
  }
}

</style>