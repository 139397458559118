
import {Vue} from "vue-class-component";
import {Action} from "vuex-class";
import {Prop} from "vue-property-decorator";
import {RelatedInterface} from "@/interfaces";

export default class Favorite extends Vue{

  @Prop({required: false})
  entity: any;
  @Prop({required: false, default: false})
  inline: boolean = false;
  isHover = false;
  showOverlay = false;

  @Action('addToFavorite') addToFavorite(favorite: RelatedInterface): void {}
  @Action('removeToFavorite') removeToFavorite(favorite: RelatedInterface): void {}

  get isFavorite(): boolean {
    return this.$store.state.favorites.some(o => o.EntityID.toString() === this.entity?.EntityID?.toString())
  }
  toggleMe() {
    if (this.entity) {
      if (this.isFavorite) {
        if(this.inline) {
          this.isHover= false;
          this.removeToFavorite(this.entity)
        }
        this.showOverlay = true;
      } else {
        const entityToAdd = this.$store.state.entitiesStore['ModuleData']?.entities[this.entity.EntityID.toString()] ?? this.entity;
        this.addToFavorite(entityToAdd)
      }
    }
  }
  removeMe() {
    if (this.entity) {
      if (this.showOverlay) {
        this.removeToFavorite(this.entity)
        this.showOverlay = false;
      }
    }
  }
  cancelMe() {
    this.showOverlay = false;
  }
}
