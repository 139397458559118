<template>
  <div>
    <footer class="footer-container max-w-screen-3xl mx-auto bg-white">
      <!-- <div class="footer-big container max-w-screen-2xl mx-auto">
        <FooterActionButtons
          v-if="gotMenuData.length > 0"
          :module-info="filterMenu('FOOTER_MENU')"
        />
      </div> -->
      <div class="module-border border-2 container mx-auto mt-8 mb-6" />
      <div class="footer-big container max-w-screen-xl mx-auto lg:px-6">
        <FooterMenuSocialIcons
          v-if="gotMenuData.length > 0"
          :module-info="filterMenu('ICONS')"
        />
        <div class="footer-main-and-logos lg:flex justify-between">
          <FooterMenuMain
            v-if="gotMenuData.length > 0"
            :module-info="filterMenu('FOOTER_MENU')"
          />
          <div class="flex flex-col items-start justify-center w-full">
            <FooterMenuLogos
              v-if="gotMenuData.length > 0"
              :module-info="filterMenu('FOOTER_LOGOS')"
            />
          </div>
        </div>
        <div class="flex items-start justify-start flex-col md:flex-row">
          <FooterMenuNewsletter
            v-if="gotMenuData.length > 0"
            :module-info="filterMenu('FOOTER_MENU_NEWSLETTER').slice(0, 2)"
          />
          <!--TODO implementacija newsletter forme -->
          <div
            v-if="false"
            class="flex flex-col content-start items-center md:items-end w-full lg:w-6/12 pb-5 py-5 lg:pt-0"
          >
            <div class="flex md:max-w-450 flex-wrap content-center ">
              <h3 class="overtitle-1 w-full md:text-15 text-color-darkgrey text-center md:text-left pb-5">
                Subscribe to our newsletter
              </h3>
              <div class="flex justify-center md:justify-start w-full">
                <input
                  id="subscribe"
                  type="text"
                  name="subscribe"
                  class="w-full border border-color-midgrey min-h-60 px-4"
                  placeholder="Enter your email"
                >
                <a
                  href="#"
                  class="btn-grey min-w-32 lg:min-w-40"
                >Subscribe</a>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="$t('loc_footer_logos')"
          class="footer-extra-text p-xs lg:w-12/12 flex flex-wrap text-color-grey justify-center lg:justify-start text-center lg:text-left leading-relaxed md:leading-loose"
          v-html="$t('loc_footer_logos')"
        />
      </div>
      <FooterMenuBottom
        v-if="gotMenuData.length > 0"
        :module-info="filterMenu('FOOTER_BOTTOM')"
      />
      <!--PARAMS here -->
      <template v-if="orgSnippet">
        <JsonLdManager
          :scheme-data="orgSnippet"
          :scheme-type="'Organization'"
          :script-id="'organizationSnippet'"
        />
      </template>
    </footer>
    <CookieMsg />
  </div>
</template>

<script>

import "@/css/modules/_footer.scss";
import FooterMenuMain from "./footer/FooterMenuMain";
import FooterMenuNewsletter from "./footer/FooterMenuNewsletter";
import FooterMenuBottom from "./footer/FooterMenuBottom";
import FooterMenuSocialIcons from "./footer/FooterMenuSocialIcons";
import FooterMenuLogos from "./footer/FooterMenuLogos";
import FooterActionButtons from "./footer/FooterActionButtons";
import CookieMsg from "./CookieMsg.vue";
import JsonLdManager from "@/components/utils/json-ld-manager";

import { menuMixin } from "@/mixins";

export default {
  name: "Footer",
  components: {
    FooterMenuMain,
    FooterMenuNewsletter,
    FooterMenuBottom,
    FooterMenuSocialIcons,
    FooterMenuLogos,
    //FooterActionButtons,
    JsonLdManager,
    CookieMsg
},
  mixins: [menuMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return{
      orgSnippet: undefined,
    }
  },
  computed: {
    getParams(){
        return this.$store.state.Params;
    },
  },
  watch:{
    getParams() {
      this.setParams();

    }
  },
  methods: {
    setParams(){
      this.orgSnippet = JSON.parse(this.$store.state.Params.find(o => o.Key === 'OrganizationSnippet')?.Value || "{}");
    }
  }
};
</script>
