<template>
  <VirtualLink
    :href="moduleData.ExternalLink ? moduleData.ExternalLink : moduleData.SefUrl"
    :external-link="moduleData.ExternalLink"
    class-list="h-full block relative overflow-hidden"
  >
    <vue-picture
      v-if="moduleData.MediaGallery.length > 0"
      :image="moduleData.MediaGallery[0]"
      :hide-tooltip="false"
      :title="moduleData.Title"
      css-class="card-img object-cover"
    />
    <img
      v-else-if="moduleData.MediaGallery.length === 0"
      class="w-full h-full object-cover transition-all duration-300"
      :src="defaultCardImg"
      alt="Default mosaic image"
      @error="imageLoadError"
    >
  </VirtualLink>
  <JsonLdManager
    :entity-id="entityId"
    :entity="moduleData"
  />
</template>

<script>
import VuePicture from "./VuePicture";
import JsonLdManager from "@/components/utils/json-ld-manager";

export default {
    name: "MosaicItem",
    components: {
        VuePicture,
        JsonLdManager
    },
    props: {
        moduleData: {
            type: Object,
            default: () => ({}),
        },
        moduleProperties: {
            type: Object,
            default: () => ({}),
        },
        moduleType: {
          type: String,
          default: ''
        },
        entityId: {
          type: Number,
          required: true
        }
    },
    data() {
      return {
        defaultCardImg: ""

      }
    },
    mounted() {
      this.getDefaultCardImg()

    },
    methods: {
      getDefaultCardImg(){
        this.defaultCardImg = '/cmsmedia/EditUtils/Cards/default-mosaic.png';
      },
      imageLoadError(){
        this.defaultCardImg =  '/dist/img/default-mosaic.png';
      }
    },

};
</script>
