<template>

  <!-- TODO: hendlati clear msgs -->
  <div
    v-if="infoMessage"
    class="login-msg login-msg--info"
  >
    <p> {{ infoMessage }} </p>
    <button @click="dismissInfoMessage">
      ×
    </button>
  </div>

  <div
    v-if="registrationFormErrors"
    class="login-msg login-msg--error"
  >
    <p>{{ $t(registrationFormErrors) }}</p>
    <button @click="dismissFormErrors">
      ×
    </button>
  </div>

  <div
    v-if="errorMessage"
    class="login-msg login-msg--error"
  >
    <p>{{ $t(errorMessage) }}</p>
    <button @click="dismissErrorMessage">
      ×
    </button>
  </div>

  <AppInput
    v-model="displayName"
    :input-type="'text'"
    :placeholder="$t('loc_login_display_name')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full pb-1']"
    :show-icon="false"
    :value="''"
  />
  <AppInput
    v-model="email"
    :input-type="'email'"
    :placeholder="$t('loc_login_email')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full mt-8 pb-1']"
    :show-icon="false"
    :value="''"
  />

  <AppInput
    v-model="password"
    :input-type="'password'"
    :placeholder="$t('loc_login_password')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full mt-8 pb-1']"
    :show-icon="false"
    :value="''"
  />
  <AppInput
    v-model="repeatPassword"
    :input-type="'password'"
    :placeholder="$t('loc_login_password_repeat')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full mt-8 pb-1']"
    :show-icon="false"
    :value="''"
  />

  <!-- TODO: proslijediti checked vrijednost -->
  <AppCheckBox
    :value="privacyChecked"
    :class-list="['registration-checkbox']"
    :label="'loc_login_privacy_checkbox'"
    class="modal-checkbox my-6 lg:mt-10 lg:mb-12 text-color-text text-15"
    @onChange="setPrivacyChecked"
  />

  <div class="text-center mt-6 lg:mt-20">
    <button class="btn-blue-border w-full mb-2" @click="confirmModal(LOGIN_TYPE.EMAIL)">
      {{ $t('loc_login_button_register') }}
    </button>

    <span class="font-sans-bold text-15 text-color-text block mt-6 lg:mt-12">
      Already have an account?
      <a href="#" class="link-dark-underline mt-4" @click="changeToLogin()">Login here</a>
    </span>
  </div>
</template>

<script>

import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import {mapGetters} from "vuex";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
import AppCheckBox from '@/libs/ui/lib/forms/checkbox/AppCheckBox.vue'

export default {
  name: "RegistrationForm",
  components: {
    AppInput,
    AppCheckBox
  },
  emits: {
    'close-modal': null,
    'confirm-modal': null
  },
  data() {
    return {
      displayName: '',
      registrationFormErrors: '',
      email: '',
      privacyChecked: false,
      password: '',
      repeatPassword: '',
      LOGIN_TYPE: this.$constants.LOGIN_TYPE
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'LoggedInUserModule/errorMessage',
      infoMessage: 'LoggedInUserModule/infoMessage'
    }),
    modalShown() {
      return this.$store.state.LoggedInUserModule.loginState === LoginStateEnum.REGISTRATION_STARTED
    }
  },
  mounted() {
    this.dismissInfoMessage()
  },
  methods: {
    setPrivacyChecked(val) {
      this.privacyChecked = val
    },
    dismissInfoMessage() {
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
    },
    closeModal() {
      this.$store.commit('LoggedInUserModule/quitRegistration');
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
    changeToLogin() {
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
      this.$store.commit('LoggedInUserModule/quitRegistration');
      this.$store.commit('LoggedInUserModule/startLogin');
    },
    dismissFormErrors() {
      this.registrationFormErrors = "";
    },
    dismissErrorMessage() {
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
    confirmModal(loginType) {
      this.registrationFormErrors = "";
      if (!this.privacyChecked) {
        this.registrationFormErrors = "loc_login_privacy_missing";
        return;
      }
      if (this.password !== this.repeatPassword) {
        this.registrationFormErrors = "loc_login_password_not_match";
        this.$store.commit('LoggedInUserModule/setErrorMessage', '')
      } else {
        this.$store.dispatch('LoggedInUserModule/registerUser', {
          loginType: loginType,
          displayName: this.displayName,
          email: this.email,
          password: this.password
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
