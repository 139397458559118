import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "filter-container container max-w-screen-xl mt-6" }
const _hoisted_2 = { class: "space-y-6 md:space-y-0 md:flex items-end justify-between mb-2 md:mb-8" }
const _hoisted_3 = { class: "filter-input-container" }
const _hoisted_4 = { class: "filter-date-map-container flex flex-col items-end space-y-6" }
const _hoisted_5 = {
  key: 1,
  class: "flex w-full justify-end mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterInput = _resolveComponent("FilterInput")
  const _component_AppSwitch = _resolveComponent("AppSwitch")
  const _component_FilterDate = _resolveComponent("FilterDate")
  const _component_AppTitle = _resolveComponent("AppTitle")
  const _component_FilterDropDown = _resolveComponent("FilterDropDown")
  const _component_FilterCheckBox = _resolveComponent("FilterCheckBox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_ctx.filterDataInput.property !== '')
          ? (_openBlock(), _createBlock(_component_FilterInput, {
              key: 0,
              "filter-data": _ctx.filterDataInput,
              class: "w-full",
              onOnSelectedValues: _ctx.onInputSelect
            }, null, 8, ["filter-data", "onOnSelectedValues"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.hasPropPositionOnMap)
          ? (_openBlock(), _createBlock(_component_AppSwitch, {
              key: 0,
              label: _ctx.$t('loc_show_map'),
              checked: _ctx.checked,
              "class-list": 'switch-filter-map',
              onOnChangeEvent: _ctx.onChangeSwitch
            }, null, 8, ["label", "checked", "onOnChangeEvent"]))
          : _createCommentVNode("", true),
        (_ctx.showDatePicker)
          ? (_openBlock(), _createBlock(_component_FilterDate, {
              key: 1,
              id: "datepicker",
              class: "filter-datepicker-container relative",
              "filter-data": _ctx.datePickerData,
              onOnSelectedValues: _ctx.onDatePickerSelect
            }, null, 8, ["filter-data", "onOnSelectedValues"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_AppTitle, { title: _ctx.title }, null, 8, ["title"]),
    _createVNode(_component_FilterDropDown, {
      "filter-data": _ctx.filterData,
      class: "filter-dropdown",
      "class-list": ['filter-dropdown-container  space-y-2 md:space-y-0'],
      "select-all": true,
      onOnSelectedValues: _ctx.onDropDownSelect
    }, null, 8, ["filter-data", "onOnSelectedValues"]),
    (_ctx.filterCheckboxData.length > 0)
      ? (_openBlock(), _createBlock(_component_FilterCheckBox, {
          key: 0,
          "filter-data": _ctx.filterCheckboxData,
          onOnSelectedValues: _ctx.onCheckBoxSelect
        }, null, 8, ["filter-data", "onOnSelectedValues"]))
      : _createCommentVNode("", true),
    (_ctx.filterOnCLick)
      ? (_openBlock(), _createBlock("div", _hoisted_5, [
          _createVNode("a", {
            class: "btn-red w-full md:w-auto cursor-pointer",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitStoreFilter && _ctx.emitStoreFilter(...args)))
          }, _toDisplayString(_ctx.$t("loc_search")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}