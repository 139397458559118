<template>
  <div
    v-if="moduleInfo.Children.length > 0"
    class="nav-dropdown max-w-screen-lg"
  >
    <div class="nav-dropdown-container max-w-screen-md">
      <template
        v-for="menuItem in moduleInfo.Children.slice(0, 1)"
        :key="menuItem.EntityID"
      >
        <ModuleLink
          v-if="$store.state.AdminMode"
          :module-properties="menuItem"
          :show-only-icon="true"
        />
        <h3
          v-if="menuItem.SefUrl || menuItem.CustomUrl"
          class="nav-dropdown-group-title relative z-1"
        >
          <VirtualLink
            :close-menu-on-click="true"
            :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
            :target="menuItem.Target"
          >
            {{ menuItem.Name }}
          </VirtualLink>
        </h3>
        <h3
          v-else
          class="relative z-1"
        >
          {{ menuItem.Name }}
        </h3>
        <div
          v-if="menuItem.Children.length > 0"
          class="w-full grid gap-3 lg:gap-0 grid-cols-2 lg:flex lg:flex-no-wrap justify-between border-b border-color-lightgrey pb-6"
        >
          <MenuImagesLink
            v-for="menuItemChild in menuItem.Children"
            :key="menuItemChild.EntityID"
            :menu-item="menuItemChild"
            :class-menu="'relative'"
            :show-menu-name="true"
            :media-layout="'lg'"
            :media-type="'menuImage'"
          />
        </div>
      </template>

      <!--<div class="w-full flex items-start justify-between py-6">-->
      <div class="w-full max-w-screen-md grid gap-x-6 gap-y-8 lg:gap-0 grid-cols-2 lg:flex lg:flex-wrap">
        <div
          v-for="menuItem in moduleInfo.Children.slice(1, moduleInfo.Children.length)"
          :key="menuItem.EntityID"
          class="w-full lg:w-1/3 lg:pb-8"
        >
          <!-- Dodana klasa w-1/4 pb-8 (za više od 4 kolone da stavke idu u drugi red) -->
          <NavMenuItemOption :menu-item="menuItem" />
        </div>
      </div>

      <div
        v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
        class="pt-2"
      >
        <VirtualLink
          :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
          class-list="btn-red-border"
          :target="moduleInfo.Target"
          :close-menu-on-click="true"
        >
          {{ $t("loc_explore_more") }}
        </VirtualLink>
      </div>
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";
import { menuMediaMixin } from "@/mixins";
import MenuImagesLink from "@/components/includes/MenuImagesLink";
import {lazyLoad} from "@/components/utils/lazyLoadImages";

export default {
  components: {
    NavMenuItemOption,
    MenuImagesLink,
  },
  mixins: [menuMediaMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  mounted() {
     setTimeout(() => {
        lazyLoad();
      }, 1000)
  },
};
</script>
<style lang="scss" scoped>
button:focus{outline:none;}
</style>
