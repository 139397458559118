import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-auto max-w-screen-xl py-8 md:py-16 md:px-4 lg:px-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormaPersonaChild = _resolveComponent("FormaPersonaChild")

  return (!_ctx.comp)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_FormaPersonaChild, {
          class: "form-personalize-horizontal max-w-screen-xl mx-auto bg-color-secondary px-4 py-8 md:px-5 md:py-6 lg:px-56px lg:py-10",
          "module-info": _ctx.moduleInfo,
          "dropdown-class-list": ['form-personalize-dropdown']
        }, null, 8, ["module-info"])
      ]))
    : _createCommentVNode("", true)
}