import {ModulePicturesInterface} from "@/interfaces";

export const arrayToMap = (array: any[]) => {
    return array.reduce((acc, item) => {
        acc[item.value] = item.label
        return acc
    }, { })
}

export const round = (x: number, digits: number) => {
    return parseFloat(x.toFixed(digits))
}

// PHOTO SIZES ARE RELATED WITH CMS, DO NOT EDIT "JUST LIKE THAT"
// svojstvo MediaTypeItemValue u tipovima medije

export const ModulePictures: ModulePicturesInterface = {
    default: {
        sm: {
            width: 500,
            height: 500
        },
        lg: {
            width: 500,
            height: 500
        }
    },
    cardListSquare: {
        sm: {
            width: 500,
            height: 500
        },
        lg: {
            width: 500,
            height: 500
        }
    },
    cardListPortrait: {
        sm: {
            width: 720,
            height: 980
        },
        lg: {
            width: 720,
            height: 980
        }
    },
    cardListLandscape: {
        sm: {
            width: 720,
            height: 530
        },
        lg: {
            width: 720,
            height: 530
        }
    },
    mosaicImage: {
        sm: {
            width: 600,
            height: 433
        },
        lg: {
            width: 1128,
            height: 814
        }
    },
    heroHome: {
        sm: {
            width: 520,
            height: 670
        },
        lg: {
            width: 1920,
            height: 1330
        }
    },
    heroBig: {
        sm: {
            width: 520,
            height: 670
        },
        lg: {
            width: 1920,
            height: 900
        }
    },
    heroSmall: {
        sm: {
            width: 520,
            height: 670
        },
        lg: {
            width: 1920,
            height: 600
        }
    },
    contentImg: {
        sm: {
            width: 520,
            height: 670
        },
        lg: {
            width: 1160,
            height: 630
        }
    },
    introImg:{
        sm: {
            width: 520,
            height: 520
        },
        lg: {
            width: 520,
            height: 520
        }
    },
    bannerImage:{
        sm: {
            width: 320,
            height: 460
        },
        lg: {
            width: 1440,
            height: 460
        }
    },
    paragraphImage:{
        sm: {
            width: 320,
            height: 360
        },
        lg: {
            width: 860,
            height: 490
        }
    },
    paragraphTwoColumnImage:{
        sm: {
            width: 320,
            height: 460
        },
        lg: {
            width: 1440,
            height: 460
        }
    },
    paragraphImageLeftRight:{
        sm: {
            width: 320,
            height: 360
        },
        lg: {
            width: 960,
            height: 740
        }
    },
    thumbTable:{
        sm: {
            width: 75,
            height: 50
        },
        lg: {
            width: 75,
            height: 50
        }
    },
    mediaGalleryImgs:{
        sm: {
            width: 640,
            height: 520
        },
        lg: {
            width: 1120,
            height: 630
        }
    },
    galleryTextImg:{
        sm: {
            width: 640,
            height: 860
        },
        lg: {
            width: 1200,
            height: 690
        }
    },
    menuImage:{
      sm: {
        width: 170,
        height: 120
      },
      lg: {
          width: 170,
          height: 120
      }
    },
    menuLoginImage:{
        sm: {
          width: 720,
          height: 1080
        },
        lg: {
            width: 720,
            height: 1080
        }
      },
    reviewsImage: {
        sm: {
            width: 320,
            height: 600
        },
        lg: {
            width: 1200,
            height: 800
        }
    }
}
