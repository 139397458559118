<template>
  <div
    v-if="moduleInfo.Children.length > 0 || moduleInfo.SefUrl || moduleInfo.CustomUrl"
    class="inline-block text-left w-full lg:w-auto"
  >
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="moduleInfo"
      :show-only-icon="true"
    />
    <Menu>
      <span class="block lg:inline w-full lg:w-auto">
        <MenuButton class="menu-button relative w-full lg:w-auto">
          <a
            v-if="moduleInfo.Children.length > 0 "
            class="nav-item w-full lg:w-auto outline-none"
            :class="{'link-only' : moduleInfo.Children.length < 1}"
            @click="openMenu"
          >
            {{ moduleInfo.Name }}</a>
          <VirtualLink
            v-else
            :close-menu-on-click="true"
            :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
            :class-list="(moduleInfo.Children.length < 1 ? 'link-only' : '') + ' nav-item w-full lg:w-auto outline-none'"
          >{{ moduleInfo.Name }}
          </VirtualLink>
          <svg class="svg-parallelogram" viewBox="0 0 2000 500" preserveAspectRatio="none"  xmlns="http://www.w3.org/2000/svg">
            <rect x="190" y="0" width="90%" height="100%" transform="skewX(-20)" />
          </svg>
        </MenuButton>
      </span>

      <transition
        v-if="moduleInfo.Children.length > 0"
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform lg:scale-95 opacity-0"
        enter-to-class="transform lg:scale-100 opacity-100"
        leave-active-class="transition duration-300 ease-out"
        leave-from-class="transform lg:scale-100 opacity-100"
        leave-to-class="transform lg:scale-95 opacity-0"
      >
        <MenuItems
          class="nav-dropdown-container nav-dropdown-container-small relative lg:absolute z-20  origin-center bg-white lg:border lg:border-gray-200 lg:divide-y lg:divide-gray-100 lg:shadow-lg outline-none"
        >
          <MenuItem
            ref="close"
            v-slot="{ active }"
          >
            <a
              :class="
                active ? 'text-color-primary' : 'text-color-darkgrey'
              "
              class="close-nav-x hidden lg:block text-60 top-0 right-0 absolute z-10 mr-5 -mt-5 cursor-pointer transition-all duration-300"
            >
              ×
            </a>
          </MenuItem>
          <NavDropdownWithImagesAbove
            v-if="moduleInfo.MenuItemType === 'WITH_IMAGES_ABOVE'"
            :module-info="moduleInfo"
          />
          <NavDropdownWithMap
            v-else-if="moduleInfo.MenuItemType === 'WITH_MAP'"
            :module-info="moduleInfo"
          />
          <NavDropdownNormal
            v-else-if="moduleInfo.Children.length"
            :module-info="moduleInfo"
          />
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import NavDropdownWithMap from "@/components/includes/dropdowns/NavDropdownWithMap";
import NavDropdownWithImagesAbove from "@/components/includes/dropdowns/NavDropdownWithImagesAbove";
import NavDropdownNormal from "@/components/includes/dropdowns/NavDropdownNormal";
import {lazyLoad} from "@/components/utils/lazyLoadImages";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    NavDropdownWithMap,
    NavDropdownWithImagesAbove,
    NavDropdownNormal,
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    closeMenuState() {
      return this.$store.state.closeMenu
    }
  },
  watch: {
    closeMenuState() {
      this.closeMenu()
    }
  },
  methods: {
    openMenu() {
      setTimeout(() => {
        lazyLoad();
      }, 1500)

    },
    closeMenu() {
      if(this.$refs.close) {
        this.$refs.close.$el.click()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
