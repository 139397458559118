<template>
  <div 
    ref="shareBtnContainer"
    class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-16 share-btn-container"
    :class="moduleClass"
    @click="showTooltip"
  >
    <div
      v-if="tooltipContent"
      v-tooltip.bottom-start="{
        content: tooltipContent,
        trigger: 'manual',
        show: isOpen,
        classes: 'tooltip-content tooltip-social',
      }"
      
      class="flex items-center cursor-pointer relative"
    >
      <!-- <i class="icon icon-share-ico text-color-primary text-32 pr-3" /> -->
      <i class="fas fa-share-alt text-color-primary text-22 pr-3" />
      <span class="uppercase text-color-darkgrey font-sans-bold hover:text-color-primary text-13"> 
        {{ $t("loc_share") }}
      </span>
    </div>
  </div>
</template>
<script>

import "@/css/modules/_tooltip.scss";

export default {
  name: "ShareBtn",
  components: {
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
     moduleLocation: {
      type: Object,
      default: () => ({}),
    },
    moduleClass: {
      type: String,
      default: () => ('text-color-primary'),
    },
  },
  data() {
    return {
      isOpen: false,
      //TODO: zamjeniti ikonice za whatsapp, viber, email, telegram ako se naprave
      tooltipContent: this.showTooltipContent() ?
      [
        `
        <ul class="ul-tooltip space-x-3 ` + this.moduleLocation + `">
            ${this.getSocialServices().includes("facebook") ? `<li><a class="social"><iframe src="https://www.facebook.com/plugins/share_button.php?href=` + encodeURIComponent("https://www.google.com") + `%2Fdocs%2Fplugins%2F&layout=button&size=large&width=40&height=40&appId" width="40" height="40" style="border:none;overflow:hidden; position:absolute; opacity: 1; z-index: 10; top: 16px; opacity: 0;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe><i class="icon icon-facebook absolute z-0"></i></a></li>` : ""}           
            ${this.getSocialServices().includes("messenger") ? `<li><a class="social" target="_blank" href="https://m.me/croatia.hr"><img src="/dist/img/messenger.svg" class="max-w-6 h-auto"/></a></li>` : ""}           
            ${this.getSocialServices().includes("whatsapp") ? `<li><a class="social" target="_blank" href="https://api.whatsapp.com/send?text=%20` + encodeURIComponent(window.location.href) +`"><i class="icon icon-whatsapp text-24"></i></a></li>` : ""}
            ${this.getSocialServices().includes("viber") ? `<li><a class="social" target="_blank" href="viber://forward?text=&quot;` + encodeURIComponent(window.location.href) + `"><img src="/dist/img/viber.svg" class="max-w-6 h-auto"/></a></li>` : ""}
            ${this.getSocialServices().includes("twitter") ? `<li><a class="social" target="_blank" href="https://twitter.com/intent/tweet?url=` + window.location.href + `&text=Share%20button'"><i class="icon icon-twitter"></i></a></li>` : ""}
            ${this.getSocialServices().includes("linkedin") ? `<li><a class="social" target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=` + window.location.href + `"><i class="icon icon-linked-in"></i></a></li>` : ""}
            ${this.getSocialServices().includes("email") ? `<li><a class="social" target="_blank" href="mailto:?subject=Google&amp;body=` + encodeURIComponent(window.location.href) + `"><i class="icon icon-mail2 text-24"></i></a></li>` : ""}
            ${this.getSocialServices().includes("telegram") ? `<li><a class="social" target="_blank" href="https://telegram.me/share/url?url=` + encodeURIComponent(window.location.href) + `"><i class="icon icon-telegram text-24"></i></a></li>` : ""}
            ${this.getSocialServices().includes("pinterest") ? `<li><a class="social" target="_blank" href="https://pinterest.com/pin/create/button/?url=` + this.getImagetoShare() + `&media=&description=Share%20button"><i class="icon icon-pinterest"></i></a></li>` : ""}
        </ul>`
      ] : [],
      //SHARE FB in new tab: ${this.getSocialServices().includes("facebook") ? `<li><a class="social" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=` + encodeURIComponent(window.location.href) + `"><i class="icon icon-facebook"></i></a></li>` : ""}
    };
  },
  mounted() {
    window.addEventListener('click', this.handleCloseTooltip);
  },
  unmounted() {
    window.removeEventListener('click', this.handleCloseTooltip, 100);
  },
  methods: {
    getSocialServices(){
      let socialShareServices = this.$store.state.Params.find(o => o.Key === 'SocialShareServices')?.Value.replace(/\s/g, '');
      var socialShareServicesArray = socialShareServices?.split(',');
      return socialShareServicesArray;
    },
    getImagetoShare(){
      let heroCont = document.getElementsByClassName("hero-container");
      if(heroCont){
        let picture = document.getElementsByTagName('source')[0]?.getAttribute("data-class");
        if(picture === "hero-img-small" || picture == "hero-img-big"){
          return document.getElementsByTagName('source')[0].getAttribute("srcset"); 
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },
    showTooltipContent(){
        if(this.moduleLocation === "loc-footer" && this.getSocialServices()){
            return true;
        }
        else if(this.moduleLocation === "loc-title" && this.moduleInfo.Module.ModuleProperties.ModuleHideShareButton !== 'True' && this.getSocialServices()){
            return true;
        }
        else return false;
    },
    showTooltip() {
        this.isOpen = true;
    },
    handleCloseTooltip(e){
      if(this.isOpen){
        let tooltip = document.querySelectorAll(".tooltip.tooltip-content.tooltip-social")[0];
        let tooltip2 = document.querySelectorAll(".tooltip.tooltip-content.tooltip-social")[1];
        let tooltipBtn = document.querySelectorAll(".share-btn-container")[0];
        let tooltipBtn2 = document.querySelectorAll(".share-btn-container")[1];

        if(tooltip){
          var isClickInside = tooltip.contains(e.target) || tooltip2?.contains(e.target);
          var isClickTooltipBtn = tooltipBtn.contains(e.target) || tooltipBtn2?.contains(e.target);

          if ((isClickInside || isClickTooltipBtn)) {
            this.isOpen = true;
          }
          else if (!isClickInside && !isClickTooltipBtn) {
            this.isOpen = false;
          }
        }
      }
    }
   
  },

};
</script>