<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="dataLoaded"
      class="cards-container cards-landscape cards-dropdown my-16"
    >
      <div
        v-if="hasPropertyValue('ModuleTitle', moduleInfo.Module.ModuleProperties, moduleData) ||
          hasPropertyValue('ModuleIntroTitle',moduleInfo.Module.ModuleProperties,moduleData)"
        class="container max-w-screen-xl mx-auto"
      >
        <h2 class="card-headline-2 my-8 pl-0">
          <span
            v-if="
              hasPropertyValue(
                'ModuleIntroTitle',
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            "
            class="inline-block"
          >
            {{
              getPropertyValue(  $t,
                "ModuleIntroTitle",
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            }}
          </span>
          <span class="inline-block big">
            <!--            <SelectItemTitle
              :dropdown-data="
                getPropertyValue(  $t,
                  'ModuleDropdownTitle',
                  moduleInfo.Module.ModuleProperties,
                  moduleData
                )
              "
            />-->
            <SelectItemTitle
              v-if="dropDownList.length > 0"
              :dropdown-data="dropDownList"
              :select-item="selectedItem"
              @selected="changeSelected"
            />
          </span>

          <span
            v-if="
              hasPropertyValue(
                'ModuleTitle',
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            "
            class="inline-block"
          >
            {{
              getPropertyValue(  $t,
                "ModuleTitle",
                moduleInfo.Module.ModuleProperties,
                moduleData
              )
            }}
          </span>
        </h2>
      </div>
      <!-- SWIPER -->
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True' && dataToShow.length > minNumbOfDataToBeSlider"
        class="swiper-card-wrapper max-w-screen-3xl"
      >
        <div class="swiper-card-container container 3xl:px-0 lg:max-w-screen-xl xl:max-w-screen-2xl lg:ml-0 3xl:ml-auto 4xl:max-w-screen-xl relative">
          <swiper
            v-if="dataToShow.length > 0"
            :breakpoints="swiperPortraitOptions.breakpoints"
            :loop="swiperPortraitOptions.loop"
            :loop-slides="swiperPortraitOptions.loopedSlides"
            :centered-slides="swiperPortraitOptions.centeredSlides"
            :space-between="swiperPortraitOptions.spaceBetween"
            :watch-slides-visibility="swiperPortraitOptions.watchSlidesVisibility"
            :navigation="swiperPortraitOptions.navigation"
            :initial-slide="swiperPortraitOptions.initialSlide"
            :pagination="{ clickable: true, dynamicBullets: true }"
            :auto-height="swiperPortraitOptions.autoHeight"
            :speed="800"
            :autoplay="{
              'delay': swiperDelay,
              'disableOnInteraction': false
            }"

            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <template
              v-for="card in dataToShow"
              :key="card?.EntityID"
            >
              <!--               <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
              <swiper-slide
                v-if="card"
                :style="{marginRight: swiperPortraitOptions.spaceBetween + 'px'}"
                class="card inline-flex flex-col py-8"
              >
                <card-item
                  :entity-id="moduleInfo.Module.EntityID"
                  :module-data="card"
                  :module-properties="moduleInfo.Module.ModuleProperties"
                  module-type="cardLandscape"
                />
              </swiper-slide>
            </template>

            <div class="swiper-button swiper-button-next dropdown-arrow-next" />
          </swiper>
          <div class="swiper-button swiper-button-prev dropdown-arrow-prev" />
        </div>
      </div>
      <!-- NO SWIPER -->
      <div
        v-else
        class="container max-w-screen-xl mx-auto"
      >
        <div
          class="card-grid-container grid gap-3 lg:gap-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-8"
          :class="{'card-flex-container': dataToShow.length < 3}"
        >
          <template
            v-for="card in dataToShow"
            :key="card?.EntityID"
          >
            <!--             <template v-if="hasPropertyValue('EndDate', moduleProperties, card) && !checkIfDateIsValid(card.EndDate)" />
 -->
            <div
              v-if="card"
              class="card card-dropdown inline-flex flex-col"
            >
              <card-item
                class="card-content-div"
                :entity-id="moduleInfo.EntityID"
                :module-data="card"
                :module-properties="moduleInfo.Module.ModuleProperties"
                module-type="cardLandscape"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleLinkName"
        class="container max-w-screen-xl mx-auto text-center md:text-right"
      >
        <VirtualLink
          :href="moduleInfo.Module.ModuleProperties.ModuleLinkUrl"
          class-list="btn-small inline-flex t mt-2"
        >
          {{ tt(moduleInfo.Module.ModuleProperties.ModuleLinkName )}}
        </VirtualLink>
      </div>

      <div class="module-border container mx-auto mt-16 mb-4" />
    </div>
  </div>
</template>

<script>
import CardItem from "@/components/includes/CardItem";
import SelectItemTitle from "@/components/includes/selects/SelectItemTitle.vue";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperPortraitOptions } from "@/services/swiperOptions";
//import {dateFromString} from "@/services/date-utilis";
import {lazyLoad} from "@/components/utils/lazyLoadImages";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {selfDataRequestMixin} from "@/mixins";
import axiosInstance from "@/services/http-common";
import {newPrepareImage} from "@/components/utils/prepareImages";
import {getRequestProps} from "@/store/store.utils";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);


export default {
  name: "Dropdown",
  components: {
    CardItem,
    Swiper,
    SwiperSlide,
    SelectItemTitle,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiperPortraitOptions: {...swiperPortraitOptions},
      swiper: {},
      swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
      entityNameForDropdown: 'eVisitorCountry',
      dataToShow: [],
      dataLoaded: false,
      selectedItem: null,
      entityNameFormDropDown: '',
      minNumbOfDataToBeSlider: 4,
      galleryType: 'cardListLandscape',
      dropdownInitialized: false,
    };
  },
  computed: {
    entitiesStoreList() {
      return this.$store.state.entitiesStore[this.entityNameFormDropDown]
    },
    dataList() {
      return []
    },
    dropDownList() {
      if (this.entitiesStoreList) {
        return this.entitiesStoreList?.ids?.map(id => this.entitiesStoreList.entities[id]) ?? []
      } else {
        return null
      }
    },
    dropDownListNames() {
      if (this.entitiesStoreList) {
        const list = Object.values(this.entitiesStoreList.entities)
        return list.length ? list.map(o => o.Title).join(',') : ''
      } else {
        return null
      }
    },
    entityTypes() {
      return this.$store.state.entityTypes
    },
    selectedPaceIds() {
      return this.selectedItem ? this.selectedItem.Place.map(o => o.EntityID) : []
    },
    userCountry() {
      return this.$store.state.LoggedInUserModule.userCountry
    }
  },
  watch: {
    entityTypes() {
      this.initDropDown()
    },
    userCountry() {
      if (this.dropDownList.length > 0) {
        this.selectedItem = this.dropDownList.find(o => o.EntityID === +this.userCountry)
      }
    },
    dropDownList(val) {
      if (val?.length > 0) {
        if (this.userCountry) {
          this.selectedItem = val.find(o => o.EntityID.toString().toLowerCase() === this.userCountry)
        }else if (this.getCookie('userFromCountry')) {
          this.selectedItem = val.find(o => o.Title.toLowerCase() === this.getCookie('userFromCountry').toLowerCase()) ?? val[0]
        } else {
          this.selectedItem = val[0]
        }
      }
    },
    selectedItem(val) {
      if (val) {
        axiosInstance.post('/api/v1/Place',
          {
            ...getRequestProps(this.moduleInfo),
            Filters: {
              OR: [
                val.Place?.map(o => ({EntityID: {is: o.EntityID.toString()}}))
              ]
            }
          },
        ).then(res => {
          const temp = [];
          res.data.Data.forEach(o => {
            temp[this.selectedPaceIds.indexOf(o.EntityID)] = {...o,  MediaGallery: newPrepareImage(o.Media)}
          })
          this.dataToShow = temp;
          this.dataLoaded = true;

          setTimeout(() => {
            lazyLoad()
          }, 150);
        })
      }
    }

  },
  mounted() {
    this.windowWidth();
    this.initDropDown()
     setTimeout(() => {
        lazyLoad();
      }, 1500)

    window.addEventListener("resize", this.windowWidth);
  },
  unmounted() {
    window.removeEventListener("resize",this.windowWidth)
  },
  methods: {
    changeSelected(val) {
      setTimeout(() => {
        lazyLoad();
      }, 1500)
      this.selectedItem = val
    },
    onSwiper(swiper) {
      this.swiper = swiper;
      this.fadeSlide(swiper);
    },
    initDropDown() {
      if (this.entityTypes.length && this.entityTypes.some(o => o.EntityName === this.entityNameForDropdown)) {
        this.entityNameFormDropDown = this.entityTypes.find(o => o.EntityName === this.entityNameForDropdown).EntityName
        if (!this.dropdownInitialized && this.entityNameForDropdown) {
          this.dropdownInitialized = true
          this.$store.dispatch('initDropDownStore', {entityName: this.entityNameFormDropDown, fields: ['Title'], relatedFields: {Place: 'Place.Title'}})
        }
      }
    },
    onSlideChange(swiper) {
      this.fadeSlide(swiper);
    },
     windowWidth() {
      if (window.innerWidth >= 1440) {
        this.minNumbOfDataToBeSlider = 3;
      } else if (window.innerWidth >= 992) {
        this.minNumbOfDataToBeSlider = 3;
      } else if (window.innerWidth >= 580) {
        this.minNumbOfDataToBeSlider = 2;
      } else { this.minNumbOfDataToBeSlider = 1; }
    },
    fadeSlide(swiperRef) {
      setTimeout(() => {
        const lastVisibleSlide = swiperRef.visibleSlides.slice(-1)[0];
        if (lastVisibleSlide) {
          swiperRef.slides.map((slide) =>
            slide.classList.remove("faded-slide")
          );
          lastVisibleSlide.classList.add("faded-slide");
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.swiper-button-prev {
  opacity: 0;
}

.swiper-card-container:hover {
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1;
  }
}

.swiper-button-next.dropdown-arrow-next,
.swiper-button-prev.dropdown-arrow-prev {
  @apply text-color-primary;
  opacity: 0;
  transition: all 0.3s;
  padding: 80px 40px;
  top: 80px;
  @screen lg{
    top: 100px;
  }
  &:after {
    @apply text-5xl;
  }
  @screen 2xl {
    right: 11%;
  }
  &:hover {
    opacity: 1;

    @screen 2xl {
      right: 10.5%;
    }
  }
}
.cards-dropdown{
  .swiper-pagination-bullets-dynamic{
    bottom: 0;
    @screen md{
      @apply hidden;
    }
      .swiper-pagination-bullet{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #fff;
          transform: scale(1);
      }
      .swiper-pagination-bullet-active-main{
          width: 11px;
          height: 11px;
          margin: 0 9px;
          border: solid 2px #9a9a9a;
          background-color: #9a9a9a;
      }
  }
  .swiper-button-prev.dropdown-arrow-prev{
    @screen 2xl{
      left: -65px;
    }
  }
}
</style>
