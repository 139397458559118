

import {Options, Vue} from "vue-class-component";
import AppTitle from "@/libs/ui/lib/headings/AppTitle.vue"
import FilterCheckBox from "@/libs/features/lib/FilterCheckBox/FilterCheckBox.vue"
import FilterInput from "@/libs/features/lib/FilterInput/FilterInput.vue"
import AppSwitch from "@/libs/ui/lib/forms/switch/AppSwitch.vue"
import FilterDropDown from "@/libs/features/lib/FilterDropDown/FilterDropDown.vue"
import FilterDate from "@/libs/features/lib/FilterDate/FilterDate.vue"
import {Emit, Prop, Watch} from "vue-property-decorator";
import {FilterDataDropDownInput, FilterDataInputInput, FilterDatePickerData} from "@/libs/features/.storybook/interfaces.ts";
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {FilterState, ModuleFilter, ModuleInfo} from "@/interfaces";
import axiosInstance from "@/services/http-common";
import CONSTANTS from '@/components/shared/constants';
import {format} from 'date-fns';
import {dateFromString} from "@/services/date-utilis";
import FILTER from '@/components/filters/filter';
import {LocationQueryValue} from "vue-router";

@Options({
  components: {
    FilterCheckBox,
    FilterDropDown,
    FilterDate,
    AppTitle,
    AppSwitch,
    FilterInput,
    Map
  },
  emits: ['on-filter-change', 'on-show-map']
})
export default class Filter extends Vue{

  @Prop({default: ''})
  title: string = '';

  @Prop({default: () => []})
  filters: any[] = [];

  @Prop({default: () => []})
  moduleFilter: ModuleFilter[] = [];

  @Prop({required: true})
  moduleInfo!: ModuleInfo;

  @Prop({default: false})
  hasPropPositionOnMap: boolean = false;

  @Prop({default: () => true})
  filterOnCLick = true;
  checked = false;
  filterOnChange = true;
  dateFilteredData: SelectItemEmit[] = [];
  inputFilteredData: SelectItemEmit[] = [];
  checkBoxFilteredData: SelectItemEmit[] = [];
  dropDownSelectFilteredData: SelectItemEmit[] = [];
  filterDataInput: FilterDataInputInput = {data: '', property: '', type: 'input', emptyValue: ''};
  filterData: FilterDataDropDownInput[] = [];
  filterCheckboxData: FilterDataDropDownInput[] = [];
  datePickerData: FilterDatePickerData = { data: [], property: '', emptyValue: '', type: 'date'};
  showDatePicker: boolean = false;

  @Emit()
  onFilterChange(val: SelectItemEmit[]) {}

  @Emit()
  onShowMap(val: boolean) {}

  @Watch('moduleFilter', {deep: true, immediate: true})
  onModuleFilterChange(val: ModuleFilter[] = []) {
    const queryProp = this.$route.query;
    this.filterData = [];
/*    try {
      val.filter(o => o.FilterType === 'dropdown')
    } catch (e) {
      console.log({val});
    }*/
    val.filter(o => o.FilterType === 'dropdown').forEach((mf, i) => this.setFilterDropDownEntity(queryProp, i, mf));
    val.filter(o => o.FilterType === 'checkboxlist').forEach((mf, i) => this.setFilterCheckBoxEntity(queryProp, i, mf));
    this.filterDataInput.property = val.find(o => o.FilterType === 'input' )?.FilterField || '';
    this.filterDataInput.selectedValue = queryProp?.[this.filterDataInput.property] ? {value: (queryProp?.[this.filterDataInput.property] as string)} : undefined
    this.setFilterDate(queryProp, val.find(o => o.FilterType === 'date'))
  }
  $constants: any;

    // TODO remove after complete implementation

  setFilterCheckBoxEntity(queryProp: Record<string, string | LocationQueryValue[] | null>,i: number, mf: ModuleFilter) {
    if(mf.LookupSourceList.length > 0) {
      this.setFilterCustomList(queryProp, mf, i)
    } else {
      const fields = [mf.LookupSourceValue];
      if (fields.length >= 1 && mf.LookupSourceTypeID) {
        axiosInstance
          .post<{Data:{[key: string]: string}[]}>(
            `/api/v1/${mf.LookupSource}`, {
              Fields: [mf.LookupSourceValue],
              Paging: {Offset: 0, Limit: -1}
            })
          .then(({data: {Data}}) => {
            if (mf.FilterType == 'checkboxlist') {
              this.filterCheckboxData[(i)] = {
                data: Data.map(o => ({
                  EntityID: o['EntityID'],
                  Caption: o[mf.LookupSourceValue]
                })),
                emptyValue: mf.FilterFieldLngExp,
                property: mf.FilterField,
                type: mf.FilterType,
                selectedValue: queryProp?.[mf.FilterField] ? {
                  name: mf.FilterField,
                  property: mf.FilterField,
                  value: +(queryProp[mf.FilterField] as string)
                } : undefined,
              };
            }
          }).catch((e) => {
          console.error(e)
        });
      }
    }
  }

  setFilterDropDownEntity(queryProp:  Record<string, string | LocationQueryValue[] | null>,i: number, mf: ModuleFilter) {
    // console.log({queryProp});
    if(mf.LookupSourceList.length > 0) {
      this.setFilterCustomList(queryProp, mf, i)
    } else {
      const fields = [mf.LookupSourceValue];
      if (fields.length >= 1 && mf.LookupSource) {
        axiosInstance
          .post<{Data:{[key: string]: string}[]}>(
            `/api/v1/${mf.LookupSource}`, {
              Fields: [mf.LookupSourceValue],
              Paging: {Offset: 0, Limit: -1}
            })
          .then(({data: {Data}}) => {
            if (mf.FilterType == 'dropdown') {
              this.filterData[(i)] = {
                data: Data.map(o => ({
                  EntityID: o['EntityID'],
                  Caption: o[mf.LookupSourceValue]
                })),
                emptyValue: mf.FilterFieldLngExp,
                property: mf.FilterField,
                type: mf.FilterType,
                selectedValue: queryProp?.[mf.FilterField] ? {
                  name: mf.FilterField,
                  property: mf.FilterField,
                  value: +(queryProp[mf.FilterField] as string)
                } : undefined,
              };
            }
          }).catch((e) => {
          console.error(e)
        });
      }
    }
  }
  setFilterCustomList( queryProp: Record<string, string | LocationQueryValue[] | null>, mf: ModuleFilter, i: number) {
    let listToUse: FilterDataDropDownInput
    try {
      listToUse = {
        data: (
          Object.entries(JSON.parse(mf.LookupSourceList) as unknown as { [key: string]: string })
        ).map(([k, v]) => ({Caption: k, EntityID: v})),
        property: mf.FilterField,
        emptyValue: mf.FilterFieldLngExp,
        type: 'dropdown',
        selectedValue: queryProp?.[mf.FilterField] ? {
          name: mf.FilterField,
          value: +(queryProp[mf.FilterField] as string)
        } : undefined,
      }
    } catch (e) {
      listToUse = {
        data: [],
        property: mf.FilterField,
        emptyValue: mf.FilterFieldLngExp,
        type: 'dropdown',
        selectedValue: queryProp?.[mf.FilterField] ? {
          name: mf.FilterField,
          value: +(queryProp[mf.FilterField] as string)
        } : undefined,
      }
    }
    this.filterData[(i)] = listToUse
  }
  setFilterDate( queryProp: Record<string, string | LocationQueryValue[] | null>, mf?: ModuleFilter) {
    if (mf) {
      this.showDatePicker = true;
      this.datePickerData = {
        data: [],
        emptyValue: this.$t(mf.FilterFieldLngExp),
        property: mf.FilterField,
        type: mf.FilterType,
        selectedValueStart: {
          name: 'StartDate',
          value: queryProp?.['startdate'] ? FILTER.getDateFromString(queryProp['startdate']) : ''
        },
        selectedValueEnd: {
          name: 'EndDate',
          value:  queryProp?.['enddate'] ? FILTER.getDateFromString(queryProp['enddate']) : ''
        },
      };
    }

  }
  onChangeSwitch(val: boolean) {
    this.onShowMap(val);
  }
  onInputSelect(val: SelectItemEmit) {
    this.inputFilteredData = [val]
  }
  onDropDownSelect(val: SelectItemEmit[]) {
    this.dropDownSelectFilteredData = val;
    if (!this.filterOnCLick){
      this.emitStoreFilter();
    }
  }
  onCheckBoxSelect(val: SelectItemEmit[]) {
    this.checkBoxFilteredData = val;
    if (!this.filterOnCLick){
      this.emitStoreFilter();
    }
  }
  onDatePickerSelect(val: SelectItemEmit[]) {
    //this.dateFilteredData = val
    this.dateFilteredData = [
      ...val.map((obj) => ({
        ...obj, value: obj.value != null ? format(obj.value as Date, CONSTANTS.DATES.Format) : ''
      })),
    ]
    //this.$store.state[this.moduleInfo.Module.EntityID].DatePickerDate = val.value as Date;
  }
  emitStoreFilter() {
    this.onShowMap(false);
    this.checked = false;
    this.onFilterChange([
      ...this.checkBoxFilteredData,
      ...this.dropDownSelectFilteredData,
      ...this.inputFilteredData,
      ...this.dateFilteredData,
    ])
  }
}
