<template>
  <div
    :class="moduleInfo.Module.CssClass"
    class="hero-container max-w-screen-3xl 3xl:max-w-full mx-auto relative"
  >
    <div
      v-if="loaded"
      class="relative hero-70h"
    >
      <span 
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasImageAsTitle === 'True'"
        class="hero-small-title-icon absolute vh-center"
      >
        <img 
          :src="moduleInfo.Module.ModuleProperties.ModuleImageAsTitle" 
          class="w-auto h-auto block mx-auto max-h-300"
        >
      </span>
      <vue-picture
        v-if="firstModuleDataItem?.MediaGallery.length"
        :image="firstModuleDataItem.MediaGallery[0]"
        :lazy-img-class="'no-lazy-image'"
        :priority-level="'high'"
        css-class="hero-img-small"
      />
    </div>
  </div>
</template>

<script>
import VuePicture from "@/components/includes/VuePicture";
import {selfDataRequestMixin} from "@/mixins";

export default {
  name: "HeaderSmall",
  components: {
    VuePicture,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      galleryType: "heroSmall",
    };
  },

};
</script>