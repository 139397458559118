import {Vue} from "vue-class-component";
import {loadLocaleMessages} from "@/i18n";
import axiosInstance from "@/services/http-common.js";
import {AppWindow} from "@/interfaces/app.interfaces";
import {ModuleInterface, PageDataInterface} from "@/interfaces";
import { lazyLoad } from "@/components/utils/lazyLoadImages";


export class I18nMixin extends Vue {
  emptyPageData: PageDataInterface = {
    PageId: 0,
    ParentId: 0,
    LanguageId: 'hr',
    ModulePreviewMode: true,
    AdminMode: false,
    ModuleData: [],
    IsProduction: false
  }
  winData = (window as AppWindow);
  firebaseInit = false

  changeLocale(lang: string = this.$store.state.LanguageId) {
    this.$store.state.LanguageId = lang;
    this.$i18n.locale = lang;
    loadLocaleMessages()
  }
  lazyLoad(){
    lazyLoad();
  }
  loadCurrentPageLanguages() {
    this.$store.state.languageList = [];
    axiosInstance
      .post("/api/seo/currenturl", {
        //CurrentLanguageID: this.$store.state.PageData.LanguageId,
        PageID: this.$store.state.PageData.PageId,
      })
      .then((response) => {
        this.$store.state.languageList = response.data;
      })
      .catch((e) => {
        console.error(e)
      });
  }
  prepareModuleProperties(module: ModuleInterface):
    {mp: {[key: string]: string}, fields: string[], relatedFields: {[key: string]: string[]}} {
    let includes = false
    let fields: string[] = []
    let relatedFields: {[key: string]: string[]} = {}
    const mp: {[key: string]: string} = {}
    //TODO resolve after module properties are implemented
    const moduleProperties = module.ModuleVariation.Title.includes('Layout') ? [] : module.ModuleProperties;
    (moduleProperties || []).forEach((o: { PropertyName: string, PropertyValue: string }) => {

        const testRegexDollar = /(\$\(.*?\))/g
        const testRegexRelated = /RelatedTypeID/g
        const testTempDollar = o.PropertyValue.match(testRegexDollar) ?? []
        const testTempRelated = o.PropertyValue.match(testRegexRelated) ?? []
        if (testTempDollar?.length) {
          includes = true
          fields.push(...testTempDollar.map(o => o.replace(/\$\(/g, '').replace(/\)/g, '')))
        }
        if (testTempRelated?.length) {
          includes = true
          let jsonTemp: {PropertyName: string,RelatedTypeID : number, RelatedTypeName: string }[] | {PropertyName: string,RelatedTypeID : number, RelatedTypeName: string } = []
          try {
            jsonTemp = JSON.parse(o.PropertyValue)
          } catch (e) {
            jsonTemp = []
          }
          const dataToUse = Array.isArray(jsonTemp) && jsonTemp ? jsonTemp : [jsonTemp]
          dataToUse.forEach(oo => {
            if (oo.RelatedTypeID > -1) {
              const source = module.SourceRelatedFields.find(o => o.RelatedEntityTypeID === oo.RelatedTypeID)
              if (source) {
                oo.RelatedTypeName = source.RelatedEntityTypeName
                relatedFields[oo.RelatedTypeName] = ['Title']
              }
            } else {
              fields.push(oo.PropertyName)
            }
          })
          o.PropertyValue = JSON.stringify(jsonTemp)
        }
        mp[o.PropertyName] = o.PropertyValue
    })

    return {mp, fields, relatedFields}
  }

  setupModule(module: ModuleInterface): ModuleInterface {
    const temp = this.prepareModuleProperties(module)
    module.ModuleProperties = temp.mp
    module.ModuleDynamic = {
      RelatedFields: temp.relatedFields,
      Fields: temp.fields
    }
    if (module.ChildModules) {
      module.ChildModules = module.ChildModules.map(o => this.setupModule(o))
    }
    return module
  }

  setupPageData(data?: PageDataInterface) {
    const pageData = data || this.winData.PageData || this.emptyPageData //(this.PageData2 as unknown as PageDataInterface) ||

    this.$store.state.CdnApiBaseUrl = this.winData.CdnSettings.url || '';
    this.$store.state.CdnApiKey = this.winData.CdnSettings.cache || '';
    this.$store.state.AltCdnApiKey = this.winData.CdnSettings.nocache || '';
    console.time('setupPageData')

    this.$store.state.PageData = {
      ...pageData,
      ModuleData: pageData.ModuleData.map(o => ({Module: this.setupModule(o.Module)})),
    };
    console.timeEnd('setupPageData')

    // this.$store.state.PageData.ModuleData = pageData.ModuleData; //TODO dupli
    this.$store.state.LanguageId = pageData.LanguageId;
  }

}

