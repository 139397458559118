import {createStore, Store} from "vuex";
import {InjectionKey} from "@vue/composition-api";
import axiosInstance from "@/services/http-common.js";
import {
  ComponentState,
  AppState,
  AppWindow,
  PageDataInterface,
  AppParams,
  Seo,
  EntityDefaultParent,
  QueryParamsModule,
  QueryParamsProp,
  Tag,
  EntityType,
  SeoInterface,
  EntityStore,
  RelatedInterface,
  Log,
  ApiResponsePagination,
  ApiResponse,
  ApiResponseNormal

} from "@/interfaces";
import {ModuleInfo} from "../interfaces/page-data.interface";

import {MenuArray, sortInArray} from "../services/navigation";
export const key: InjectionKey<Store<AppState>> = Symbol()
//import {getModuleData, prepareRequestBody} from "@/services/api.requests.service";
import {prepareWhereStatement} from "@/components/utils/query-params";
import {FilterType, SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {
  checkFavoriteIntegrity,
  findEntityName, getLookupSourceFromField, getMediaTypes, getModuleFilters,
  getModulePaging,
  getRelatedFields,
  getRequestFields, getRequestProps, moduleIncludeQuery, setPublicationPlace
} from "@/store/store.utils";
import {getModuleDataKey} from "@/store/store.utils";
import {regionActions, regionMutations} from "@/store/features/Region/region-store";
import {dropDownActions, dropdownMutations} from "@/store/features/DropDown/dropdown.store";
//import {addTagToDb, clearTags} from "@/services/indexedDb";
import { LoggedInUserModule } from './modules/loggedInUserModule';
import {reviewActions, reviewGetters, reviewMutations} from "@/store/features/review/review.store";
//@ts-ignore
import https from "https";
import {query} from "express";
import {LocationQueryValue} from "vue-router";

const emptyPageData: PageDataInterface = {
  PageId: 0,
  ParentId: 0,
  LanguageId: 'hr',
  ModulePreviewMode: true,
  AdminMode: false,
  ModuleData: [],
  IsProduction: false
}
const store = createStore<AppState>({
  state() {
    return {
      AdminMode:false,
      CdnApiBaseUrl: '',
      CdnApiKey: '',
      AltCdnApiKey: '',
      EntityDefaultParent: [],
      HpHyperLink: null,
      LanguageId: 'hr',
      LoadedLocale: false,
      Menu: [],
      PageData: emptyPageData,
      Params: [],
      PagePerfomance: {
        Load: 'calculating...',
        Requests: 0
      },
      closeMenu: 0,
      moduleDataList: {},
      persone: [],
      favorites: [],
      entityTypes: [
        {
          "EntityTypeID": 1,
          "EntityName": "Translation",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 2,
          "EntityName": "Media",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 3,
          "EntityName": "MultimediaGallery",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 10,
          "EntityName": "EmailForm",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 46,
          "EntityName": "Module",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1056,
          "EntityName": "Layout",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1057,
          "EntityName": "MediaType",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1064,
          "EntityName": "Page",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1065,
          "EntityName": "StaticContent",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1101,
          "EntityName": "PublicationPlace",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1110,
          "EntityName": "Menu",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 1111,
          "EntityName": "MenuItem",
          "EntityNamespaceGUI": "Infosit.Cms.Core.Bo.Bo{0},Infosit.Cms.Core.Bo"
        },
        {
          "EntityTypeID": 11,
          "EntityName": "Region",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 27,
          "EntityName": "Article",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1066,
          "EntityName": "Event",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1067,
          "EntityName": "News",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1069,
          "EntityName": "Destination",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1070,
          "EntityName": "Category",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1073,
          "EntityName": "Tag",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1074,
          "EntityName": "Persona",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1075,
          "EntityName": "Product",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1078,
          "EntityName": "County",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1079,
          "EntityName": "Place",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1082,
          "EntityName": "Attraction",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1083,
          "EntityName": "Activity",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1084,
          "EntityName": "Feed",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1087,
          "EntityName": "Author",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1089,
          "EntityName": "EventType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1090,
          "EntityName": "FacilityCategory",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1091,
          "EntityName": "Facility",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1093,
          "EntityName": "POICategory",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1094,
          "EntityName": "POIAddress",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1095,
          "EntityName": "POIMMedia",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1096,
          "EntityName": "InterestCategoryType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1097,
          "EntityName": "CategoryType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1098,
          "EntityName": "SubcategoryType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1100,
          "EntityName": "POIContact",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1102,
          "EntityName": "AddressType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1103,
          "EntityName": "ContactType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1104,
          "EntityName": "MMediaType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1105,
          "EntityName": "POIPsbn",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1106,
          "EntityName": "DataType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1107,
          "EntityName": "FacilityService",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1108,
          "EntityName": "Table",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1109,
          "EntityName": "ActivityCategory",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1112,
          "EntityName": "Weather",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1113,
          "EntityName": "City",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1114,
          "EntityName": "AttractionType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1115,
          "EntityName": "PlaceType",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1116,
          "EntityName": "FacilitySubcategory",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1117,
          "EntityName": "Eden",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1119,
          "EntityName": "SourceEntity",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1120,
          "EntityName": "WebUsers",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1121,
          "EntityName": "ActivePeriod",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1122,
          "EntityName": "ExchangeRate",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1123,
          "EntityName": "Comments",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1124,
          "EntityName": "eVisitorCountry",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        },
        {
          "EntityTypeID": 1125,
          "EntityName": "Instagram",
          "EntityNamespaceGUI": "Infosit.Cms.Custom.Bo.Bo{0},Infosit.Cms.Custom.Bo"
        }
      ],

      navigateUrl: undefined,
      nextPageId: 0,
      reload: 0,
      resetLanguage: 1,
      allTags: {},
      a11y: {},
      entitiesStore: {},
      entitiesStoreUpdated: 0,
      forceLoadAllModule: false,
      languageList: [],

      loadingNewPage: false,
      showVirtualRouter: false,
      logs: [],
      review: {
        infoMessage: '',
        errorMessage: '',
        reviewModalIsOpen: false,
        title: '',
        description: '',
        rating: 0,
        tempRating: null,
        submitEnabled: true,
        complete: false
      }
    }
  },
  modules: {
    LoggedInUserModule: LoggedInUserModule
  },
  mutations: {
    ...regionMutations,
    ...dropdownMutations,
    ...reviewMutations,
    setEntityStoreAll(state, {entities, propertyName}: {propertyName: string, entities: EntityStore<RelatedInterface> }) {
      state.entitiesStore[propertyName] = entities
    },
    setEntityStorePartial(state, {entities, propertyName}: {propertyName: string, entities: EntityStore<RelatedInterface> }) {
      if (!state.entitiesStore[propertyName]) {
        state.entitiesStore[propertyName] = {entities: {}, ids: [], loaded: false, timeLoaded: new Date(), loading: false, propertyNames: []}
      }
      state.entitiesStore[propertyName].entities = {...state.entitiesStore[propertyName].entities, ...entities.entities};
      state.entitiesStore[propertyName].ids = [...new Set([...state.entitiesStore[propertyName].ids])]
    },
    setEntityStoreOne(state, {entity, propertyName}: {entity: RelatedInterface, propertyName: string} ) {
      state.entitiesStore[propertyName].entities[entity.EntityID.toString()] = entity
      state.entitiesStore[propertyName].ids.push(entity.EntityID.toString())
    },
    updateEntityStoreOne(state, {entity, propertyName}: {entity: RelatedInterface, propertyName: string}) {
      state.entitiesStore[propertyName].entities[entity.EntityID.toString()] = {...state.entitiesStore[propertyName].entities[entity.EntityID.toString()], ...entity}
    },
    loadingFinished(state, {id}) {
      //state.moduleDataList[getModuleDataKey(moduleInfo)].loaded = true;
      (state[id] as ComponentState).isLoading = false;
    },
    loadMoreLoadingStarted(state, {id}) {
      (state[id] as ComponentState).loadMoreLoading = true;
    },
    loadMoreLoadingFinished(state, {id}) {
      (state[id] as ComponentState).loadMoreLoading = false;
    },
    loadingStarted(state, {id}) {
      (state[id] as ComponentState).isLoading = true;
    },
    setGalleryType(state, {galleryType, id}) {
      if (state[id]) {
        (state[id] as ComponentState).galleryType =  galleryType;
      }
    },
    setVideoPoster(state, {videoPoster, id}) {
      if (state[id]) {
        (state[id] as ComponentState).hasVideoPoster = videoPoster;
      }
    },
    loadingError(state, {id, errorMessage, value}) {
      if (value) {
        console.error(errorMessage);
      }
      (state[id] as ComponentState).errorMessage = errorMessage;
      (state[id] as ComponentState).isError = value;
    },
    setA11yValue(state, {key, value}) {
      state.a11y[key] = value
    },
    addToFavorite(state, favorite: RelatedInterface) {
      state.favorites.push(favorite)
    },
    removeToFavorite(state, favorite: RelatedInterface) {
      state.favorites = state.favorites.filter(o => o.EntityID !== favorite.EntityID)
    },
    changeAdminMode(state, adminMode){
      state.AdminMode = adminMode
    },
    setChildData(state, {childData, id}) {
      if (!id) {
        console.error('ID missing')
      } else {
        (state[id] as ComponentState).childData = childData;
        (state[id] as ComponentState).isLoading = false;
      }
    },
    setModuleData(state, {moduleData, id, merge, moduleInfo, ...res}) {
      //console.log({moduleData});
      if (!id) {
        console.error('ID missing')
      } else {
        const dataString = getModuleDataKey(moduleInfo);
        if (!merge) {
          state.moduleDataList[dataString] = {
            ...state.moduleDataList[dataString],
            loaded: true,
          };
          if(state.moduleDataList[dataString].moduleIds){
            state.moduleDataList[dataString].moduleIds
              .forEach(idEach => {
                store.commit('loadingFinished', {
                  id: idEach,
                  loadMore: moduleData?.length,
                  moduleInfo: moduleInfo,
                });
                store.commit('setPagination', {
                  ...res,
                  pagination: res.pagination,
                  id: idEach
                });
                (state[idEach] as ComponentState).moduleData = [...moduleData];
              })
          }
          delete state.moduleDataList[dataString];
        } else {
          console.log(moduleData);
          (state[id] as ComponentState).moduleData = [...((state[id] as ComponentState).moduleData || []), ...moduleData];
          store.commit('loadingFinished', {
            id: id,
            loadMore: moduleData?.length,
            moduleInfo: moduleInfo,
          });
        }
        store.commit('setPagination', {
          ...res,
          pagination: true,
          id
        })

         /* data: moduleData,
          loaded: true,
          error: false,
          moduleIds: [id],
          message: ''
        }*/
       /* if (merge) {
          (state[id] as ComponentState).moduleData = [...((state[id] as ComponentState).moduleData || []), ...moduleData];
        } else {
          (state[id] as ComponentState).moduleData = moduleData;
        }*/
      }
    },
    setModuleInfo(state, {moduleInfo, id, query}: {moduleInfo: ModuleInfo, id: string, query: Record<string, string >}) {
      if (!id) {
        console.error('ID missing')
      } else {
        if (state[id]) {
/*          if (query && Object.keys(query).some(k => k.toString() === id.toString())) {
            const selectItemEmit : SelectItemEmit[] =
              Object.entries(JSON.parse(query[moduleInfo.Module.EntityID]) as QueryParamsModule)
              //.map(([o, i]) => ({property: o, value: i.value, type: i.type}), {});
              .map(([o, i]) => ({property: o, value: i}), {});
            /!*moduleInfo.Module.Api?.RequestBody = {
              ...moduleInfo.Module.Api?.RequestBody,
            };*!/
            (state[id] as ComponentState).filters =
              selectItemEmit.map(o => ({value: (o.value || '').toString(), property: (o.property), type: o.type}));
          }*/
          if (query) {
            Object.entries(query).forEach(([a,b]) => {
              if (moduleIncludeQuery(moduleInfo, a)) {
                (state[id] as ComponentState).filters = (state[id] as ComponentState).filters || [];
                if (a.toLowerCase().includes('date')) {
                  (state[id] as ComponentState).filters.push({
                    property: a, // getLookupSourceFromField(moduleInfo, a),
                    value: b,
                    type: 'date'
                  });
                } else {
                  (state[id] as ComponentState).filters.push({
                    property: a, // getLookupSourceFromField(moduleInfo, a),
                    value: b,
                    type: 'dropdown'
                  })
                }

              }
            })
          }
          (state[id] as ComponentState).editFilter = {
            and:  moduleInfo.Module.Binding.FiltersAnd,
            or: moduleInfo.Module.Binding.FiltersOr
          };
          (state[id] as ComponentState).moduleInfo = moduleInfo;
        } else {
          console.error('ID store missing')
        }
      }
    },
    setPagination(state, { Offset,Limit, Total, id}) {
      if (!id) {
        console.error('ID missing')
      } else {
        (state[id] as ComponentState).paginationActive = true;
        (state[id] as ComponentState).pagination = {
          CurrentPage: (Offset && Offset > 0) ? (Offset / (Limit ?? Total ?? 20)) + 1  : 1,
          LastPage: Math.ceil(Total/ Limit) ?? 1,
          Offset: Offset ?? 0,
          Limit: Limit ?? Total ?? 20,
          Total: Total ?? undefined,
        }
      }
    },
    setPaginationActive(state, { pagination, id}) {
      if (!id) {
        console.error('ID missing')
      } else {
        (state[id] as ComponentState).paginationActive = pagination as boolean;
      }
    },
    setMenuData(state, {menu}){
      state.Menu = menu;
    },
    setBreadcrumbs(state, {hpHyperlink}){
      state.HpHyperLink = hpHyperlink;
    },
    initModuleDataInList(state, {moduleInfo, id}: {moduleInfo: ModuleInfo, id: string}) {
      state.moduleDataList[getModuleDataKey(moduleInfo)] = {
        loaded: false,
        error: false,
        moduleIds: [id],
        message: ''
      };
      //console.log(state.moduleDataList);
    },
    writeLogs(state,logs: Log[]) {
      state.logs.push(...logs);
    },
    clearLogs(state,logs: Log[]) {
      state.logs = [];
    }
  },
  actions: {
    ...regionActions,
    ...dropDownActions,
    ...reviewActions,
    insertLogs({commit}, logs:Log[]) {
      if (logs?.length) {
        commit('writeLogs',logs)
      }
    },
    setEntityStorePartial(store, {entities, propertyName}: {propertyName: string, entities: { EntityID: string, Title: string, [key: string]: string }[] }) {
      const entityList = {
        loaded: false, loading: false,
        entities: {...entities.reduce((o, n) => ({...o,[n.EntityID]: n}), {})},
        ids: [...entities.map(o => o.EntityID)]
      }
      store.commit('setEntityStorePartial', {entities: entityList, propertyName})
    },
    setEntityStoreAll(store, {entities, propertyName}: {propertyName: string, entities: { EntityID: string, Title: string, [key: string]: string }[] }) {
      const entityList = {
        loaded: true, loading: false,
        entities: {...entities.reduce((o, n) => ({...o,[n.EntityID]: n}), {})},
        ids: [...entities.map(o => o.EntityID)]
      }
      store.commit('setEntityStoreAll', {entityList, propertyName})
    },
    addToFavorite(store, favorite: RelatedInterface) {
      if( checkFavoriteIntegrity(favorite) && !store.state.favorites.some(o => o.EntityID === favorite.EntityID)) {
        localStorage.setItem('favorites', JSON.stringify([...store.state.favorites, favorite]))
        store.commit('addToFavorite', favorite)
      }
    },
    removeToFavorite(store, favorite: RelatedInterface) {
      if(checkFavoriteIntegrity(favorite) && store.state.favorites.some(o => o.EntityID === favorite.EntityID)) {
        store.commit('removeToFavorite', favorite)
        localStorage.setItem('favorites', JSON.stringify([...store.state.favorites]))
      }
    },
    setA11yValue(store, {key, value}) {
      localStorage.setItem(key, value)
      store.commit('setA11yValue', {key, value})
    },
    setPersone(store, val: {[key: string] : string}) {
      store.state.persone = Object.entries(val).map(([key, val]) => ({property: key, value: val }))
    },
    setPerfectVacationId(store, val) {
      store.state.perfectVacationId = val
    },
    setupParams(store) {
      axiosInstance.post<AppParams[]>(
        '/api/company/params', { LanguageID: store.state.PageData.LanguageId }
      ).then(res => {
        store.state.Params = res.data
        store.dispatch('setDefaultEntitiesParent')
      })
    },
    setupHyperlinks(store) {
      //axiosInstance.defaults.baseURL = store.state.CdnApiBaseUrl;
      axiosInstance.post<SeoInterface[]>(
        '/api/seo',
        { LanguageID: store.state.PageData.LanguageId }
      ).then(res => {
        store.state.seoLinks = res.data
      })
    },
    setBreadcrumbs(store){
      axiosInstance
          .post("/api/breadcrumbs", {
            Settings: store.state.CdnApiKey,
            EntityID: parseInt(store.state.Params.find(o => o.Key === 'HomePageObjectId')?.Value || '', 10),
            LanguageID: store.state.PageData.LanguageId,
          })
          .then((response) => {
            store.state.HpHyperLink = response.data.length > 0 ? response.data[0] : null;
          })
    },
    setupEntityId(store) {
      //axiosInstance.defaults.baseURL = store.state.CdnApiBaseUrl;
      axiosInstance.post<EntityType[]>(
        '/api/entities/types',
        { LanguageID: store.state.PageData.LanguageId }
      ).then(res => {
        store.state.entityTypes = res.data
      })
    },
    setModuleInfo({ commit }, {moduleInfo, query}) {
      commit('setModuleInfo', {moduleInfo, id: moduleInfo.Module.EntityID.toString(), query,} )
    },
    setGalleryType({ commit }, {galleryType, id}: {galleryType: string, id: string}) {
      commit('setGalleryType', {galleryType, id} )
    },
    setVideoPoster({ commit }, {videoPoster, id}: {videoPoster: boolean, id: string}) {
      commit('setVideoPoster', {videoPoster, id} )
    },
    setMenuData(store){
      axiosInstance
        .post(
          "/api/v1/Menu", {
            MediaTypes: ["default", "menuimage", "menuloginimage"]
          })
        .then((response) => {
          const menuArr = response.data.map((o: any) => ({...o, Children: []}));

          if(menuArr.length > 0){
            menuArr.map((obj: MenuArray) => {
              sortInArray(obj.Items.map(o => ({...o, Children: [], MediaGalleryToShow: []})), obj);
            })
            store.commit('setMenuData', {
              menu: menuArr,
            })
          }
        })
        .catch((e) => {
          console.error('Error response: ', e)
        })
    },
    setDefaultEntitiesParent(store){
      if(store.state.Params.length){
        const entitiesParentParam = store.state.Params.find(o => o.Key === 'EntitiesDefaultParent')?.Value ||  "";
        if(entitiesParentParam){
          const entitiesList = entitiesParentParam.split('#');
          let entitiesParentArr: EntityDefaultParent[] = [];
          entitiesList.map((obj) => {
            const entityArr = obj.split('=');
            if(entityArr.length >= 2){
              const parentArr = entityArr[1].split('-');
              if(parentArr.length >= 2){
                let entityObj: EntityDefaultParent = {
                  EntityTypeID: parseInt(entityArr[0], 10),
                  ParentID: parseInt(parentArr[0], 10),
                  ParentTypeID: parseInt(parentArr[1], 10),
                };
                entitiesParentArr.push(entityObj);
              }
            }
          });
          store.state.EntityDefaultParent = entitiesParentArr;
        }
      }
    },
    makeNewRequest({state, commit}, {moduleInfo, merge, query} ) {
      commit('setModuleInfo',{moduleInfo, id: moduleInfo.Module.EntityID.toString(), query  })
      const entityName = moduleInfo.Module.Binding.SourceName; // findEntityName(+moduleInfo.Module.Api.RequestBody.EntityTypeID,state.entityTypes )
      if(!entityName) { return}
      state.moduleDataList[getModuleDataKey(moduleInfo)] = {
        loaded: false,
        error: false,
        moduleIds: [],
        message: ''
      }
      state.moduleDataList[getModuleDataKey(moduleInfo)].moduleIds.push(moduleInfo.Module.EntityID.toString())
      if (!merge) {
        commit('loadingStarted', {
          id: moduleInfo.Module.EntityID.toString()
        })
      } else {
        store.commit('loadMoreLoadingStarted', {
          id: moduleInfo.Module.EntityID.toString()
        })
      }
      let request
      const body = {
        // Settings: state.AltCdnApiKey,
        ...getRequestProps(moduleInfo)
      }
      if (!moduleInfo.Module.Binding.SourceEntityID && moduleInfo.Module.Binding.SourceParentID) {
        const filters = getModuleFilters(moduleInfo, state)
        request = axiosInstance.post(
          `/api/v1/${entityName}`,
          {
            ...body,
            Filters: {...filters,
              AND: [...(filters?.AND || []), [{ParentID: {is: moduleInfo.Module.Binding.SourceParentID.toString()}}]]},
          }
        )
      } else if (moduleInfo.Module.Binding.SourceEntityID) {

        request = axiosInstance.post(
          `/api/v1/${entityName}/${moduleInfo.Module.Binding.SourceEntityID}`,
          body
        )
      } else {
        request =axiosInstance.post(
          `/api/v1/${entityName}`,
          {
           ...body,
            Filters: getModuleFilters(moduleInfo, state),
          })
      }
      request.then(res => {
        commit('setModuleData', {
          ...res.data,
          moduleData: res.data.Data.map((o: any) => setPublicationPlace(o)),
          id: moduleInfo.Module.EntityID.toString(),
          moduleInfo,
          merge
        })
        commit('loadMoreLoadingFinished', {
          id: moduleInfo.Module.EntityID.toString()
        })
      })
    },
    //TODO makeRequest global function from store
    /*makeRequest (store, {moduleInfo, isFilteredRequest, merge} ) {
      const state: { moduleInfo: ModuleInfo, isFilteredRequest: boolean } = {moduleInfo, isFilteredRequest}
      state.moduleInfo.Module.Api.RequestBody = prepareRequestBody(moduleInfo, store.state)
      if (!merge) {
        store.commit('loadingStarted', {
          id: state.moduleInfo.Module.EntityID.toString()
        })
      } else {
        store.commit('loadMoreLoadingStarted', {
          id: state.moduleInfo.Module.EntityID.toString()
        })
        getModuleData( moduleInfo, store.state)
          .then(res => {
            if (res ) {
              let resData = res.Data;
              store.commit('setModuleData', {
                ...res,
                moduleData: resData,
                id: state.moduleInfo.Module.EntityID.toString(),
                moduleInfo,
                merge
              })
              store.commit('loadMoreLoadingFinished', {
                id: state.moduleInfo.Module.EntityID.toString()
              })
            }
          })
      }
      if(!store.state.moduleDataList[getModuleDataKey(moduleInfo)] && !merge) {
        store.commit('initModuleDataInList', {moduleInfo, id: state.moduleInfo.Module.EntityID.toString()});
        getModuleData( moduleInfo, store.state)
          .then(res => {
            if (res ) {
              let resData = res.Data;
              store.dispatch('setEntityStorePartial', {entities: res.Data, propertyName: 'ModuleData'})
              store.commit('setModuleData', {
                ...res,
                moduleData: resData,
                id: state.moduleInfo.Module.EntityID.toString(),
                moduleInfo,
                merge
              })
              store.commit('loadingError', {
                id: state.moduleInfo.Module.EntityID.toString(),
                errorMessage: '',
                value: false
              })
            }
          })
          .catch((e) => {
            console.error({e});
            store.commit('loadingFinished', {
              id: state.moduleInfo.Module.EntityID.toString()
            })
            store.commit('loadingError', {
              id: state.moduleInfo.Module.EntityID.toString(),
              errorMessage: e?.response?.data?.ErrorMessage,
              value: true
            })
          })
      }
      if (store.state.moduleDataList[getModuleDataKey(moduleInfo)] && !store.state.moduleDataList[getModuleDataKey(moduleInfo)]
        .moduleIds.some(o => o === state.moduleInfo.Module.EntityID.toString())){
        store.state.moduleDataList[getModuleDataKey(moduleInfo)]
          .moduleIds.push(state.moduleInfo.Module.EntityID.toString())
      }
      if (+moduleInfo.Module.Api.RequestBody.ItemPerPage) {
        store.commit('setPaginationActive', {
          pagination: true,
          id: state.moduleInfo.Module.EntityID.toString()
        })
      }
    }*/
  },

  getters: {
    ...reviewGetters,
    getA11yValue: (state) => (key: string) => state.a11y[key] ?? false,
    getAllA11yValues: (state) =>  state.a11y
  }
})


window.addEventListener('load', function () {
  store.state.PagePerfomance.Load = ((performance.now() % 60000) / 1000).toFixed(2)
}, false);

// Instantiate the performance observer
const perfObserver = new PerformanceObserver(function (list) {
  // Get all the resource entries collected so far
  // (You can also use getEntriesByType/getEntriesByName here)
  const entries = list.getEntries();

  // Iterate over entries
  for (let i = 0; i < entries.length; i++) {
    // Do the work!
    store.state.PagePerfomance.Requests++;
  }
});

// Run the observer
perfObserver.observe({
  // Polls for Navigation and Resource Timing entries
  entryTypes: ["navigation", "resource"]
});

export const Appstore = store

