<template>

  <!-- TODO: hendlati clear msgs -->
  <div
    v-if="infoMessage"
    class="login-msg login-msg--info"
  >
    <p> {{ infoMessage }} </p>
    <button @click="dismissInfoMessage">
      ×
    </button>
  </div>

  <AppInput
    v-model="displayName"
    :input-type="'text'"
    :placeholder="$t('loc_login_display_name')"
    :class-list="['login-input border-b-2 border-color-midgrey bg-transparent text-color-text text-16 font-sans-bold w-full pb-1']"
    :show-icon="false"
    :value="''"
  />
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="eVisitorList.length"
      :select-list="eVisitorList"
      :selected-item="selectedEVisitor"
      :property="entityEVisitorCountry"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      @onChangeSelected="onEVisitorChange"
    />
  </div>
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="personaList.length"
      :select-list="personaList"
      :selected-item="selectedPersona"
      :property="entityPersona"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      @onChangeSelected="onPersonaChange"
    />
  </div>
  <div class="filter-dropdown-container">
    <AppDropDown
      v-if="productList.length"
      :select-list="productList"
      :selected-item="selectedProduct"
      :property="entityProduct"
      :class-list="['auth-dropdown']"
      :select-all="true"
      :select-all-string="'loc_login_dashboard_remove_select'"
      @onChangeSelected="onProductChange"
    />
  </div>
  <div class="text-center mt-6 lg:mt-20">
    <button
      class="btn-blue-border w-full mb-2"
      @click="confirmChanges()"
    >
      {{ $t('loc_login_button_save_changes') }}
    </button>
  </div>
</template>

<script>

import {LoginStateEnum} from "@/interfaces/login/login-state-enum";
import {mapGetters} from "vuex";
import AppInput from "@/libs/ui/lib/forms/Input/AppInput.vue"
import AppDropDown from "@/libs/ui/lib/forms/select/AppDropDown";

export default {
  name: "DashBoardForm",
  components: {
    AppDropDown,
    AppInput,
  },
  emits: {
    'close-modal': null,
    'confirm-modal': null
  },
  data() {
    return {
      displayName: '',
      eVisitorId: '',
      persona: '',
      product: '',
      LOGIN_TYPE: this.$constants.LOGIN_TYPE,
      entityEVisitorCountry: 'eVisitorCountry',
      entityPersona: 'Persona',
      entityProduct: 'Product'
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'LoggedInUserModule/errorMessage',
      infoMessage: 'LoggedInUserModule/infoMessage'
    }),
    modalShown() {
      return this.$store.state.LoggedInUserModule.loginState === LoginStateEnum.DASHBOARD
    },
    authState() {
      return this.$store.state.LoggedInUserModule
    },
    dashboardDTO() {
      console.log("dashboard");
      console.log(this.displayName);
      console.log(this.eVisitorId);
      return {
        ...(this.displayName && {DisplayName: this.displayName}),
        ...(this.eVisitorId && {UserCountry : this.eVisitorId}),
        ...(this.eVisitorId && {UserCountryName: this.eVisitorList.find(o => o.value.toString() == this.eVisitorId.toString()).name}),
        ...(this.persona && {Persona : this.persona}),
        ...(this.product && {Product : this.product})
      }
    },
    selectedEVisitor() {
      return this.authState.userCountry ? {
        value: +this.authState.userCountry,
        property: this.entityEVisitorCountry
      }: null
    },
    selectedPersona() {
      return this.authState.persona ? {
        value: +this.authState.persona,
        property: this.entityPersona
      }: null
    },
    selectedProduct() {
      return this.authState.product ? {
        value: +this.authState.product,
        property: this.entityProduct
      }: null
    },
    eVisitorList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityEVisitorCountry]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityEVisitorCountry
      })) ?? []
    },
    personaList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityPersona]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityPersona
      })) ?? []
    },
    productList() {
      return (Object.values(this.$store.state?.entitiesStore?.[this.entityProduct]?.entities ?? {})).map(o => ({
        value: o.EntityID,
        name: o.Title,
        property: this.entityProduct
      })) ?? []
    },
  },
  mounted() {
    this.dismissInfoMessage()
    this.getUserDataFromStore()
    this.getEVisitorCountry()
    this.getPersona()
    this.getProduct()
  },
  methods: {
    onEVisitorChange(val) {
      this.eVisitorId = val?.value?.toString() ?? ''
    },
    onPersonaChange(val) {
      this.persona = val?.value?.toString() ?? ''
    },
    onProductChange(val) {
      this.product = val?.value?.toString() ?? ''
      this.product = val?.value?.toString() ?? ''
    },
    getEVisitorCountry() {
      this.$store.dispatch('initDropDownStore', this.entityEVisitorCountry)
    },
    getPersona() {
      this.$store.dispatch('initDropDownStore', this.entityPersona)
    },
    getProduct() {
      this.$store.dispatch('initDropDownStore', this.entityProduct)
    },
    getUserDataFromStore() {
      this.displayName = this.authState.displayName
      this.eVisitorId = this.authState.userCountry
    },
    confirmChanges() {
      this.registrationFormErrors = "";
      this.$store.dispatch('LoggedInUserModule/dashboardSave',this.dashboardDTO)
    },
    setPrivacyChecked(val) {
      this.privacyChecked = val
    },
    dismissInfoMessage() {
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
    },
    closeModal() {
      this.$store.commit('LoggedInUserModule/quitRegistration');
      this.$store.commit('LoggedInUserModule/setInfoMessage', '');
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
    dismissFormErrors() {
      this.registrationFormErrors = "";
    },
    dismissErrorMessage() {
      this.$store.commit('LoggedInUserModule/setErrorMessage', '')
    },
/*    confirmModal(loginType) {
      this.registrationFormErrors = "";
      if (!this.privacyChecked) {
        this.registrationFormErrors = "loc_login_privacy_missing";
        return;
      }
      this.$store.dispatch('LoggedInUserModule/dashboardSendStart', {
        loginType: loginType,
        displayName: this.displayName,
        email: this.email,
        password: this.password
      })

    },*/
  },
};
</script>

<style lang="scss" scoped>

</style>
