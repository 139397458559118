<template>
  <div
    v-if="moduleInfo.length > 0"
    class="w-full flex flex-wrap items-center justify-center lg:gap-16 lg:justify-center text-left pb-5 lg:pb-10 lg:pt-6"
  >
    <MenuImagesLink
      v-for="logo in moduleInfo"
      :key="logo.EntityID"
      :menu-item="logo"
      :class-menu="'relative flex items-center w-auto lg:w-3/12 h-20 px-4 lg:px-0 h-auto mt-2 lg:mt-0'"
      :media-type="'default'"
      :img-class="'footer-logo-image'"
    />
  </div>
</template>

<script>
import { menuMediaMixin } from '@/mixins';
import MenuImagesLink from "../MenuImagesLink";

export default {
  components: {MenuImagesLink},
  mixins: [menuMediaMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  
};
</script>
