<template>
  <div
    v-if="geoLocationAllowed && showDistanceData"
    :moduleInfo="moduleInfo"
    class="container max-w-screen-md lg:max-w-screen-xl mx-auto my-6"
  >
    <h2
      v-if="
        hasPropertyValue(
          'ModuleTitle',
          moduleInfo.Module.ModuleProperties,
          moduleData
        ) && distanceDataSuccessArr.length
      "
      class="headline-3a text-color-primary pb-0 text-center md:text-left"
    >
      {{
        getPropertyValue($t,"ModuleTitle",moduleInfo.Module.ModuleProperties,moduleData)
      }}
    </h2>
    <p
      v-if="userCountry"
      class="overtitle text-16 text-center md:text-left"
    >
      {{ $t("loc_distance_welcome") }}, {{ getUserCountry(userCountry) }}
    </p>

    <div v-if="gettingLocation">
      <div class="loader" />
    </div>

    <div
      v-if="gotDestination && gotCoordinates"
      class="flex flex-wrap sm:flex-no-wrap items-start "
      :class="{
        'flex-wrap justify-between lg:justify-around space-x-3 lg:px-4 pt-8': moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'True',
        'justify-around md:justify-start md:space-x-16 lg:space-x-32 xl:space-x-40 md:px-8 lg:px-16 pt-12': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === ''),
      }"
    >
      <div
        v-for="currentDistance in calculatedDistance"
        :key="currentDistance.type"
        class="flex flex-col items-center text-center mb-6 md:mb-0"
        :class="{
          'w-auto':
            moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'True',
          'w-1/2 sm:w-auto': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === ''),
        }"
      >
        <!-- <i
          v-if="currentDistance.duration !== ''"
          :class="'icon icon-' + currentDistance.icon + '-ico block md:min-h-20'
          "
        ><span
          class="path1 text-48"
          :class="{' text-60 md:text-80': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === '')}"
        /><span
          class="path2 text-48"
          :class="{'text-60 md:text-80': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === '')}"
        /><span
          class="path3 text-48"
          :class="{'text-60 md:text-80': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === '')}"
        /></i> -->
        <Icon
          v-if="currentDistance.duration !== ''"
          :icon-name="currentDistance.icon"
          :icon-class="'icon icon-' + currentDistance.icon + '-ico block md:min-h-20'"
          :span-class="isSidebarClass(moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar)"
        />
        <p
          v-if="currentDistance.duration !== ''"
          class="p-xs text-color-text uppercase"
          :class="{'mt-4': (moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' || moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === '')}"
        >
          {{ currentDistance.distance }}
        </p>
        <p
          v-if="currentDistance.duration !== ''"
          class="p-sm text-color-primary"
        >
          {{ currentDistance.duration }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import {selfDataRequestMixin} from "@/mixins";
import Icon from "@/components/includes/Icon";

export default {
  name: "Distance",
  components: {
    Icon
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // googleMapsApiKey: "",
      // destination: "",
      gettingLocation: false,
      geoLocationAllowed: true,
      hasCoordinates: false,
      distanceDataSuccessArr: [],
      showDistanceData: true,
      originCoord: [],
      userCountry: "",
      destinationCoord: [],
      calculatedDistance: [
        {
          name: "car",
          type: "driving",
          icon: "car",
          distance: "",
          duration: "",
        },
        {
          name: "bus",
          type: "transit",
          icon: "bus",
          distance: "",
          duration: "",
        },
        {
          name: "bicycle",
          type: "bicycling",
          icon: "bike",
          distance: "",
          duration: "",
        },
      ],
    };
  },
  computed: {
    googleMapsApiKey () {
      return  this.$store.state.Params.find(o => o.Key === 'GoogleMapsAPIKey')?.Value ||  "";
    },
    gotCoordinates: function () {
      if (this.hasCoordinates) {
        if (this.googleMapsApiKey) {
          this.loadGoogleMapsApi();
        }
        return true;
      }
      return false;
    },
    gotDestination: function () {
      if (this.destination) {
        this.getOriginsAndDestCoordinates();
        return true;
      }
      return false;
    },
    hasModulePropDestination() {
      return this.$filters.hasPropertyValue(
        "ModuleDestinationCoords",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleInfo.Module
      )
    },
    destinationFromModuleProp () {
      return this.$filters.getPropertyValue(  this.$t,
        "ModuleDestinationCoords",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleInfo.Module
      )
    },
    destinationFromParams() {
      return this.$store.state.Params.find(o => o.Key === 'DistanceDefaultDestinacija')?.Value || '';
    },
    destination() {
      return this.hasModulePropDestination ? this.destinationFromModuleProp : this.destinationFromParams;
    }
  },
  mounted() {
    /*this.$parameters.getParameter("GoogleMapsAPIKey", "").then((response) => {
      this.googleMapsApiKey = response;
    });*/
/*    if (
      this.$filters.hasPropertyValue(
        "ModuleDestinationCoords",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleInfo.Module
      )
    ) {
      this.destination = this.$filters.getPropertyValue(  $t,
        "ModuleDestinationCoords",
        this.moduleInfo.Module.ModuleProperties,
        this.moduleInfo.Module
      );
    } else {
      this.$parameters
        .getParameter("DistanceDefaultDestinacija", "")
        .then((response) => {
          this.destination = response;
        });
    }*/
  },
  methods: {
    async calcDistance(calcDistanceObj) {
      let travelMode;
      let transitMode;
      switch (calcDistanceObj.type) {
        case "bicycling":
          travelMode = this.google.maps.TravelMode.BICYCLING;
          break;
        case "transit":
          travelMode = this.google.maps.TravelMode.TRANSIT;
          transitMode = {
            modes: [calcDistanceObj.name.toUpperCase()],
          };
          break;
        default:
          travelMode = this.google.maps.TravelMode.DRIVING;
          transitMode = null;
      }
      const service = new this.google.maps.DistanceMatrixService();
      return service.getDistanceMatrix(
        {
          origins: this.originCoord,
          destinations: this.destinationCoord,
          //london
          /* origins: ["51.552029,-0.298980"],
          destinations: ["51.507351,-0.127758"], */
          travelMode: travelMode,
          unitSystem: this.google.maps.UnitSystem.METRIC,
          transitOptions: transitMode,
        },
        (response, status) => {
          if (status !== "OK") {
            calcDistanceObj.distance = response.statusText;
          }
          if (status === "OK" && response.rows[0].elements[0].status === "OK") {
            this.getVisitorsCountry(response.originAddresses[0]);
            this.distanceDataSuccessArr.push(response.originAddresses[0]);
            calcDistanceObj.distance =
              response.rows[0].elements[0].distance.text;
            calcDistanceObj.duration = this.$t("loc_distance_calculation", [
              response.rows[0].elements[0].duration.text,
              this.$t(
                this.$filters.stringFormat(
                  "loc_distance_{0}",
                  calcDistanceObj.name
                )
              ),
            ]);
          } else {
            calcDistanceObj.distance = this.$t("loc_distance_not_reachable");
          }
          this.showDistanceData = this.distanceDataSuccessArr.length > 0;
          return true
        }
      ).then(() => true);
    },
    getOriginsAndDestCoordinates() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        this.getCoordsSuccess,
        this.getCoordsError,
        options
      );
    },
    getCoordsSuccess(pos) {
      this.gettingLocation = false;
      const crd = pos.coords;
      this.originCoord.push(crd.latitude + "," + crd.longitude);
      this.destinationCoord.push(this.destination);
      this.hasCoordinates = true;
    },
    getCoordsError() {
      this.gettingLocation = false;
      this.geoLocationAllowed = false;
      this.hasCoordinates = false;
    },
    getVisitorsCountry(res) {
      let resArray = res.split(",");
      this.userCountry = res.split(",")[resArray.length - 1];
      this.setCookie('userFromCountry', this.userCountry)
    },
    async loadGoogleMapsApi() {
      this.google = await GoogleMapsApiLoader({
        apiKey: this.googleMapsApiKey,
        language: this.$store.state.PageData.LanguageId,
      });
      if (this.getCookie('distance' + this.destination)) {
        this.calculatedDistance = this.getCookie('distance' + this.destination);
        if(this.getCookie('distanceName' + this.destination)){
          this.distanceDataSuccessArr = this.getCookie('distanceName' + this.destination);
        }
        if(this.getCookie('userFromCountry')){
          this.userCountry = this.getCookie('userFromCountry');
        }
      } else {
        await this.asyncForEach(this.calculatedDistance, async (obj) => {
          await this.calcDistance(obj);
        })
        this.setCookie('distance' + this.destination, this.calculatedDistance, 8);
        this.setCookie('distanceName' + this.destination, this.distanceDataSuccessArr, 8);
      }
    },

    getUserCountry(userCountry){
      const userCountryWithoutNumbers = userCountry.replace(/\d+/g, '');
      if(userCountryWithoutNumbers != ' '){
        return userCountryWithoutNumbers;
      } else {
        return this.$t("loc_distance_default_country");
      }
    },
    isSidebarClass(isSidebar){
      if(isSidebar === 'True'){ return 'text-48 '; }
      else return 'text-60 md:text-80 ';
    },
  },
};
</script>

<style></style>
