<template>
  <div class="nav-dropdown maps-region">
    <div class="nav-dropdown-container flex max-w-screen-lg mx-auto space-x-16 lg:space-x-0">
      <!--<div class="w-1/2 flex justify-between">-->
      <div class="w-full max-w-screen-md flex flex-wrap">
        <div
          v-for="(menuItem, index) in moduleInfo.Children"
          :key="menuItem.EntityID"
          class="w-full md:w-1/2 pb-4 lg:pb-8 lg:pr-4"
        >
          <!-- Zamjenjena klasa space-y-2 sa w-1/2 pb-8 (za više od 2 kolone da stavke idu u drugi red)-->
          <NavMenuItemOption :menu-item="menuItem" />
          <div
            v-if="index == (moduleInfo.Children.length - 1) && (moduleInfo.SefUrl || moduleInfo.CustomUrl)"
            class="pt-6"
          />
        </div>

        <VirtualLink
          :close-menu-on-click="true"
          :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
          :target="moduleInfo.Target"
          class-list="btn-red-border"
        >{{ $t("loc_explore_more") }}</VirtualLink>
      </div>

      <!--<div class="w-1/2">-->
      <RegionMap />
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";
import {regionList} from "@/components/utils/regionList";
import {mapActions} from "vuex";
import RegionMap from "@/components/includes/region/RegionMap";

export default {
  components: {
    RegionMap,
    NavMenuItemOption
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loggedIn: false,
      regionsPaths: regionList,
      showTooltip: false,
      hoveredRegion: '',
      mousePosition: {
        top: 0,
        left: 0
      }
    };
  },

};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
.btn-red-border{max-height: 60px;}


</style>

