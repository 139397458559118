<template>
  <div
    v-if="modalShown"
    class="modal"
  >
    <div class="modal-content-container">
      <div
        class="modal-close-icon"
        @click="cancelModal()"
      >
        ×
      </div>
      <div class="modal-image w-5/12 max-w-740">
        <!-- TODO: povuci sliku negdje iz edita -->
        <img src="https://via.placeholder.com/400x900" class="w-full">
      </div>
      <div class="modal-form w-7-12 relative mx-auto">
        <div class="absolute vh-center mx-auto w-fixed-380">
          <div class="modal-title text-color-darkgrey text-32 font-sans text-center mb-12">
            {{ $t(title) }}
          </div>
          <div class="modal-content">
            <slot name="content" />
          </div>
          <div class="modal-actions">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// TODO - create a real modal, and put login form as content inside
// TODO - prebaciti sve u  headless Dialog from '@headlessui/vue'

export default {
  name: "Modal",
  components: {
  },
  props: {
    modalShown: Boolean,
    title: {
      type: String,
      default: undefined
    }
  },
  emits: {
    'modal-closed': null
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  updated() {
    console.log('dom-updated modal' );
    //document.body.classList.add("body-lock-scroll");
  },
  beforeUnmount() {
    console.log('unmounting modal');
    //document.body.classList.remove("body-lock-scroll");
  },
  methods: {
    cancelModal: function() {
      this.$emit('modal-closed', null);
       //document.body.classList.remove("body-lock-scroll");
    },
  }
};

</script>

<style lang="scss" scoped>
.modal {
  @apply fixed z-40 left-0 right-0 top-0 bottom-0 overflow-hidden flex items-center justify-center;
  top:120px;
  .modal-content-container {
    @apply flex w-full h-full bg-white relative;
    z-index: 2;
    .modal-close-icon {
      @apply absolute top-0 right-0 block text-60 z-10 transition-all duration-300 mr-2 -mt-2 cursor-pointer;
      &:hover{
        @apply text-color-secondary;
      }
    }
  }
}
</style>
