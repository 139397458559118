
import {I18nMixin} from "@/mixins/i18nMixin.ts";
import {Options} from "vue-class-component";
import {throttle} from "lodash";
import {Getter} from "vuex-class";
import {Watch} from "vue-property-decorator";
import {AppA11y} from "@/interfaces";
import {firebaseAuthProvider} from "@/services/firebase";
import {Appstore} from "@/store/main.store";
import axios from "axios";
//@ts-ignore
import https from "https";
import {LoginStateEnum} from "@/interfaces/login/login-state-enum";

@Options({})
export default class App extends I18nMixin {

  @Getter('getAllA11yValues') allA11yvalues: any
  @Watch('$store.state.a11y', {deep: true, immediate: true})
  onAllyChange(val: AppA11y) {
    Object.entries(val).forEach(([key, val]) => {
      if (val) {
        document.documentElement.classList.add(key);
      } else {
        document.documentElement.classList.remove(key);
      }
    })
  }


  mounted(){
    this.setupPageData();
    this.lazyLoad();
    window.addEventListener('load',  throttle(this.lazyLoad, 100));
    window.addEventListener("scroll", throttle(this.lazyLoad, 100));
    this.tryLoggedIn('app');
    this.sendLogToServer()
  }
  unmounted() {
    window.removeEventListener('scroll', throttle(this.lazyLoad, 100));
    window.removeEventListener('load', throttle(this.lazyLoad, 100));
  }
  get entityTypes(): any[] {
    return this.$store.state.entityTypes
  }
  @Watch('entityTypes', {deep: true, immediate: true})
  onEntityTypesChange(val: any[]) {
    // console.log(val.length);
    if(val.length) {
      this.tryLoggedIn('entityTypes tryLoggedIn')
    }
  }
  tryLoggedIn(inn: string) {
    /**
     * Connect firebase to app
     */
    if (this.entityTypes.length && !this.firebaseInit) {
      this.firebaseInit = true
      firebaseAuthProvider.onAuthStateChanged((user) => {
        if (!user) {
          Appstore.dispatch({
            type: 'LoggedInUserModule/setUserFromFirebase',
          });
        } else {
          if (!user.emailVerified ) {
            Appstore.dispatch('LoggedInUserModule/logout', {keepState: true, message: 'loc_login_email_sent_for_verification'})
          } else {
            console.log('emailVerified');
            // TODO - constants for actions
            Appstore.dispatch('LoggedInUserModule/setUserFromFirebase',
              {user , entityType: this.entityTypes.find(o => o.EntityName === 'WebUsers')}
            );
          }
        }
      });
    }


  }
  sendLogToServer() {
    setInterval(() => {

      if (this.$store.state.PageData.LogActive) {
        const axiosInstance = axios.create({
          httpsAgent: new https.Agent({
            rejectUnauthorized: false
          }),
          withCredentials: false,
          headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        if(this.$store.state.logs.length) {
          axiosInstance.post(Appstore.state.CdnApiBaseUrl + '/api/log', {
            Settings: this.$store.state.CdnApiKey,
            LoggerList: this.$store.state.logs
          }).then(() => {
            this.$store.commit('clearLogs')
          }).catch(() => {

            this.$store.commit('clearLogs')
          })
        }
      } else {
        this.$store.commit('clearLogs')
      }

    }, 60 * 1000)
  }
  async created() {
    //await initDb();
    //const tags = await getTagsFromDb()
    //this.$store.state.allTags = Object.fromEntries(tags.map(o => ([o.EntityID, o.Title])))
  }

}
