<template>
  <template
    v-if="showChildren"
  >
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="moduleInfo"
      :show-only-icon="true"
    />
    <h3
      v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
      class="overtitle-1 md:text-22 text-white text-left pb-5"
    >
      <VirtualLink :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl">
        {{ moduleInfo.Name }}
      </VirtualLink>
    </h3>
    <h3
      v-else
      class="overtitle-1 md:text-22 text-white text-center md:text-left pb-2 md:pb-5 ml-3"
    >
    <span class="relative"><span class="relative z-1">{{ moduleInfo.Name }}</span><svg class="svg-parallelogram z-0" viewBox="0 0 2000 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" data-v-c54cdae4=""><rect x="190" y="0" width="90%" height="100%" transform="skewX(-20)" data-v-c54cdae4=""></rect></svg></span>
    </h3>
  </template>
  <div
    class="footer-html-object"
    v-if="moduleInfo.MenuHtml"
    :class="moduleClass"
    v-html="moduleInfo.MenuHtml"
  />
  <ul
    v-if="(showChildren && moduleInfo.Children.length > 0) || (!showChildren && moduleInfo.length > 0)"
    :class="moduleClass"
  >
    <template
      v-for="infoFooterItem in (showChildren ? moduleInfo.Children : moduleInfo)"
      :key="infoFooterItem.EntityID"
    >
      <ModuleLink
        v-if="$store.state.AdminMode"
        :module-properties="infoFooterItem"
        :show-only-icon="true"
      />
      <li
        v-if="infoFooterItem.SefUrl || infoFooterItem.CustomUrl"
      >
        <VirtualLink
          :href="infoFooterItem.CustomUrl ? infoFooterItem.CustomUrl : infoFooterItem.SefUrl"
          :class-list="'footer-link text-16 leading-8 hover:text-color-secondary'"
        >
          {{ infoFooterItem.Name }}
        </VirtualLink>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
    moduleClass: {
        type: String,
        default: () => (''),
    },
    showChildren: {
      type: Boolean,
      default: () => (true),
    }
  }
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
