
import {Listbox,ListboxButton,ListboxOptions,ListboxOption} from "@headlessui/vue";
import {Options, Vue} from "vue-class-component";
import {Prop, Emit, Watch} from "vue-property-decorator";
import {FilterType, SelectItem, SelectItemEmit} from "@/libs/ui/.storybook/interfaces.ts";


@Options({
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  emits: ['on-change-selected']
})
export default class AppDropDown extends Vue {

  @Prop({default: []})
  classList: string[] = [];
  @Prop({required: true})
  selectList: SelectItem[] = [];
  @Prop({default: 'font-semibold', required: false})
  selectedClass: string = 'font-semibold';
  @Prop({default: 'Odaberite', required: false})
  placeholder: string = 'Odaberite';
  @Prop({default: 'Title', required: false})
  title: string = 'Title';
  @Prop({default: false, required: false})
  selectAll: boolean = false;
  @Prop({default: undefined, required: false})
  selectAllString?: string;
  @Prop({default: 'font-normal', required: false})
  notSelectedClass: string = 'font-normal';
  @Prop({default: 'property', required: true})
  property: string = 'property';
  @Prop({default: null, required: false })
  selectedItem: SelectItem | null = null;
  @Prop({default: false, required: false })
  showTitle: boolean  = false;
  @Prop({default: 'dropdown', required: false})
  type: FilterType = 'dropdown';

  @Emit()
  onChangeSelected (val: SelectItemEmit) {}
  isSelected: Object = false
  valueToShow: SelectItem[] = [];
  selectedValue: SelectItem | null = null
  onChangeDropDown(val: SelectItem) {
    this.isSelected = true;
    if (val.value) {
      this.onChangeSelected({value: val.value, property: this.property, type: this.type});
      this.selectedValue = val;
    } else {
      this.onChangeSelected({value: null, property: this.property, type: this.type});
      this.selectedValue = this.valueToShow[0];
    }
  }
  @Watch('selectedItem')
  onSelectedItemChange(){
    this.init()
  }

  created() {
    // prva vrijednos dok nije neodabran neka bude
    this.init()

  }
  init() {
    this.valueToShow = [{name: this.placeholder, value: null} ];
    if (this.selectAll) {
      this.valueToShow.push({name: this.selectAllString ? this.$t(this.selectAllString) : this.$t("loc_dropdownlist_all"), value: 0})
    }
    this.valueToShow.push(...this.selectList);
    if (this.selectedItem && this.valueToShow.some(o => o.value === this.selectedItem?.value)) {
      this.selectedValue = {...(this.valueToShow.find(o => o.value === this.selectedItem?.value) as SelectItem)}
    } else {
      this.selectedValue = {name: this.placeholder, value: null}
    }
  }
}

