<template>
  <div
    class="hero-container hero-big-container max-w-screen-3xl 3xl:max-w-full mx-auto relative"
    data-customize-btn="true"
    :class="moduleInfo.Module.CssClass"
  >
    <div
      v-if="loaded"
      class="relative hero-100h"
      :class="{
        'hero-home':
          moduleInfo.Module.ModuleProperties.ModuleHasHomepage === 'True',
      }"
    >
      <!-- If HasSlider = True -->
      <swiper
        v-if="moduleInfo.Module.ModuleProperties.ModuleHasSlider === 'True'"
        ref="heroSwiper"
        effect="fade"
        :slides-per-view="swiperHeroOptions.slidesPerView"
        :pagination="swiperHeroOptions.pagination"
        :speed="1500"
        :autoplay="{
          'delay': swiperDelay,
          'disableOnInteraction': false
        }"
        class="hero-swiper h-full"
        @slideChange="slideChange"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="image of firstModuleDataItem?.MediaGallery"
          :key="image"
        >
          <vue-picture :image="image" />
        </swiper-slide>
        <div
          class="container max-w-screen-xl mx-auto absolute z-10 left-0 right-0 bottom-0 md:mb-4 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:pr-16 2xl:pr-4"
          :class="{'justify-end': moduleInfo.Module.ModuleProperties.ModuleHasHomepage === 'True'}"
        >
          <!-- <Breadcrumbs
            :module-info="moduleInfo"
            :module-class="'breadcrumbs text-white text-14 relative'"
          /> -->
          <div class="relative flex flex-row items-center lg:space-x-4">
            <div
              v-if="firstModuleDataItem?.MediaGallery.length > 1"
              class="swiper-pagination pagination-bullet relative"
            />
            <div
              v-if="firstModuleDataItem?.MediaGallery.length > 1"
              class="swiper-pagination pagination-fraction relative hidden lg:block"
            />
            <div v-if="slideCaption">
              <p class="text-16 text-white relative hidden lg:block">
                {{ slideCaption }}
              </p>
            </div>
          </div>
        </div>
      </swiper>

      <!-- Else show image -->
      <vue-picture
        v-if="firstModuleDataItem?.MediaGallery.length && moduleInfo.Module.ModuleProperties.ModuleHasSlider !== 'True'"
        :image="firstModuleDataItem.MediaGallery[0]"
        :lazy-img-class="'no-lazy-image'"
        :priority-level="'high'"
        css-class="hero-img-big"

      />
      <div class="container max-w-screen-xl mx-auto absolute z-10 left-0 right-0 bottom-0 md:mb-4 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:pr-16 2xl:pr-4">
        <Breadcrumbs
          v-if="(moduleInfo.Module.ModuleProperties.ModuleHasSlider == 'False' || moduleInfo.Module.ModuleProperties.ModuleHasSlider == '')"
          :module-info="moduleInfo"
          :module-class="'breadcrumbs text-white text-14 relative'"
        />
      </div>
      <!--# hero-heading postoji za hero-homepage i hero-big modul, na ostalim hero sliderima nema -->
      <div
        v-if="!moduleInfo.Module.ModuleProperties.ModuleHasNoContentOverHeader"
        class="hero-heading container max-w-full xl:max-w-screen-xl w-full absolute z-10 vh-center mx-auto text-white text-center "
      >
        <span
          v-if="moduleInfo.Module.ModuleProperties.ModuleHasImageAsOvertitle === 'True'"
          class="hero-overtitle-icon w-full h-auto"
        >
          <img
            :src="moduleInfo.Module.ModuleProperties.ModuleImageAsOvertitle"
            class="w-auto h-auto block mx-auto max-h-300"
          >
          <!-- src="/dist/img/tz-icon.png"-->
        </span>
        <span
          v-if="hasPropertyValue('Overtitle',moduleInfo.Module.ModuleProperties,firstModuleDataItem) & moduleInfo.Module.ModuleProperties.ModuleHasImageAsOvertitle !== 'True'"
          class="overtitle-1 block mb-6"
        >
          {{ getPropertyValue($t, "Overtitle", moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
        </span>

        <div class="relative z-1">
          <!--# Ovdje prikazujemo podatke na fotografiji od stranice ili od fotografije iz multimedijalne galerije-->
          <span
            v-if="moduleInfo.Module.ModuleProperties.ModuleHasImageAsTitle === 'True'"
            class="hero-title-icon w-full h-auto"
          >
            <img
              :src="moduleInfo.Module.ModuleProperties.ModuleImageAsTitle"
              class="w-auto h-auto block mx-auto max-h-300"
              :width="''"
              :height="''"
            >
          </span>
          <h1
            v-if="hasPropertyValue('Title', moduleInfo.Module.ModuleProperties, firstModuleDataItem) && moduleInfo.Module.ModuleProperties.ModuleHasImageAsTitle !== 'True'"
            :class="moduleInfo.Module.ModuleProperties.ModuleHasBiggerTitle === 'True' ? 'headline-1b' : 'headline-1a'"
          >
            {{ getPropertyValue(  $t,  "Title", moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
          </h1>
          <svg class="svg-parallelogram" viewBox="0 0 2000 500" preserveAspectRatio="none"  xmlns="http://www.w3.org/2000/svg">
            <rect x="190" y="0" width="90%" height="100%" transform="skewX(-20)" />
          </svg>
          <img
            v-if="moduleInfo.Module.ModuleProperties.ModuleHasSplash == 'True'"
            src="/dist/img/splash-v2.svg"
            class="splash-v1 absolute vh-center -z-1"
          >
        </div>
        <!--# Podnaslov ispod glavnog naslova-->
        <p
          v-if="hasPropertyValue('Subtitle',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          class="p-base text-white max-w-screen-md mx-auto mb-6 mt-8"
        >
          {{ getPropertyValue(  $t, "Subtitle",moduleInfo.Module.ModuleProperties,firstModuleDataItem) }}
        </p>
        <VirtualLink
          v-if="hasPropertyValue('ModuleUrlName',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          :href="getPropertyValue(  $t, 'ModuleUrlLink',moduleInfo.Module.ModuleProperties,firstModuleDataItem)"
          :class-list="btnClass + ' relative z-10 text-white font-sans-bold'"
        >
          {{ getPropertyValue( $t,"ModuleUrlName", moduleInfo.Module.ModuleProperties, firstModuleDataItem) }}
        </VirtualLink>
      </div>

      <FormaPersonaHeader
        v-if="comp && moduleInfo.Module.ModuleProperties.ModuleHasHomepage === 'True'"
        class="form-personalize md:container md:max-w-screen-xl w-full md:h-center mx-auto absolute md:fixed top-auto bottom-0 md:mb-2 transition-all duration-300 ease-in-out 2xl:ml-1"
        :module-info="comp"
        :dropdown-class-list="['form-personalize-dropdown']"
      />
    </div>
  </div>
</template>

<script>
import VuePicture from "@/components/includes/VuePicture";
//import Breadcrumbs from "@/libs/features/lib/Breadcrumbs/Breadcrumbs";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay  } from "swiper";
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperHeroOptions } from "@/services/swiperOptions";

// Import Swiper styles

import {selfDataRequestMixin} from "@/mixins";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade ]);

export default {
  name: "HeaderBig",
  components: {
    VuePicture,
    Swiper,
    SwiperSlide,
    //Breadcrumbs,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      galleryType: "heroBig",
      swiperHeroOptions: {...swiperHeroOptions},
      swiper: {},
      slideCaption: "",
      swiperDelay: this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleSliderSpeed : this.$store.state.Params.find(o => o.Key === 'SliderSpeed')?.Value || 8000,
      btnClass: 'btn-small-lg' //this.moduleInfo.Module.ModuleProperties.ModuleButtonType?.length > 0 ? this.moduleInfo.Module.ModuleProperties.ModuleButtonType : 'btn-transparent-white'
    };
  },
  computed: {
    comp() {
      return this.$store.state.PageData.ModuleData.find(m => m.Module?.ModuleVariation?.Title.replace(/\s/g, '') === "FormaPersona" )
    }
  },
  mounted(){
    if(document.getElementsByClassName("hero-big-container")[0]){
      let parent = document.getElementsByClassName("hero-big-container")[0].closest(".scroll-margin-default");
      if(parent && this.moduleInfo.Module.ModuleProperties.ModuleHasHomepage === "True"){
        parent.classList.add("container-hp-special");
      }
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;

      // Apply Slide Caption
      if (this.firstModuleDataItem.MediaGallery.length > 0) {
        // console.log(this.firstModuleDataItem.MediaGallery[0]?.meta.MediaDescription);
        this.slideCaption = this.firstModuleDataItem.MediaGallery[0]?.meta.MediaDescription;
      }
    },
    slideChange(swiper) {
      this.slideCaption = this.firstModuleDataItem.MediaGallery[
        swiper.realIndex
      ].meta.MediaDescription;
    },
    toggleForm(){
      this.isActive = !this.isActive;
    },
  },
};
</script>
