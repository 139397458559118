<template>
  <div>
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="menuItem"
      :show-only-icon="true"
    />
    <span
      v-if="menuItem.SefUrl || menuItem.CustomUrl"
      class="nav-dropdown-group-title relative z-1"
    >
      <VirtualLink
        :close-menu-on-click="true"
        :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
        :target="menuItem.Target"
        :class-list="'nav-dropdown-link w-full block py-3 px-4'"
      >
        {{ menuItem.Name }}
      </VirtualLink>
    </span>
    <span
      v-else
      class="nav-dropdown-title w-full block py-3 px-4 relative z-1"
    >
      {{ menuItem.Name }}
    </span>
    <ul
      :class="menuItem.MenuItemOption == 'LEFT_BORDER' ? 'nav-list-blockquote' : 'nav-list-content'"
    >
      <template
        v-for="(menuItemChild, index) in menuItem.Children.filter((obj) => obj.MenuItemOption == '' || obj.MenuItemOption == null)"
        :key="menuItemChild.EntityID"
      >
        <ModuleLink
          v-if="$store.state.AdminMode"
          :module-properties="menuItemChild"
          :show-only-icon="true"
        />
        <li
          v-if="(menuItemChild.SefUrl || menuItemChild.CustomUrl) && index < numbOfLinksToShow(menuItem.MenuItemOption)"
        >
          <VirtualLink
            :close-menu-on-click="true"
            :target="menuItemChild.Target"
            :href="menuItemChild.CustomUrl ? menuItemChild.CustomUrl : menuItemChild.SefUrl"
          >
            {{ menuItemChild.Name }}
          </VirtualLink>
        </li>
      </template>
    </ul>
    <VirtualLink
      v-if="(menuItem.SefUrl || menuItem.CustomUrl) && menuItem.Children.filter((obj) => (obj.SefUrl || obj.CustomUrl) && (obj.MenuItemOption == '' || obj.MenuItemOption == null)).length > numbOfLinksToShow(menuItem.MenuItemOption)"
      :close-menu-on-click="true"
      :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
      :target="menuItem.Target"
      class-list="btn-small text-12 lg:text-15 pl-0 mt-1"
    >
      {{ $t("loc_view_more") }}
    </VirtualLink>
    <br v-if="menuItem.Children.filter((obj) => (obj.SefUrl || obj.CustomUrl) && (obj.MenuItemOption == '' || obj.MenuItemOption == null)).length > numbOfLinksToShow(menuItem.MenuItemOption)">
    <div
      v-if="(menuItem.Children.filter((obj) => (obj.SefUrl || obj.CustomUrl) && obj.MenuItemOption == 'HIGHLIGHT')).length > 0"
      class="w-full lg:w-auto inline-flex flex-col items-start space-y-2 pt-3 pb-3 lg:pb-0"
    >
      <template
        v-for="menuItemChild in menuItem.Children.filter((obj) => (obj.SefUrl || obj.CustomUrl) && obj.MenuItemOption == 'HIGHLIGHT')"
        :key="menuItemChild.EntityID"
      >
        <ModuleLink
          v-if="$store.state.AdminMode"
          :module-properties="menuItemChild"
          :show-only-icon="true"
        />
        <VirtualLink
          :href="menuItemChild.CustomUrl ? menuItemChild.CustomUrl : (menuItemChild.SefUrl ? menuItemChild.SefUrl : '#')"
          :close-menu-on-click="true"
          :target="menuItemChild.Target"
          class-list="link-grey"
        >
          <span class="link-underline">
            <span>{{ menuItemChild.Name }}</span>
          </span>
        </VirtualLink>
      </template>
    </div>
  </div>
</template>

<script>
import { menuMixin } from "@/mixins";

export default {
  mixins: [menuMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    numbOfLinksToShow(menuItemOption){
      if(menuItemOption !== 'LEFT_BORDER'){
        return this.getNumbOfItemsToShow();
      }
      else {
        return 15;
      }
    }

  },
};
</script>
