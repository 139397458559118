<template>
  <div class="relative inline-block text-left">
    <Menu>
      <span class="rounded-md block lg:inline w-full lg:w-auto">
        <MenuButton class="menu-button w-full lg:w-auto">
          <i
            class="fas fa-wheelchair text-color-nav-text text-32 hover:text-color-secondary"
          />
        </MenuButton>
      </span>

      <transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute z-10 right-0 left-0 w-56 mt-2 origin-bottom-left bottom-0 lg:bottom-auto mb-16 lg:mb-0 lg:origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
        >
          <div class="py-1 border-b border-color-lightgrey">
            <div
              class="flex justify-between w-full px-4 py-2 text-base leading-5 text-left text-color-darkgrey hover:text-color-primary"
            >
              <span
                v-tooltip.top-center="{
                  content: tooltipFontBase,
                  trigger: 'hover',
                  classes: 'tooltip-content tooltip-accessibility',
                }"
                class="font-change text-base text-color-darkgrey cursor-pointer hover:text-color-primary"
                @click="setFontValue(a11yKeys.FontSizeBase, $event)"
              >Aa</span>
              <span
                v-tooltip.top-center="{
                  content: tooltipFontLg,
                  trigger: 'hover',
                  classes: 'tooltip-content tooltip-accessibility',
                }"
                class="font-change text-lg text-color-darkgrey cursor-pointer hover:text-color-primary"
                @click="setFontValue(a11yKeys.FontSizeLg, $event)"
              >Aa</span>
              <span
                v-tooltip.top-center="{
                  content: tooltipFontXl,
                  trigger: 'hover',
                  classes: 'tooltip-content tooltip-accessibility',
                }"
                class="font-change text-xl text-color-darkgrey cursor-pointer hover:text-color-primary pb-2"
                @click="setFontValue(a11yKeys.FontSizeXl, $event)"
              >Aa</span>
            </div>

            <div class="py-1 flex items-center justify-between" >
              <div
                v-tooltip.left-start="{
                  content: tooltipDyslexia,
                  trigger: 'hover',
                  classes: 'tooltip-content tooltip-accessibility',
                }"
                class="flex justify-between w-full px-4 py-2 text-base leading-5 text-left text-color-darkgrey hover:text-color-primary"
              >
                <span> {{ $t("loc_dyslexia") }}</span>

                <label class="switch-container">
                  <input
                    v-bind="$attrs"
                    class="input"
                    type="checkbox"
                    :checked="getAllA11yValues[a11yKeys.Dyslexia]"
                    @change="setValue(a11yKeys.Dyslexia)"
                    @click="loadFont"
                  >
                  <span class="switch" />
                  <span
                    v-if="label"
                    class="label"
                  /></label>
              </div>
            </div>

            <div class="py-1 flex items-center justify-between">
              <div
                v-tooltip.left-start="{
                  content: tooltipHighContrast,
                  trigger: 'hover',
                  classes: 'tooltip-content tooltip-accessibility',
                }"
                class="flex justify-between w-full px-4 py-2 text-base leading-5 text-left text-color-darkgrey hover:text-color-primary"
              >
                <span> {{ $t("loc_high_contrast") }} </span>
                <label class="switch-container">
                  <input
                    v-bind="$attrs"
                    class="input"
                    type="checkbox"
                    :checked="getAllA11yValues[a11yKeys.HighContrast]"
                    @change="setValue(a11yKeys.HighContrast)"
                  >
                  <span class="switch" />
                  <span
                    v-if="label"
                    class="label"
                  />
                </label>
              </div>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { a11yKeys } from "@/services/a11y.helpers";
import { mapActions, mapGetters } from "vuex";

import "@/css/modules/_tooltip.scss";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
  emits: ["update:checked", "change"],
  data() {
    return {
      fontLoaded: false,
      loggedIn: false,
      a11yKeys: a11yKeys,
      tooltipDyslexia: [ this.$t("loc_dyslexia_desc") ],
      tooltipHighContrast: [ this.$t("loc_high_contrast_desc") ],
      tooltipFontBase: [ this.$t("loc_font_base") ],
      tooltipFontLg: [ this.$t("loc_font_lg") ],
      tooltipFontXl: [ this.$t("loc_font_xl") ],
    };
  },
  computed: {
    ...mapGetters(["getAllA11yValues"]),
  },
  methods: {
    ...mapActions(["setA11yValue"]),
    setValue(key) {
      this.setA11yValue({
        key: key,
        value: !this.getAllA11yValues[key],
      })
    },
    setFontValue(key, event) {
      if(event){
        var fontChangeElements = document.querySelectorAll(".font-change");
        [].forEach.call(fontChangeElements, function(el) {
            el.classList.remove("active");
        });
        event.target.classList.add("active");
      }
      this.setA11yValue({
        key: "FontSizeBase",
        value: false,
      });
      this.setA11yValue({
        key: "FontSizeLg",
        value: false,
      });
      this.setA11yValue({
        key: "FontSizeXl",
        value: false,
      });
      this.setA11yValue({
        key: key,
        value: !this.getAllA11yValues[key],
      })
    },
    changeEvent(event) {
      this.$emit("update:checked", event);
      this.$emit("change", event);
    },
    loadFont(){
      if(this.fontLoaded === false){
        this.fontLoaded = true;
        var link = document.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('type', 'text/css');
            link.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
            document.head.appendChild(link);
      }
    }
  },
};
</script>

<style lang="scss">
.dropdown-link {
  @apply relative inline-flex items-center justify-between py-3 px-4 transition-all duration-300;
  &:after {
    @apply transition-all duration-300 w-0 h-0 absolute left-auto;
    content: "";
    right: 16px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid theme("colors.color-primary");
  }
  &:hover {
    @apply text-color-primary;
  }
  &:focus,
  &:active {
    @apply outline-none shadow-none;
  }
}
button:focus {
  outline: none;
}

.switch-container {
  cursor: pointer;
  display: flex;
  align-items: center;

  .label {
    margin-left: 12px;
    color: #1a202c;
    /* Show an ellipsis if the text takes more than one line */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /* Visually hide the checkbox input */
  .input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .switch {
    --switch-container-width: 50px;
    --switch-size: calc(var(--switch-container-width) / 2);
    --steel: #6f7486;
    --gray: #cbd5e0;
    --dark-gray: #a0aec0;
    --red: #ef3524;
    --dark-red: #d11f0e;

    /* Vertically center the inner circle */
    display: flex;
    align-items: center;
    position: relative;
    height: var(--switch-size);
    flex-basis: var(--switch-container-width);
    /* Make the container element rounded */
    border-radius: var(--switch-size);
    background-color: var(--steel);
    /* In case the label gets really long, the toggle shouldn't shrink. */
    flex-shrink: 0;
    left: -50px;

    transition: background-color 0.25s ease-in-out;
  }

  .switch::before {
    content: "";
    position: absolute;
    /* Move a little bit the inner circle to the right */
    left: 1px;
    height: calc(var(--switch-size) - 4px);
    width: calc(var(--switch-size) - 4px);
    /* Make the inner circle fully rounded */
    border-radius: 9999px;
    background-color: white;
    border: 2px solid var(--steel);

    transition: transform 0.375s ease-in-out;
  }

  .input:checked + .switch {
    background-color: var(--red);
  }

  .input:checked + .switch::before {
    border-color: var(--red);
    /* Move the inner circle to the right */
    transform: translateX(
      calc(var(--switch-container-width) - var(--switch-size))
    );
  }

  .input:disabled + .switch {
    background-color: var(--gray);
  }

  .input:disabled + .switch::before {
    background-color: var(--dark-gray);
    border-color: var(--dark-gray);
  }
}
</style>
