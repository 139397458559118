import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")

  return (_openBlock(), _createBlock(_component_AppInput, {
    placeholder: _ctx.$t('loc_search_placeholder'),
    "class-list": ['border-b-2 border-color-primary text-color-secondary w-full h-12 py-4 px-1 relative z-1 bg-transparent'],
    "show-icon": true,
    value: _ctx.filterData.selectedValue?.value,
    onOnInputChange: _ctx.onInputChange
  }, null, 8, ["placeholder", "value", "onOnInputChange"]))
}