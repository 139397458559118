<template>
  <div
    v-if="moduleInfo.Children.length > 0"
    class="nav-dropdown nav-dropdown-small"
  >
    <div class="nav-dropdown-container mx-auto">
      <div class="">
        <div
          v-for="menuItem in moduleInfo.Children"
          :key="menuItem.EntityID"
          class="w-full"
        >
          <NavMenuItemOption :menu-item="menuItem" />
        </div>
        <div
          v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
          class="w-full pt-2"
        >
          <VirtualLink
            :close-menu-on-click="true"
            :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
            :target="moduleInfo.Target"
            class-list="btn-small btn-small"
          >{{ $t("loc_explore_more") }}</VirtualLink>
        </div>
      </div>
      <!-- <div
        v-for="menuItem in moduleInfo.Children.slice(-1)"
        :key="menuItem.EntityID"
        class="w-full lg:max-w-250 pt-4 lg:pt-0"
      >
        <NavMenuItemOption :menu-item="menuItem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";

export default {
  components: {
    NavMenuItemOption,
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loggedIn: false,
    };
  },
};
</script>

<style lang="scss" scoped>
button:focus{outline:none;}
</style>
