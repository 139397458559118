<template>
  <div
    v-for="row in footerList"
    :key="row.index"
    class="block md:flex items-top justify-center lg:justify-start text-center md:text-left py-5 pb-0 md:pb-5 md:gap-6"
  >
    <div
      v-for="infoFooterItem in row.row"
      :key="infoFooterItem.EntityID"
      class="lg:min-w-250 md:max-w-250 lg:max-w-none pb-5"
    >
      <FooterMenuItem
        :module-info="infoFooterItem"
        :module-class="'footer-list-items leading-relaxed md:leading-8'"
      />
    </div>
  </div>
</template>

<script>
import FooterMenuItem from "./FooterMenuItem";

export default {
  components: {
    FooterMenuItem
  },
  props: {
    moduleInfo: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
      return {
          footerList: []
      }
  },
  mounted() {
      this.createFooterListArray();
  },
  methods: {
      createFooterListArray(){
          if(this.moduleInfo.length > 0){
              const rowsNumber = this.moduleInfo.length / 4;
              for(let i = 0; i <= rowsNumber; i++){
                  const posEndSlice = (i + 1) * 4;
                  const posStartSlice = i * 4 == 0 ? 0 : i * 4;
                  const rowObj = {
                      row: this.moduleInfo.slice(posStartSlice, posEndSlice),
                      index: i
                  };
                  this.footerList.push(rowObj);
              }
          }
      }
  }
};
</script>
