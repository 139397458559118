<template>
  <div>
    <div
      id="play-button"
      class="block w-24 h-30 absolute vh-center z-10 mx-auto cursor-pointer transition-all duration-300"
      @click="startFancy()">
      <!-- <i class="fas fa-camera text-white text-60 " /> -->
      <i class="fas fa-images text-white text-48"></i>

    </div>
  </div>
</template>

<script>
import {Fancybox} from  "../../../../node_modules/@fancyapps/ui/src/Fancybox/Fancybox";
import "@/css/modules/_tooltip.scss";
export default {
    name: "Fancybox",
    props: {
        firstModuleDataItem: {
            type: Object,
            default: () => ({}),
        },
        galleryType: {
            type: Object,
            default: () => ({}),
        },
        slideIndex: {
            type: Number,
            default: 0
        }
    },
     data(){
        return{
            imgs: [],
            flag: false,
            tooltipContent: []//this.setTooltipContent(0)
        }
    },
    methods: {
       getImages(){

        let totalImgs = this.firstModuleDataItem.MediaGallery?.length;

        this.firstModuleDataItem.MediaGallery.forEach((gallery, index) => {

          let currentImg = index + 1;

          //INLINE VIDEO
          if(gallery.meta.MediaCategory === "video" && !gallery.meta.ExternalMediaPath){
            this.imgs.push(
              {
                "src": gallery.meta.MediaPath,
                //DEV"src":"https://htz-com.p4c-htz/"+gallery.meta.MediaPath,
                //OPIS"caption": gallery.meta.MediaDescription + "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
                "caption": "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
              }
            );
          }
          //EXTERNAL VIDEO
          else if(gallery.meta.MediaCategory === "video" && gallery.meta.ExternalMediaPath){
             this.imgs.push(
              {
                "src": (gallery.meta.MediaTypeValue === 'youtube' ? 'https://www.youtube.com/embed/' : 'https://vimeo.com/') + gallery.meta.ExternalMediaPath,
                //OPIS"caption": gallery.meta.MediaDescription + "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
                "caption": "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
              }
             );
          }
          //IMG
          else{
            this.imgs.push(
              {
                "src": gallery.lg.path,
                //DEV"src": 'https://htz-com.p4c-htz/'+gallery.lg.path,
                //OPIS"caption": gallery.meta.MediaDescription + "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
                "caption": "<div class='counter-custom'>"+currentImg+"/"+totalImgs+"</div>"
              }
            );
          }
        });

        if (this.imgs.length === 0) {
          this.imgs.push({"src": this.firstModuleDataItem.MediaGallery[0]?.lg.path});
        }
        this.flag = true;
      },
      startFancy(){
        if(this.flag === false){
          this.getImages();
        }
        var gallery = this.imgs;

        Fancybox.show(gallery, {
          mainClass: "fancybox-custom-gallery",
          startIndex: this.slideIndex,
          Thumbs: {
            autoStart: false
          },
          //Toolbar: false,
          Toolbar: {
            display: [{ id: "counter"}, "close"],
          },
          Image: {
            //zoom: false,
          },
          on: {
            "Carousel.change": (fancybox, slide, carousel, to, from) => {
              /* console.log("change", to);
              console.log("slide pages to",slide.pages[to]);
              console.log("slide pages slides content",slide.pages[to].slides[0].$content); */


             // carousel.slides[to].append(document.getElementById("tooltip"));
              this.setTooltipContent(to, slide);
            },
            done: (fancybox, slide) => {
             /*  console.log("DONE");
              console.log(fancybox); */
              //append counter inside fancybox_content div
              slide.$content.append(slide.$caption);
              //slide.$content.append(document.getElementById("tooltip"));
              },
            },
        });


      },
      setTooltipContent(index, slide){
        console.log("setTooltipContent");
        let img = this.firstModuleDataItem.MediaGallery[index];

        let tooltipContent = [
        `${img.meta.MediaDescription? "<i>" + img.meta.MediaDescription + "</i><br>": ""}
          ${img.meta.Author != null ? "<span>"+ this.$t("loc_photo") +": <span>" + img.meta.Author.AuthorName +" " +img.meta.Author.AuthorSurname +"</span></span><br>": ""}
          ${img.meta.MediaLocation ? "<span>"+ this.$t(this.$filters.stringFormat("loc_location"))+": <span>" + img.meta.MediaLocation + "</span></span>": ""}`
        ];
        //console.log("1 tootlipContent:", this.tooltipDataExist(index) ? tooltipContent : []);
        tooltipContent = this.tooltipDataExist(index) ? tooltipContent : [];
        //return this.tooltipDataExist(index) ? tooltipContent : [];
        var tooltipHtml = "<i v-tooltip.top-end=`{content: "+tooltipContent+", trigger: 'click hover', classes: 'tooltip-content tooltip-image'}` class='fas fa-camera text-white absolute z-20 right-0 left-auto bottom-0 opacity-100 text-24 cursor-pointer transition-all duration-300 ease-in-out m-2'></i>"
        slide.pages[index].slides[0].$content.append(tooltipHtml);
      },

      tooltipDataExist(index) {
        let img = this.firstModuleDataItem.MediaGallery[index];
        return (
        img.meta !== null &&
        (img.meta.MediaDescription.length > 0 ||
          img.meta.Author !== null ||
          img.meta.MediaLocation.length > 0)
      );
      },
    }
};
</script>

<style lang="scss" scoped>
@import "@fancyapps/ui/dist/fancybox.css";


.fancybox__backdrop{
  @apply bg-color-secondary bg-opacity-100;
}
  #play-button{
        path{@apply transition-all duration-300 ease-in-out;}
        .fa-camera,
        .fa-images{
          @apply transition-all duration-300 ease-in-out;
          /* border: 2px solid rgba(255,255,255,0.7);
          border-radius: 100%; */
          padding: 17px;
          color: rgba(255,255,255,0.7);
          margin-top: - 40px;
        }
  }

  #play-button:hover{
      path{fill: #fff;}
      .fa-camera,
      .fa-images{
         /* border: 2px solid rgba(255,255,255,1); */
         color: rgba(255,255,255,1);
       }
  }


</style>
