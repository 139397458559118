<template>
  <div :class="moduleInfo.Module.CssClass">
    <div
      v-if="loaded"
      class="container max-w-screen-md lg:max-w-screen-xl mx-auto my-8 mg:my-12"
    >
      <h3
        v-if="moduleInfo.Module.ModuleProperties.ModuleShowGreyTitle == 'True'"
        class="headline-4 text-color-text pb-8"
      >
        {{ tt(moduleInfo.Module.ModuleProperties.ModuleTitle )}}
      </h3>

      <h2
        v-else
        class="headline-3a text-color-primary pb-12"
      >
        {{ tt(moduleInfo.Module.ModuleProperties.ModuleTitle) }}
      </h2>
      <div class="flex flex-wrap justify-between items-start lg:space-x-4">
        <div
          v-for="data of moduleData"
          :key="data.EntityID"
          class="min-w-250 flex lg:flex-1 flex-row items-center w-full sm:w-1/2 lg:w-auto mb-3 md:mb-0 space-x-3"
        >
          <Icon
            v-if="data.Icon"
            :icon-name="data.Icon"
            :icon-class="'icon '+data.Icon+' mb-4 md:min-h-20 text-60 md:text-72'"
            :span-class="'text-60 md:text-72'"
          />
          
          <i
            v-else
            class="block min-w-16 min-h-16 md:min-w-20 md:min-h-20 mb-2"
          />
          <div>
            <a :href="data.SefUrl">
              <p class="p-xs text-15 text-color-darkgrey font-sans-bold uppercase leading-tight hover:text-color-primary">
                {{ data[propertyToShow] }}
                {{ data.propertyToShow }}
              </p>
              <span
                class="btn-small text-color-primary pt-0 pl-0 mt-0"
              >
                {{ $t("loc_icons_link_go") }}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div class="border-b border-color-lightgrey mt-6 md:mt-12" />
    </div>
  </div>
</template>

<script>
import {selfDataRequestMixin} from "@/mixins";
import Icon from "@/components/includes/Icon";

export default {
  name: "IconsCTA",
  components: {
    Icon
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
        propertyToShow: (JSON.parse(this.moduleInfo.Module.ModuleProperties?.ModulePropertyToShow))?.PropertyName
    };
  },
};
</script>

<style lang="scss">
</style>
