export const tableMixin = {
  data() {
    return {
      ascending: false,
      sortColumn: "",
      start: 0,
      end: 0,
    };
  },
  computed: {
    fallBackItemPerPage() {
      return this.$store.state.Params.find(o => o.Key === 'tableFallbackItemPerPage')?.Value ?? null;
    },
    componentStore() {
      return this.$store.state[this.moduleInfo.Module.EntityID.toString()]
    },
    rowNames() {
      let elements = this.componentStore.moduleInfo.Module.ModuleProperties.ModuleTableRowNames;
      elements = elements?.split("#");
      elements = elements?.map(str => this.$t(str.replaceAll('%', '')));
      elements = elements?.map(str => this.$t(str.replace('$(', '').replace(')', '')));
      return elements;
    },
    moduleTableColumnNames() {
      return this.componentStore.moduleInfo.Module.ModuleProperties.ModuleTableColumnNames;
    },
    columnNames() {
      let elements = this.moduleTableColumnNames;
      elements = elements?.split("#");
      elements = elements?.map(str => this.$t(str.replaceAll('%', '')));
      elements = elements?.map(str => this.$t(str.replace('$(', '').replace(')', '')));
      return elements;
    },
    tableData() {
      let dtaToUse = ''
      try {
        dtaToUse = JSON.parse(this.componentStore.moduleInfo.Module.ModuleProperties.ModuleTableData)

      } catch (e) {
        //console.log(e);
        dtaToUse = this.componentStore.moduleInfo.Module.ModuleProperties.ModuleTableData.split("#")
          .map(o => ({PropertyID:null, PropertyName: o, RelatedTypeID: -1 ,GroupName: "SvojstvaObjekta"}))
      }
      return dtaToUse
    },
    perPage() {
      return +this.componentStore.pagination.PerPage
    },

    getRows() {
      let dataToUse;
      if (this.componentStore.paginationActive) {
        dataToUse = this.componentStore.moduleData
      } else {
        this.start = +(this.componentStore.pagination?.CurrentPage - 1) * this.componentStore.pagination?.PerPage;
        this.end = this.start + this.perPage;
        dataToUse = this.componentStore.moduleData.slice(this.start, this.end);
      }
      if (this.componentStore.paginationActive) {
        return dataToUse
      } else {
        return this.sortRows(dataToUse)

      }
    }
  },

  // Title#Email#Link#StreetAndNumber#Rating
  methods: {
    sortRows(list) {
      return this.tableData.length && this.index !== null ? list.sort( (a, b) => {
      return this.ascending ?
        a[this.tableData[this.index].PropertyName].localeCompare(b[this.tableData[this.index].PropertyName]) :
        a[this.tableData[this.index].PropertyName].localeCompare(b[this.tableData[this.index].PropertyName]) * -1
      }) : list
/*      return this.tableData.length && this.index !== null ? list.sort( (a, b) => {
        if (a[this.tableData[this.index].PropertyName] > b[this.tableData[this.index].PropertyName]) {
          return this.ascending ? 1 : -1;
        } else if (a[this.tableData[this.index].PropertyName] < b[this.tableData[this.index].PropertyName]) {
          return this.ascending ? -1 : 1;
        }
        return 0;
      }) : list;*/
    },
    requestNewData() {
      const newRequestModuleInfo = {...this.componentStore.moduleInfo}
      newRequestModuleInfo.Module.Api.RequestBody.PageNumber = 1;
      newRequestModuleInfo.Module.Api.RequestBody.AdditionalSortParam = this.sortColumn + ' ' + (this.ascending ? 'ASC' : 'DESC')
      this.makeRequest({moduleInfo: newRequestModuleInfo, galleryType: 'default'})
    },
    sortTable(col, index) {
      if (this.tableData[index].RelatedTypeID < 0) {
        this.index = index;
        if (this.sortColumn === col) {
          this.ascending = !this.ascending;
        } else {
          this.ascending = true;
          this.sortColumn = col;
        }
        if (+this.componentStore.moduleInfo.Module.Paging.ItemPerPage) {
          this.requestNewData();
        } else {
          console.log('no pagination');
        }
      }

    },
    getRating(rating) {
      return parseInt(rating, 10) || 0;
    },
  },
}
