<template>
  <div>
    <div v-if="!gettingWeatherData">
      <div class="loader" />
    </div>
    <div v-if="gotAllData && gotWeatherData">
      <!-- sidebar weather module -->
      <div
        v-if="moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'True'"
        class="container max-w-screen-md lg:max-w-screen-sm mx-auto my-8"
      >
        <h5
          v-if="
            $filters.hasPropertyValue(
              'ModuleTitle',
              moduleInfo.Module.ModuleProperties,
              moduleData
            )
          "
          class="overtitle-1 text-color-text text-left text-16 mb-5"
        >
          {{
            $filters.getPropertyValue( $t,
                                       "ModuleTitle",
                                       moduleInfo.Module.ModuleProperties,
                                       moduleData
            )
          }}
        </h5>
        <!-- current weather -->
        <div
          class="flex flex-row items-stretch justify-around p-xs text-color-text border-b border-color-lightgrey py-4"
        >
          <!-- {{ currentTempInfo.Icon }} -->
          <!-- <i
            class="icon icon-weather-sunny-ico text-68"
          ><span class="path1" /><span class="path2" /><span class="path3" /></i> -->
          <Icon
            :icon-name="'icon-weather-sunny-ico'"
            :icon-class="'icon icon-weather-sunny-ico text-68'"
            :span-class="''"
          />
          <span class="flex flex-col items-stretch justify-evenly">
            <span>{{ $t("loc_weather_now") }}</span>
            <span
              class="text-32 text-color-primary leading-none"
            >{{ currentTempInfo.Temp }}°C</span>
          </span>

          <span class="flex flex-col items-stretch justify-evenly">
            <p>
              {{ $t("loc_weather_min_temp") }}
              <strong
                class="text-color-primary ml-2"
              >{{ weatherData.WeatherToday.MinTemp }}°C</strong>
            </p>
            <p>
              {{ $t("loc_weather_max_temp") }}
              <strong
                class="text-color-secondary ml-2"
              >{{ weatherData.WeatherToday.MaxTemp }}°C</strong>
            </p>
          </span>
          <span
            v-if="gotSeaTempData && getHighestSeaTemp(seaTempData.Termin)"
            class="flex flex-col items-stretch justify-evenly"
          >
            <p>{{ $t("loc_weather_sea_temp") }}</p>
            <p>
              <strong class="text-color-secondary">
                {{ getHighestSeaTemp(seaTempData.Termin) }}°C
              </strong>
            </p>
          </span>
        </div>
        <!-- three day weather -->
        <div class="flex justify-around border-b border-color-lightgrey py-4">
          <div
            v-for="(day, index) in weatherData.DayWeatherList"
            :key="day.DayName"
            class="flex flex-col items-center justify-center text-color-text text-16 space-y-1"
          >
            <!--TODO: prikaz ikona {{day.Icon}} -->
            <!-- <i
              v-if="index === 1 || index === 3 || index === 4"
              class="icon icon-weather-cloudy-ico text-68"
            ><span class="path1" /><span class="path2" /><span class="path3" /></i>
            <i
              v-else
              class="icon icon-weather-sunny-ico text-68"
            ><span class="path1" /><span class="path2" /><span class="path3" /></i> -->
            <Icon
              v-if="index === 1 || index === 3 || index === 4"
              :icon-name="'icon-weather-cloudy-ico'"
              :icon-class="'icon icon-weather-cloudy-ico text-68'"
              :span-class="''"
            />
            <Icon
              v-else
              :icon-name="'icon-weather-sunny-ico'"
              :icon-class="'icon icon-weather-sunny-ico text-68'"
              :span-class="''"
            />

            <span>{{
              $t(
                $filters.stringFormat(
                  "loc_weather_day_{0}",
                  $filters.lowercase(day.DayName)
                )
              )
            }}</span>
            <span class="text-color-primary text-18">{{ day.AvgTemp }}°C</span>
          </div>
        </div>
      </div>

      <!-- big weather module -->
      <div
        v-if="
          moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === 'False' ||
            moduleInfo.Module.ModuleProperties.ModuleShowAsSidebar === ''
        "
        class="container max-w-screen-xl mx-auto my-8"
      >
        <h4
          v-if="
            $filters.hasPropertyValue(
              'ModuleTitle',
              moduleInfo.Module.ModuleProperties,
              moduleData
            )
          "
          class="headline-4 text-color-text mb-5"
        >
          {{
            $filters.getPropertyValue( $t,
                                       'ModuleTitle',
                                       moduleInfo.Module.ModuleProperties,
                                       moduleData
            )
          }}
        </h4>
        <div class="flex justify-between md:px-8">
          <div
            v-for="(day, index) in weatherData.DayWeatherList"
            :key="day.DayName"
            class="flex flex-col items-center justify-center text-color-text text-15 md:text-18 space-y-6 lg:space-y-8"
          >
            <span
              v-if="index === 0"
              class="text-color-darkgrey"
            >{{
              $t("loc_weather_today")
            }}</span>
            <span v-else-if="display === 'mobile'">{{
              $t(
                $filters.stringFormat(
                  "loc_weather_day_{0}",
                  $filters.lowercase(day.DayName)
                )
              ).substring(0, 3)
            }}</span>
            <span v-else>{{
              $t(
                $filters.stringFormat(
                  "loc_weather_day_{0}",
                  $filters.lowercase(day.DayName)
                )
              )
            }}</span>
            <!--TODO: prikaz ikona {{day.Icon}} -->
            <!-- <i
              v-if="index === 1 || index === 3 || index === 4"
              class="icon icon-weather-cloudy-ico text-40 sm:text-48 md:text-68 lg:text-80"
            ><span class="path1" /><span class="path2" /><span class="path3" /></i>
            <i
              v-else
              class="icon icon-weather-sunny-ico text-40 sm:text-48 md:text-68 lg:text-80"
            ><span class="path1" /><span class="path2" /><span class="path3" /></i> -->

            <Icon
              v-if="index === 1 || index === 3 || index === 4"
              :icon-name="'icon-weather-cloudy-ico'"
              :icon-class="'icon icon-weather-cloudy-ico text-40 sm:text-48 md:text-68 lg:text-80'"
              :span-class="''"
            />
            <Icon
              v-else
              :icon-name="'icon-weather-sunny-ico'"
              :icon-class="'icon icon-weather-sunny-ico text-40 sm:text-48 md:text-68 lg:text-80'"
              :span-class="''"
            />

            <span
              :class="{ 'text-color-darkgrey': index === 0 }"
            >{{ day.AvgTemp }}°C</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/http-common";
import {selfDataRequestMixin} from "@/mixins";
import Icon from "@/components/includes/Icon";

export default {
  name: "Weather",
  components: {
    Icon,
  },
  mixins: [selfDataRequestMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      weatherData: {},
      gettingWeatherData: false,
      currentTempInfo: {},
      display: "desktop",
      // weatherContainerTypeId: 0,
      // cityTypeId: 0,
      city: {},
      seaTempData: {},
    };
  },
  computed: {
    gotWeatherData: function () {
      if (this.weatherData && this.weatherData.WeatherToday) {
        this.getCurrentTemp(this.weatherData.WeatherToday.TodayList);
        return true;
      }
      return false;
    },
    gotAllData: function () {
      if (
        this.moduleInfo &&
        this.weatherContainerTypeId > 0 &&
        this.cityTypeId > 0
      ) {
        this.getCityData();
        if(this.gotCityData){
          this.getWeatherData();
        }
      }
      return this.gettingWeatherData;
    },
    gotCityData: function() {
      if(this.city){
        return Object.keys(this.city).length > 0;
      } else {
        return false;
      }
    },
    weatherContainerTypeId() {
      return +this.$store.state.Params.find(o => o.Key === 'WeatherContainerTypeId')?.Value || 0
    },
    cityTypeId() {
      return +this.$store.state.Params.find(o => o.Key === 'CityTypeId')?.Value || 0
    },
    defaultCityID () {
      return  this.$store.state.Params.find(o => o.Key === 'DefaultCityID')?.Value ||  "";
    },
    defaultSeaCityName () {
      return  this.$store.state.Params.find(o => o.Key === 'DefaultSeaCityName')?.Value ||  "";
    },
    gotSeaTempData(){
      if (this.gotWeatherData && (this.$filters.hasPropertyValue(
                "ModuleSeaTemp",
                this.moduleInfo.Module.ModuleProperties,
                this.moduleData
              ) || this.defaultSeaCityName != '')) {
        this.getSeaTempData();
        return true;
      }
      return false;
    }
    /*
    *
    * */
  },
  mounted() {
    this.windowWidth();
    this.getAndFillParameters();
  },
  methods: {
    getCityData() {
      if (
        !this.gotCityData &&
        !this.gettingWeatherData
      ){
        axiosInstance
            .post("/api/entities", {
              EntityTypeID: this.cityTypeId,
            })
            .then((response) => {
              const res = response.data;
              const moduleCityCode = this.moduleInfo.Module.ModuleProperties.ModuleCityCode != '' ? this.moduleInfo.Module.ModuleProperties.ModuleCityCode : this.defaultCityID;
              this.city = res.find(obj => obj.EntityID == moduleCityCode);
              if(!this.city){
                this.gettingWeatherData = true;
              }
            })
            .catch((e) => {
              this.error = e;
            });
      }
    },
    getWeatherData() {
      if (
        this.gotCityData &&
        !this.gettingWeatherData
      ) {
        axiosInstance
          .post("/api/entities/services", {
            ServiceType: "Weather",
            CityCode: this.city.Properties.find((obj) => obj.Name == 'CityCode').Value,
            ShowWeatherAsSidebar: this.moduleInfo.Module.ModuleProperties
              .ModuleShowAsSidebar,
            LanguageID: this.$store.state.PageData.LanguageId,
            WeatherTypeId: this.weatherContainerTypeId,
            CityTypeId: this.cityTypeId,
          })
          .then((response) => {
            this.weatherData = response.data;
            this.gettingWeatherData = true;
          })
          .catch((e) => {
            this.error = e;
          });
      } else {
        this.gettingWeatherData = true;
      }
    },
    getSeaTempData() {
      if (
        this.gotWeatherData && this.weatherData.SeaTempList.length
      ) {
        const moduleSeaTempName = this.$filters.hasPropertyValue(
                "ModuleSeaTemp",
                this.moduleInfo.Module.ModuleProperties,
                this.moduleData
              ) ? this.$filters.getPropertyValue(  this.$t,
                "ModuleSeaTemp",
                this.moduleInfo.Module.ModuleProperties,
                this.moduleData
              ) : this.defaultSeaCityName;
            if(moduleSeaTempName != ''){
              const seaTempListArr = this.weatherData.SeaTempList.filter((obj) => obj.Naziv == moduleSeaTempName);
              this.seaTempData = seaTempListArr.length > 0 ? seaTempListArr[0] : {};
            }
      }
    },
    getCurrentTemp(arr) {
      if (arr) {
        let currentDate = new Date();
        let currentHour = currentDate.getHours();
        this.currentTempInfo = arr?.reduce((obj1, obj2) => {
            return Math.abs(obj2.Hour - currentHour) < Math.abs(obj1.Hour - currentHour) ? obj2 : obj1;
        });
      }
    },
    getHighestSeaTemp(seaTempArr){
      if(seaTempArr.length){
        return seaTempArr.sort(function(a, b){return b-a})[0];
      }
    },
    windowWidth: function () {
      if (window.innerWidth > 768) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
      }
    },
    getAndFillParameters: function () {
     /* this.$parameters
        .getParameter("WeatherContainerTypeId", "")
        .then((response) => {
          this.weatherContainerTypeId = response;
        });
      this.$parameters.getParameter("CityTypeId", "").then((response) => {
        this.cityTypeId = response;
      });*/
    },
  },
};
</script>

<style></style>
